import React from "react";
import { NavLink } from "react-router-dom";

const ConditionalPhoneNumberUpdate = ({ isPasswordReset }) => {
  return (
    !isPasswordReset && (
      <div className="text-sm text-slate-500">
        <NavLink to="/update-phone-number" className="mr-1 text-color-primary">
          Click here
        </NavLink>
        to udpate phone number
      </div>
    )
  );
};

export default ConditionalPhoneNumberUpdate;
