/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ClipLoader } from "react-spinners";
import { useParams } from "react-router-dom";
import { AppToast, Button, TextArea, TextInput } from "../ui";
import {
  useCreateProjectAsyncMutation,
  useGetSingleProjectByUserIdAsyncMutation,
  useUpdateProjectAsyncMutation,
} from "./project-slice";

const AddProjectForm = () => {
  const [projectInfo, setProjectInfo] = useState([]);
  const { id: projectId } = useParams();

  const [
    createProjectAsync,
    {
      isLoading: createLoading,
      data: createProjectData,
      isError: isCreateProjectError,
      error: createProjectError,
      isSuccess: isCreateProjectSuccess,
    },
  ] = useCreateProjectAsyncMutation();

  const [
    usegetSingleProject,
    {
      data: singleProject,
      isSuccess: singleProjectSuccess,
      error: singleProjectError,
    },
  ] = useGetSingleProjectByUserIdAsyncMutation();

  const [
    useUpdateProject,
    {
      isLoading: updateLoading,
      isSuccess: isUpdateSuccess,
      isError: isUpdateError,
      error: updateError,
    },
  ] = useUpdateProjectAsyncMutation();

  useEffect(() => {
    usegetSingleProject({ id: projectId });
  }, [projectId, usegetSingleProject]);

  useEffect(() => {
    if (singleProjectSuccess && singleProject) {
      setProjectInfo(singleProject);
    }
  }, [singleProject, singleProjectSuccess]);

  const { name, description } = projectInfo?.response || {};

  const initialValues = {
    projectId,
    projectName: name || "",
    projectDescription: description || "",
  };

  const validationSchema = Yup.object({
    projectName: Yup.string().required("Project name cannot be blank"),
    projectDescription: Yup.string().required(
      "Please provide a project description"
    ),
  });

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (projectId === null || projectId === undefined) {
        const response = await createProjectAsync({
          name: values.projectName,
          description: values.projectDescription,
        });

        if (!response.error) {
          resetForm();
        }
      } else {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const response = await useUpdateProject({
          id: projectId,
          name: values.projectName,
          description: values.projectDescription,
          group_id: values.projectGroup,
        });

        if (!response.error) {
          resetForm();
        }
      }
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={(values, helpers) => handleSubmit(values, helpers)}
      >
        <Form className="space-y-4x">
          <div className="md:grid md:grid-cols-2 md:gap-x-4">
            <div className="md:col-span-2">
              <TextInput
                label="Project Name"
                id="projectName"
                name="projectName"
                placeholder="Enter name of project"
              />
            </div>
          </div>
          <div>
            <TextArea
              label="Project Description"
              id="projectDescription"
              name="projectDescription"
              placeholder="Provide a project description"
            />
          </div>
          <div className="md:flex md:justify-end md:items-center">
            <Button
              type="submit"
              label="Submit"
              disabled={createLoading}
              otherStyles="bg-accent-gradient text-light-900"
            >
              {(createLoading || updateLoading) && (
                <ClipLoader color="#fff" size={20} />
              )}
              Save Changes
            </Button>
          </div>
        </Form>
      </Formik>
      <AppToast
        isSuccess={isCreateProjectSuccess || isUpdateSuccess}
        successMessage={
          (isCreateProjectSuccess && "Project added successfully") ||
          (isUpdateSuccess && "Project updated successfully")
        }
        isError={isCreateProjectError || isUpdateError}
        errorMessage={
          createProjectError?.data?.error || updateError?.data?.error
        }
      />
    </React.Fragment>
  );
};

export default AddProjectForm;
