import React from "react";
import MessageTemplateItem from "./message-template-item";
import { ClipLoader } from "react-spinners";

const SingleMessageTemplateUI = ({ templateData, title, contentLoading }) => {
  return (
    <React.Fragment>
      <div className="p-2 rounded-lg space-y-2">
        <React.Fragment>
          <h1 className="font-medium text-slate-600">Title</h1>
          <p className="text-color-primary">{title}</p>
        </React.Fragment>
      </div>

      {contentLoading && <ClipLoader size={24} />}

      {templateData?.map((template) => {
        return (
          <MessageTemplateItem
            key={template.id}
            {...template}
            contentLoading={contentLoading}
          />
        );
      })}
    </React.Fragment>
  );
};

export default SingleMessageTemplateUI;
