import React from "react";
import { Card, InnerPageHeader } from "../features/ui";
import { CreateNewMessageForm } from "../features/messaging/components";

const AddNewMessage = () => {
  const pageConfig = {
    title: "New Message Template",
    subTitle: "Messages engage with our audience by sending them information.",
  };

  return (
    <React.Fragment>
      <Card>
        <section>
          <InnerPageHeader
            pageTitle={pageConfig.title}
            pageSubTitle={pageConfig.subTitle}
          />
          <CreateNewMessageForm />
        </section>
      </Card>
    </React.Fragment>
  );
};

export default AddNewMessage;
