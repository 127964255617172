/* eslint-disable react/button-has-type */
/* eslint-disable react/function-component-definition */
import React from 'react';
import CreditBilling from './credit-billing-card';
import TrainingAndSupport from './training-support-card';

const BillingAndTraining = () => (
  <section className="md:grid md:grid-cols-5 md:gap-x-4 md:items-stretch">
    <div className="md:col-span-3">
      <CreditBilling />
    </div>
    <div className="md:col-span-2">
      <TrainingAndSupport />
    </div>
  </section>
);

export default BillingAndTraining;
