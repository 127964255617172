/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PieChart } from '../ui';
import { useGetUserCampaignStatAsyncQuery } from './campaign-api-slice';

const CampaignPieChart = () => {
  const dispatch = useDispatch();
  const userId = sessionStorage.getItem("id");
  const {
    data, isSuccess, isLoading, isError, error,
  } = useGetUserCampaignStatAsyncQuery(userId);

  const [chartData, setChartData] = useState([]);

  useEffect(() => {
    if (isSuccess && data) {
      setChartData(data);
    }
  }, [data, isSuccess]);

  const chartConfig = {
    labels: chartData.map((labelItem) => labelItem.MessageTitle),
    datasets: [{
      label: 'Recipients',
      data: chartData.map((dataItem) => dataItem.Recipients.length),
      backgroundColor: ['#8FCDFF', '#337AFF '],
      borderColor: '#F3F8FC',
      borderWidth: 2,
    }],
  };

  return (
    <>
      {error && (
        <div className="flex items-center justify-center mt-4">
          <p className="text-red-600 text-lg py-2 px-4 rounded-lg">
            <span className="text-red-600">{error?.data?.error}</span>
          </p>
        </div>
      )}
      <p>.</p>
      {
        isLoading ? <p>Fetching...</p> : (
          <div>
            <PieChart
              subData={chartConfig}
              chartHeading="Campaign Recipients"
              titleText="Recipients grouped by campaigns"
            />
          </div>
        )
      }
    </>

  );
};

export default CampaignPieChart;
