/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React from 'react';
import {
  InnerPageHeader,
} from '../features/ui';
import { DisplayGeneratedCampaignReport } from '../features/campaigns';

const GenerateReport = () => {
  const pageConfig = {
    title: 'Generate Outbound Reports',
    subTitle: 'Generate reports from Campaigns sent to beneficiaries.',
  };

  return (
    <React.Fragment>
      <InnerPageHeader
        pageTitle={pageConfig.title}
        pageSubTitle={pageConfig.subTitle}
      />
      <DisplayGeneratedCampaignReport />
    </React.Fragment>
  );
};

export default GenerateReport;
