/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */

import React from "react";
import { FaSms, FaUsers } from "react-icons/fa";
import { RiVoiceprintFill } from "react-icons/ri";
import AnalyticsCardItem from "./analytics-card-item";
import { useGetAllUserSubscribersAsyncQuery } from "../subscriptions";
import { useGetUserCampaignStatAsyncQuery } from "../campaigns/campaign-api-slice";
import { useGetAllMessageTemplatesByUserIdAsyncQuery } from "../messaging/messaging-slice";

const AnalyticsCards = () => {
  const userId = sessionStorage.getItem("id");

  const { data: subscribers } = useGetAllUserSubscribersAsyncQuery(userId);
  const { data: messages } = useGetAllMessageTemplatesByUserIdAsyncQuery(
    userId
  );
  const { data: campaigns } = useGetUserCampaignStatAsyncQuery(userId);

  const ANALYTICS_DATA = [
    {
      label: "Subscribers",
      icon: <FaUsers className="text-emerald-500 text-5xl" />,
      value: subscribers?.length || 0,
    },
    {
      label: "SMS Campaigns sent",
      icon: <FaSms className="text-color-primary text-5xl" />,
      value: campaigns?.length || 0,
    },
    {
      label: "Voice Campaigns sent",
      icon: <RiVoiceprintFill className="text-light-500 text-5xl" />,
      value: messages?.length || 0,
    },
  ];
  return (
    <section className="lg:flex justify-between">
      {ANALYTICS_DATA.map((data) => (
        <AnalyticsCardItem key={data.label} data={data} />
      ))}
    </section>
  );
};

export default AnalyticsCards;
