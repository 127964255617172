// import { toast } from 'react-toastify';
// import Cookies from 'js-cookie';
import { apiSlice } from "../../api/api";
const userId = sessionStorage.getItem('id');
const storedValue = sessionStorage.getItem("uniqueId");

const subscriberId = storedValue ? JSON.parse(storedValue) : {};

export const subscriptionApi = apiSlice.injectEndpoints({
  reducerPath: "subscriptionApi",
  endpoints: (builder) => ({
    addNewSubscriberAsync: builder.mutation({
      query: (userInput) => ({
        url: "/subscriber",
        method: "POST",
        body: {
          full_name: userInput.full_name,
          phone_number: userInput.phone_number,
          language: userInput.language,
          user_id: userId,
          gender: userInput.gender,
          region: userInput.region,
          district: userInput.district,
          comment: userInput.comments,
          grp_id: userInput.grp_id,
          age: userInput.age,
        },
      }),
      invalidatesTags: ["Subscribers"],
    }),
    getAllUserSubscribersAsync: builder.query({
      query: (userId) => `/subscriber/user/${userId}`,
      transformResponse: (res) => res.response,
      providesTags: ["Subscribers"],
    }),
    getSingleUserSubscriberAsync: builder.mutation({
      query: ({ subscriberId }) => ({
        url: `/subscriber/single/${userId}`,
        method: "POST",
        body: { id: subscriberId },
      }),
      invalidatesTags: ["SingleSubscriber", "Subscribers"],
      transformResponse: (res) => res.response,
    }),
    deleteUserSubscriberAsync: builder.mutation({
      query: ({ subscriberId }) => ({
        url: `/subscriber/${userId}/${subscriberId}`,
        method: "DELETE",
      }),
      invalidatesTags: ["SingleSubscriber", "Subscribers"],
    }),
    updateSubscriberDataAsyc: builder.mutation({
      query: (userInput) => ({
        url: `/subscriber/${userId}`,
        method: "PUT",
        body: {
          id: subscriberId,
          full_name: userInput.full_name,
          phone_number: userInput.phoneNumber,
          language: userInput.preferedLanguage,
          gender: userInput.gender,
          region: userInput.region,
          district: userInput.district,
          comment: userInput.comment,
          user_id: userId,
          group_id: userInput.group,
          age: userInput.age,
        },
      }),
      invalidatesTags: ["SingleSubscriber", "Subscribers"],
    }),
    importUserSubscribersAsync: builder.mutation({
      query: (arg) => ({
        url: `/upload/subscribers/${userId}`,
        method: "POST",
        body: arg.formData,
      }),
      invalidatesTags: ["Subscribers"],
    }),
    importUserSubscriberNewGroupsAsync: builder.mutation({
      query: (arg) => ({
        url: `/upload/subscribers/new_group/${userId}`,
        method: "POST",
        body: arg.formData,
      }),
      invalidatesTags: ["Subscribers"],
    }),
    getUserSubscribersToExport: builder.mutation({
      query: ({ user_id, group_id, all_sub, start_date, end_date }) => ({
        url: `/subscriber/export/${user_id}`,
        method: "POST",
        body: {
          start_date,
          end_date,
          group_id,
          all_sub,
        },
      }),
    }),
  }),
});

export const {
  useAddNewSubscriberAsyncMutation,
  useGetAllUserSubscribersAsyncQuery,
  useGetSingleUserSubscriberAsyncMutation,
  useDeleteUserSubscriberAsyncMutation,
  useUpdateSubscriberDataAsycMutation,
  useImportUserSubscribersAsyncMutation,
  useImportUserSubscriberNewGroupsAsyncMutation,
  useGetUserSubscribersToExportMutation,
} = subscriptionApi;
