/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React from 'react';
import { Card, InnerPageHeader } from '../features/ui';
import { CreditBillingInfo } from '../features/billing';

const CreditBilling = () => {
  const pageConfig = {
    title: 'Credit Billing',
    subTitle: 'Credit transactions on your account.',
  };

  return (
    <React.Fragment>
      <InnerPageHeader
        pageTitle={pageConfig.title}
        pageSubTitle={pageConfig.subTitle}
      />
      <Card>
        <CreditBillingInfo />
      </Card>
    </React.Fragment>
  );
};

export default CreditBilling;
