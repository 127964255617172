/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/jsx-indent */
/* eslint-disable indent */
/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable import/named */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable arrow-body-style */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import {
  useVerifyOTPMutation,
  useForgotPasswordMutation,
} from "../api/auth-api-slice";
import ForgotPasswordOTPVerificationUI from "./forgot-password-otp-verification-ui";

function ForgotPasswordOTPVerification() {
  const phoneNumber = sessionStorage.getItem("phone");
  const navigate = useNavigate();

  const initialValues = {
    secret: "",
  };

  const validationSchema = Yup.object({
    secret: Yup.string().required("Please enter the code sent you."),
  });

  const [
    verifyOTP,
    {
      data: verifyResponse,
      isSuccess: verifySuccess,
      isLoading: verifyLoading,
      isError,
      error,
    },
  ] = useVerifyOTPMutation();

  const [
    resendOTP,
    {
      data: resendResponse,
      isSuccess: resendSuccess,
      isLoading: resendLoading,
      isError: isResendError,
      error: resendError,
    },
  ] = useForgotPasswordMutation();

  useEffect(() => {
    if (verifySuccess) {
      navigate("/change-password");
    }
  }, [navigate, verifySuccess]);

  useEffect(() => {
    if (isError) {
      toast.error(error?.data?.error, {
        position: toast.POSITION.TOP_CENTER,
        toastId: `${uuidv4()}`,
      });
    }
  }, [isError, error?.data?.error]);

  useEffect(() => {
    if (isResendError) {
      toast.error(resendError?.data?.error, {
        position: toast.POSITION.TOP_CENTER,
        toastId: `${uuidv4()}`,
      });
    }
  }, [isResendError, resendError?.data?.error]);

  useEffect(() => {
    if (resendSuccess) {
      toast.success(resendResponse?.response, {
        position: toast.POSITION.TOP_CENTER,
        toastId: `${uuidv4()}`,
      });
    }
  }, [resendSuccess, resendResponse]);

  const handleSubmit = (values) => {
    verifyOTP({ secret: values.secret, phoneNumber });
  };

  const handleOtpResend = () => {
    resendOTP({ phoneNumber });
  };

  return (
    <ForgotPasswordOTPVerificationUI
      initialValues={initialValues}
      validationSchema={validationSchema}
      handleOtpResend={handleOtpResend}
      verifyLoading={verifyLoading}
      resendLoading={resendLoading}
      handleSubmit={handleSubmit}
    />
  );
}

export default ForgotPasswordOTPVerification;
