import moment from "moment";

const transformTableData = (data) => {
  if (!Array.isArray(data) || data.length === 0) {
    return [];
  }

  return data.map((sender, index) => ({
    serial: index + 1,
    id: sender.id,
    title: sender.name,
    date_created: moment(sender.created_at).format("DD-MM-YYYY"),
  }));
};

export default transformTableData;
