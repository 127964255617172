import { Formik, Form, Field } from "formik";
import React from "react";
import { Button, SelectField, TextArea, TextInput } from "../ui";
import { ClipLoader } from "react-spinners";

const SubscriberFormUI = ({
  initialValues,
  validationSchema,
  handleSubmit,
  ...otherprops
}) => {
  const { language, group, isLoading } = otherprops;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, helpers) => handleSubmit(values, helpers)}
      enableReinitialize
    >
      {({ handleChange }) => (
        <Form className="space-y-4">
          <div className="space-y-4 md:grid md:grid-cols-2 md:gap-x-2 lg:gap-x-6 md:items-baseline">
            <TextInput
              label="Full Name"
              id="fullName"
              name="fullName"
              placeholder="Enter subscriber first name"
            />
            <TextInput
              label="Phone Number"
              id="phoneNumber"
              name="phoneNumber"
              placeholder="Enter phone number. eg. 0541234567"
            />
          </div>
          <div className="space-y-4 md:grid md:grid-cols-2 md:gap-x-2 lg:gap-x-6 md:items-baseline">
            <TextInput
              label="Age"
              name="age"
              id="age"
              placeholder="Enter subscriber age"
            />
            <div className="">
              <p
                className="font-medium text-slate-600"
                aria-label="Message Type"
              >
                Choose Gender
              </p>
              <div className="space-y-2">
                <div className="flex items-center space-x-4">
                  <label htmlFor="sexMale">
                    Male
                    <Field
                      type="radio"
                      name="gender"
                      id="male"
                      value="male"
                      onChange={handleChange}
                    />
                  </label>

                  <label htmlFor="sexFemaleMale">
                    Female
                    <Field
                      type="radio"
                      name="gender"
                      id="female"
                      value="female"
                      onChange={handleChange}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="space-y-4 md:grid md:grid-cols-2 md:gap-x-2 lg:gap-x-6 md:items-baseline">
            <TextInput
              label="Region"
              name="region"
              id="region"
              placeholder="Enter subscriber region"
            />
            <TextInput
              label="District/City/Town"
              name="district"
              id="district"
              placeholder="Enter subscriber district"
            />
          </div>
          <div className="space-y-4 md:grid md:grid-cols-2 md:gap-x-2 lg:gap-x-6 md:items-baseline">
            <SelectField
              label="Select Language"
              name="preferedLanguage"
              id="preferedLanguage"
            >
              <option>Choose prefered langugae</option>
              {Array.isArray(language)
                ? language.map((languageItem, index) => (
                    <option key={index} value={languageItem.title}>
                      {languageItem.title}
                    </option>
                  ))
                : null}
            </SelectField>
            <SelectField label="Subscriber Group" name="group" id="group">
              <option>Choose subscriber group</option>
              {Array.isArray(group)
                ? group.map((groupItem, index) => (
                    <option key={index} value={groupItem.ID}>
                      {groupItem.Name}
                    </option>
                  ))
                : ""}
            </SelectField>
          </div>
          <div>
            <TextArea
              label="Comments"
              name="comments"
              id="comments"
              placeholder="Anything else to add about this subscriber?"
            />
          </div>
          <div className="space-y-4 md:grid md:grid-cols-2 md:gap-x-2 lg:gap-x-6 md:items-baseline"></div>
          <div className="md:flex md:justify-end md:items-center">
            <Button
              type="submit"
              label="Add Subscriber"
              disabled={isLoading}
              otherStyles="bg-accent-gradient text-light-900"
            >
              {isLoading && <ClipLoader color="#fff" size={20} />}
              Save Changes
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SubscriberFormUI;
