import * as Yup from "yup";

const INSTANT_SMS_VALIDATION_SCHEMA = Yup.object({
  campaignName: Yup.string().required("Campaign name cannot be empty"),
  smsSenderId: Yup.string().required("Sender ID cannot be empty"),
  message: Yup.string().required("Campaign message cannot be empty"),
  campaignRecipients: Yup.string().required("Recipeints cannot be empty"),
});

export default INSTANT_SMS_VALIDATION_SCHEMA;
