/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React from 'react';
import { Form, Formik } from 'formik';
import { SelectField, TextInput } from '../form-fields';

const ShowEntriesBy = () => {
  const initialValues = {
    showBy: '',
  };

  const handleChange = () => {
  };

  return (
    <div className="flex justify-between px-2">
      <Formik
        initialValues={initialValues}
        onSubmit={() => {}}
      >
        <Form className="w-full flex justify-between">
          <div className="flex items-center space-x-2">
            <p>Show</p>
            <SelectField
              label=""
              name="showBy"
              id="showBy"
              onChange={handleChange}
            >
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </SelectField>
            <p>entries</p>
          </div>
          <div className="flex items-center space-x-4">
            <span className="inline-block">Search: </span>
            <TextInput
              label=""
              type="search"
              name="search"
              placeholder="Search here..."
              id="search"
              onChange={handleChange}
            />
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default ShowEntriesBy;
