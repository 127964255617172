/* eslint-disable import/order */
/* eslint-disable arrow-body-style */
/* eslint-disable no-const-assign */
/* eslint-disable prefer-destructuring */
/* eslint-disable semi */
/* eslint-disable object-shorthand */
/* eslint-disable arrow-parens */
/* eslint-disable no-unused-vars */
/* eslint-disable comma-dangle */
/* eslint-disable indent */
/* eslint-disable import/prefer-default-export */
import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';
// import { logout } from '../features/auth/auth-slice';
import Cookies from 'js-cookie';

// const BASE_URL = 'http://44.218.77.91:9086/api';
const BASE_URL = 'https://kpododomessagingengine.com/api';


const baseQuery = fetchBaseQuery({
    baseUrl: BASE_URL,
    // credentials: 'include',
    prepareHeaders: (headers) => {
        const token = Cookies.get('token');
        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }
        return headers;
    },
});

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: baseQuery,
    endpoints: builder => ({})
});
