import React from "react";
import { Form, Formik } from "formik";
import { Button, Checkbox, TextInput } from "../../ui";
import { ClipLoader } from "react-spinners";
import { Link } from "react-router-dom";

const SignupFormUI = ({
  initialValues,
  handleSubmit,
  validationSchema,
  isLoading,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values) => handleSubmit(values)}
      validationSchema={validationSchema}
    >
      <Form className="grid grid-cols-2 gap-6">
        <TextInput
          label="Organization Name"
          id="organizationName"
          name="organizationName"
          placeholder="Please enter your organization's name"
        />
        <TextInput
          label="Full Name"
          id="username"
          name="username"
          placeholder="Please enter your name"
        />
        <TextInput
          label="Email"
          type="email"
          name="organizationEmail"
          id="email"
          placeholder="Enter organization's email address"
        />
        <TextInput
          label="Phone Number"
          id="phoneNumber"
          name="phoneNumber"
          placeholder="Enter organization's phone number"
        />
        <TextInput
          label="Password"
          type="password"
          name="password"
          id="password"
          placeholder="Enter your password"
        />
        <TextInput
          label="Confirm Password"
          type="password"
          name="confirmPassword"
          id="confirmPassword"
          placeholder="Confirm your password"
        />
        <div className="col-span-2">
          <div className="space-x-2 text-sm">
            <Checkbox
              label="Accept Terms"
              type="checkbox"
              name="acceptTerms"
              id="acceptTerms"
            >
              I accept Kpododo&apos;s
              <Link to="#" className="text-color-primary mx-1">
                Terms of Service
              </Link>
              & have read the
              <Link to="#" className="text-color-primary mx-1">
                Privacy Policy
              </Link>
            </Checkbox>
          </div>
        </div>
        <div className="col-span-2">
          <Button
            type="submit"
            disabled={isLoading}
            otherStyles="bg-accent-gradient text-light-900"
          >
            {isLoading && <ClipLoader color="#fff" size={20} />}
            Create your account
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

export default SignupFormUI;
