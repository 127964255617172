/* eslint-disable react/button-has-type */
/* eslint-disable react/function-component-definition */
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

const CustomLink = ({ label, reference, icon, customStyle }) => (
  <Link
    to={reference}
    className={`p-2 text-sm flex 
    ${icon ? "justify-between" : "justify-center"}
    items-center rounded-md shadow-sm transition-all duration-300 hover:ease-in
     ${customStyle}`}
  >
    <span className={`inline-block`}>{label}</span>
    {icon && <span className="ml-2 inline-block">{icon}</span>}
  </Link>
);

CustomLink.defaultProps = {
  customStyle: "bg-light-400 text-light-900",
};

CustomLink.propTypes = {
  reference: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  customStyle: PropTypes.string,
  icon: PropTypes.element,
};

export default CustomLink;
