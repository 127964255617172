/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';

const TabButton = ({ children, isActive, onTabChange }) => {
  const handleClick = () => {
    onTabChange();
  };

  return (
    <button
      type="button"
      onClick={handleClick}
      className={`px-4 py-2
      ${isActive ? 'text-color-primary rounded-tl-md rounded-tr-md border-[2px] border-b-slate-700' : 'text-slate-600'}
      `}
    >
      {children}
    </button>

  );
};

TabButton.defaultProps = {
  isActive: false,
};

TabButton.propTypes = {
  children: PropTypes.node.isRequired,
  isActive: PropTypes.bool,
  onTabChange: PropTypes.func.isRequired,
};

export default TabButton;
