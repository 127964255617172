/* eslint-disable react/button-has-type */
/* eslint-disable react/function-component-definition */
import React, { useRef } from 'react';
import { BsPip } from 'react-icons/bs';
import { Button, CardWithHeader } from '../ui';
import TopupAccount from '../billing/topup-account-modal';

const CreditBilling = () => {
  const ref = useRef();
  const handleTopupClick = () => {
    ref.current.showModal();
    document.body.style.overflow = 'hidden';
  };

  return (
    <CardWithHeader title="Credit Billing" otherStyles="h-full">
      <div className="p-2 md:py-8">
        <span className="flex items-center my-2">
          <BsPip className="mr-2 text-3xl text-color-primary" />
          <p className="text-gray-500">Credit: GHC 2.00</p>
        </span>
        <h5 className="text-slate-700 text-base font-bold md:text-xl">
          You can always topup your account, don&apos;t wait till you are out of credit!
        </h5>
        <p className="text-sm text-slate-500 mt-2">
          Load your account and begin sending SMS & Voice campaigns now.
        </p>
        <div className="my-4 md:w-full lg:w-[45%] xl:w-[25%]">
          <Button
            type="button"
            label="Topup Account"
            handleBtnClick={handleTopupClick}
          >
            Topup Account
          </Button>
          <TopupAccount
            modalRef={ref}
          />
        </div>
        <p className="text-lg">
          Or send us an email:
          <span className="inline-block md:ml-2 text-color-primary hover:underline hover:decoration-2">
            <a href="mailto://info@kpododo.com">info@kpododo.com</a>
          </span>
        </p>
      </div>
    </CardWithHeader>
  );
};

export default CreditBilling;
