/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React from "react";
import { Card, InnerPageHeader } from "../features/ui";
import { UploadSubscribersForm } from "../features/subscriptions";

const ImportSubscribers = () => {
  const pageConfig = {
    title: "Import subscribers",
    subTitle: "",
  };

  return (
    <React.Fragment>
      <Card>
        <InnerPageHeader
          pageTitle={pageConfig.title}
          pageSubTitle={pageConfig.subTitle}
        />
        <ul className="text-light-400 text-sm my-4 space-y-2">
          <li>
            1.Upload an Excel spreadsheet .xlsx containing subscriber numbers.
            <a
              href={
                process.env.PUBLIC_URL + "templates/subscriber-template.xlsx"
              }
              download="subscriber-template.xlsx"
              className="text-color-primary px-1"
            >
              Download this template.
            </a>
          </li>
          <li>
            2. Select an existing group from the select list and click
            &quot;Upload Excel File&quot; to upload subscribers into that group.
          </li>
          <li>
            3. Or Add a new group name and description then click &quot;Upload
            Excel File&quot; to upload subscribers into the new group.
          </li>
        </ul>

        <UploadSubscribersForm />
      </Card>
    </React.Fragment>
  );
};

export default ImportSubscribers;
