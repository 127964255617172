/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React from "react";
import { InnerPageHeader, Card } from "../features/ui";
import { NewOutboundCampaignForm } from "../features/campaigns";

const ConfigureOutboundCampaign = () => {
  const pageConfig = {
    title: "Scheduled Campaign",
    subTitle:
      "Campaigns represent the messages that are currently being sent to beneficiaries.",
  };

  return (
    <React.Fragment>
      <Card>
        <div>
          <InnerPageHeader
            pageTitle={pageConfig.title}
            pageSubTitle={pageConfig.subTitle}
          />
          <NewOutboundCampaignForm />
        </div>
      </Card>
    </React.Fragment>
  );
};

export default ConfigureOutboundCampaign;
