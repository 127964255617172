/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable arrow-body-style */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useLoginMutation } from "../api/auth-api-slice";
import { authenticate } from "../api/auth-slice";
import LoginFormUI from "./login-form-ui";
import { useAuth } from "../hooks";

function LoginForm() {
  const auth = useAuth();
  const navigate = useNavigate();

  const [
    userLogin,
    { data, isLoading, isSuccess, isError, error },
  ] = useLoginMutation();

  const initialValues = {
    userEmail: "",
    userPassword: "",
    rememberMe: "",
  };

  const validationSchema = Yup.object({
    userEmail: Yup.string()
      .email()
      .required("Email cannot be blank"),
    userPassword: Yup.string().required("Password cannot be blank"),
    rememberMe: Yup.boolean(),
  });

  React.useEffect(() => {
    if (isSuccess && data) {
      auth.login(data.user);
      navigate("/dashboard");
    }
  }, [isSuccess, data, auth, navigate]);

  const handleSubmit = async (values) => {
    try {
      const { userEmail, userPassword } = values;
      const response = await userLogin({
        userEmail,
        userPassword,
      });
    } catch (error) {}
  };
  return (
    <LoginFormUI
      initialValues={initialValues}
      validationSchema={validationSchema}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
    />
  );
}

export default LoginForm;
