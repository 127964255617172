/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React from "react";
import { Card, InnerPageHeader } from "../features/ui";
import { ExportscribersForm } from "../features/subscriptions";

const ExportSubscribers = () => {
  const pageConfig = {
    title: "Export subscribers",
    subTitle: "",
  };

  return (
    <React.Fragment>
      <Card>
        <InnerPageHeader
          pageTitle={pageConfig.title}
          pageSubTitle={pageConfig.subTitle}
        />
        <div className="mb-8">
          <ul className="text-light-400 text-sm my-4 space-y-2">
            <li>
              1.Select "All subscribers" or select a group to export subscribers
              from.
            </li>
            <li>2. Choose a date range (a start and end date ).</li>
            <li>3. Finally, click on the Export button.</li>
          </ul>
        </div>
        <ExportscribersForm />
      </Card>
    </React.Fragment>
  );
};

export default ExportSubscribers;
