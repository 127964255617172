/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React from 'react';
import { ShowEntriesBy, Table } from '../ui';

const TabularDisplayOfCredits = () => {
  const tableConfig = {
    tableHeaders: ['#', 'Credited', 'Used', 'Description', 'Date'],
    tableData: [],
  };

  return (
    <>
      <ShowEntriesBy />
      <Table
        tableConfigurationObject={tableConfig}
      />
    </>
  );
};

export default TabularDisplayOfCredits;
