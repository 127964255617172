import React from "react";
import { Card, CustomTable, EmptyResource, ErrorDisplay } from "../../ui";
import {
  useDeleteUserMessageTemplateAsyncMutation,
  useGetAllMessageTemplatesByUserIdAsyncQuery,
} from "../messaging-slice";
import ActionButtons from "./action-buttons";
import { transformTableData } from "../utils";
import { ClipLoader } from "react-spinners";

const TabularDisplayOfMessageTemps = () => {
  const userId = sessionStorage.getItem("id");
  const [templates, setTemplates] = React.useState([]);

  const {
    isLoading,
    data: messageTemplates,
    isError: isTemplateFetchError,
    // error: templateError,
    isSuccess: templatesSuccess,
  } = useGetAllMessageTemplatesByUserIdAsyncQuery(userId);

  const [
    deleteUserMessageTemplateAsync,
    // {
    //   isLoading: deleteLoading,
    //   error: deleteTemplateError,
    //   isSuccess: deleteTemplateSuccess,
    // },
  ] = useDeleteUserMessageTemplateAsyncMutation();

  React.useEffect(() => {
    if (templatesSuccess && messageTemplates) {
      setTemplates(messageTemplates);
    }
  }, [messageTemplates, templatesSuccess]);

  const handleDelete = async (templateId) => {
    try {
      await deleteUserMessageTemplateAsync({ id: templateId });
      setTemplates((prevTemplates) =>
        prevTemplates.filter((template) => template.id !== templateId)
      );
    } catch (error) {}
  };

  const MESSAGE_TEMPLATE_TABLE_COLUMNS = [
    {
      Header: "#",
      accessor: "serial",
    },
    {
      Header: "Title",
      accessor: "title",
    },
    {
      Header: "Content Type",
      accessor: "content_type",
    },
    {
      Header: "Date Created",
      accessor: "date_created",
    },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <ActionButtons row={row} handleDelete={handleDelete} />
      ),
    },
  ];

  const transformedData = transformTableData(templates);

  return (
    <section>
      <div className="my-4">{isLoading && <ClipLoader color="#94a3b8" />}</div>

      {templatesSuccess && (
        <Card>
          <CustomTable
            data={transformedData}
            columns={MESSAGE_TEMPLATE_TABLE_COLUMNS}
          />

          {templates.length === 0 && (
            <EmptyResource message="You do not have any message templates." />
          )}
        </Card>
      )}

      {isTemplateFetchError && <ErrorDisplay />}
    </section>
  );
};

export default TabularDisplayOfMessageTemps;
