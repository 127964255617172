import React from "react";
import { Card, InnerPageHeader } from "../features/ui";
import { EditProfileForm } from "../features/auth/components";

const EditProfile = () => {
  const pageConfig = {
    title: "Update Account Information",
    subTitle: "Review and update account details here.",
  };

  return (
    <React.Fragment>
      <Card>
        <section>
          <InnerPageHeader
            pageTitle={pageConfig.title}
            pageSubTitle={pageConfig.subTitle}
          />
          <EditProfileForm />
        </section>
      </Card>
    </React.Fragment>
  );
};

export default EditProfile;
