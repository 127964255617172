/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable arrow-body-style */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useForgotPasswordMutation } from "../api/auth-api-slice";
import { toast } from "react-toastify";
import ForgotPasswordFormUI from "./forgot-password-form-ui";

function ForgotPasswordForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [
    forgotPassword,
    { isSuccess, isError, isLoading, error },
  ] = useForgotPasswordMutation();

  const initialValues = {
    phoneNumber: "",
  };

  useEffect(() => {
    if (isSuccess && forgotPassword) {
      navigate("/forget-password-otp-verification", { replace: true });
    }
  }, [navigate, isSuccess, forgotPassword]);

  // Toasts
  useEffect(() => {
    if (isSuccess) {
      toast.success("OTP verification code sent", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      toast.error(error?.data?.error, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, [isError, error?.data?.error]);

  const handleSubmit = (values) => {
    // For when the user needs the OTP to be resent
    sessionStorage.setItem("phone", values.phoneNumber);
    dispatch(forgotPassword({ phoneNumber: values.phoneNumber }));
  };

  const validationSchema = Yup.object({
    phoneNumber: Yup.string().required("Phone number cannot be blank"),
  });

  return (
    <ForgotPasswordFormUI
      initialValues={initialValues}
      validationSchema={validationSchema}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
    />
  );
}

export default ForgotPasswordForm;
