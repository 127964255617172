/* eslint-disable object-curly-newline */
/* eslint-disable no-trailing-spaces */
/* eslint-disable operator-linebreak */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React from "react";
import { useCreateNewInstantVoiceMessageAsyncMutation } from "../messaging-slice";
import InsantVoiceFormUI from "./instant-voice-form-ui";
import { AppToast } from "../../ui";
import { VOICE_CAMPAIGN_INITIAL_VALUES } from "../constants";
import { INSTANT_VOICE_VALIDATION_SCHEMA } from "../utils";

const InstantVoiceForm = () => {
  const userId = sessionStorage.getItem("id");
  const [
    createNewInstantVoiceMessageAsync,
    { isSuccess, isError, error, isLoading },
  ] = useCreateNewInstantVoiceMessageAsyncMutation();

  const handleSubmit = async (values, { resetForm }) => {
    const {
      campaignName,
      campaignLanguage,
      audioFile,
      campaignRecipients,
    } = values;

    try {
      const formData = new FormData();
      formData.append("message_title", campaignName);
      formData.append("msg_language", campaignLanguage);
      formData.append("voice", audioFile);
      formData.append("is_scheduled", false);

      const recipientsArray = campaignRecipients
        .split(",")
        .map((recipient) => recipient.trim());

      recipientsArray.forEach((recipient) => {
        formData.append("recipients", recipient);
      });

      const response = await createNewInstantVoiceMessageAsync({
        formData,
        userId: userId,
      });

      if (!response.error) {
        resetForm();
      }
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <InsantVoiceFormUI
        initialValues={VOICE_CAMPAIGN_INITIAL_VALUES}
        validationSchema={INSTANT_VOICE_VALIDATION_SCHEMA}
        handleSubmit={handleSubmit}
        isCreating={isLoading}
      />
      <AppToast
        isSuccess={isSuccess}
        successMessage="Your voice campaign has been sent successfully"
        isError={isError}
        errorMessage={error?.data?.error}
      />
    </React.Fragment>
  );
};

export default InstantVoiceForm;
