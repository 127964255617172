/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FaEnvelope } from 'react-icons/fa';
import { BsSoundwave } from 'react-icons/bs';
import { ClipLoader } from 'react-spinners';
import { CustomTable, ShowEntriesBy, Table } from '../ui';
import { useGetAllUserScheduledMessageByTypeAsyncMutation } from './campaign-api-slice';
import moment from 'moment/moment';


const RoutineOutboundCampaignLog = () => {
  const [campaignState, setCampaignSate] = useState([]);


  const [getAllUserScheduledMessageMutation, { data: getAllUserScheduledMessages, isLoading, error, isSuccess
  }] = useGetAllUserScheduledMessageByTypeAsyncMutation();


  useEffect(() => {
    getAllUserScheduledMessageMutation({
      scheduled_type: 'Routine'
    })
  }, [getAllUserScheduledMessageMutation])

  useEffect(() => {
    if (isSuccess && getAllUserScheduledMessages) {
      setCampaignSate(getAllUserScheduledMessages)
    }
  }, [getAllUserScheduledMessages, isSuccess])

  const columns = [
    {
      Header: '#',
      accessor: 'serial',
    },
    {
      Header: 'Campaign Name',
      accessor: 'campaign_name',
    },
    {
      Header: 'Recipients',
      accessor: 'recipients',
    },
    {
      Header: 'Channel',
      accessor: 'channel',
    },
    {
      Header: 'Sent Date',
      accessor: 'sent_date',
    },
  ];

  const transformedData = campaignState.map((item, index) => ({
    serial: index + 1,
    campaign_name: item.message_title,
    recipients: item.recipients.length,
    channel: (
      <>
        {item.is_sms && <FaEnvelope />}
        {item.is_voice && <BsSoundwave />}
      </>
    ),
    sent_date: moment(item.created_at).format('DD-MM-YYYY')
  }));

  let content;

  if (isLoading) {
    content = (<>
      <div className="w-full flex justify-center items-center">
        Please wait...
        <ClipLoader />
      </div></>)
  } else if (isSuccess) {
    content = <CustomTable data={transformedData} columns={columns} />

  } else if (error) {
    content = (<div className="flex items-center justify-center mt-4">
      <p className="bg-red-300 text-lg py-2 px-4 rounded-lg">
        <span className="text-red-500">{error?.data?.error}</span>
      </p>
    </div>)
  }

  return (
    <section>
      {content}
    </section>
  );
};

export default RoutineOutboundCampaignLog;
