/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/function-component-definition */
import { useField } from "formik";
import PropTypes from "prop-types";
import React from "react";
import FormErrorMessage from "./form-error-message";

const Checkbox = ({ children, ...props }) => {
  const [field, meta] = useField({ name: props.name });

  return (
    <React.Fragment>
      <div className="space-x-2">
        <input className="p-2" {...field} {...props} />
        <label
          htmlFor={props.name || props.id}
          className="text-dark-500 text-sm font-semibold"
        >
          {children}
        </label>
      </div>
      {meta.touched && meta.error ? (
        <FormErrorMessage>{meta.error}</FormErrorMessage>
      ) : null}
    </React.Fragment>
  );
};

Checkbox.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default Checkbox;
