/* eslint-disable indent */
/* eslint-disable react/jsx-indent */
import React from "react";
import {
  AuthHeader,
  ForgotPasswordOTPVerification,
} from "../features/auth/components";
import { AppToastContainer } from "../features/ui";

function ForgotPasswordOTPVerificationPage() {
  return (
    <section className="flex flex-col justify-center items-center min-h-screen">
      <div className="bg-white rounded drop-shadow-lg p-8">
        <AuthHeader title="Phone number verification" otherStyles="mb-4" />
        <span className="inline-block text-sm text-slate-400">
          {`An OTP has been sent to ${sessionStorage.getItem("phone")}`}
        </span>
        <ForgotPasswordOTPVerification />
      </div>
      <AppToastContainer />
    </section>
  );
}

export default ForgotPasswordOTPVerificationPage;
