import React from "react";
import { Form, Formik } from "formik";
import { ClipLoader } from "react-spinners";
import { Button, FootNote, SelectField, TextArea, TextInput } from "../../ui";

const InstantSMSFormUI = ({
  initialValues,
  validationSchema,
  handleSubmit,
  isLoading,
  senderIds,
  ...otherProps
}) => {
  const { handleShowModal } = otherProps;

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, helpers) => handleSubmit(values, helpers)}
    >
      <Form className="space-y-6">
        <div className="space-y-6 md:grid md:grid-cols-2 md:items-baseline md:gap-4">
          <div>
            <TextInput
              label="Campaign Name"
              name="campaignName"
              id="campaignName"
              type="text"
              placeholder="Please provide your campaign name."
            />
            <FootNote>
              <span className="font-bold mr-2">Note:</span>
              Provide a simple name for your campaign.
            </FootNote>
          </div>
          <div>
            <SelectField
              label="Choose SMS sender ID"
              name="smsSenderId"
              id="smsSenderId"
            >
              <option value="">Select a SMS sender ID</option>
              {senderIds?.map((sender) => (
                <option key={sender.id} value={sender.id}>
                  {sender.name}
                </option>
              ))}
            </SelectField>
            <FootNote>
              <span className="font-bold mr-2">Note:</span>
              You can create a new SenderID{" "}
              <button
                type="button"
                className="text-color-primary"
                onClick={handleShowModal}
              >
                here
              </button>
            </FootNote>
          </div>
        </div>
        <div>
          <TextArea
            label="Compose Message"
            name="message"
            id="message"
            placeholder="Compose your campaign message here..."
          />
          <FootNote>
            <span className="font-bold mr-2">Note:</span>
            The &apos;enter&apos; key and other characters copied from Word or
            from your browser may count as more than one character
          </FootNote>
        </div>
        <div>
          <TextArea
            label="Type/Paste Campaign Recipients."
            name="campaignRecipients"
            id="campaignRecipients"
            placeholder="Enter campaign recipients here..."
          />
          <FootNote>
            <span className="font-bold mr-2">Note:</span>
            Please ensure your numbers are seperated with commas. Eg.
            0541234567,0551234567,0201234567.
          </FootNote>
        </div>
        <div className="flex justify-end">
          <Button
            type="submit"
            label="Send Campaign"
            otherStyles="bg-accent-gradient text-light-900"
            disabled={isLoading}
          >
            {isLoading && <ClipLoader color="#fff" size={20} />}
            Send Campaign
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

export default InstantSMSFormUI;
