import { apiSlice } from '../../api/api';

const selectUserId = sessionStorage.getItem('id');

export const projectApi = apiSlice.injectEndpoints({
  reducerPath: 'projectApi',
  endpoints: (builder) => ({
    createProjectAsync: builder.mutation({
      query: (userInput) => ({
        url: `/user/project/${selectUserId}`,
        method: 'POST',
        body: {
          name: userInput.name,
          description: userInput.description,
          group_id: userInput.group_id,
        }
      }),
      invalidatesTags: ['projects'],
    }),
    getAllProjectsByUserIdAsync: builder.query({
      query: (userId) => `/user/project/${userId}`,
      providesTags: ['projects'],
      transformResponse: res => res.response,
    }),
    getUserProjectRelatedGroupsAsync: builder.mutation({
      query: (userInput) => ({
        url: `/user/project-group/${selectUserId}`,
        method: 'POST',
        body: {
          id: userInput.id,
        },
        providesTags: ['projects'],
        transformResponse: res => res.response,
      }),
    }),
    getSingleProjectByUserIdAsync: builder.mutation({
      query: (projectId) => ({
        url: `/user/project/single/${selectUserId}`,
        method: 'POST',
        body: {
          id: projectId.id
        },
        providesTags: ['projects'],
        transformResponse: res => res.response,
      })
    }),
    updateProjectAsync: builder.mutation({
      query: (userInput) => ({
        url: `/user/project/${selectUserId}`,
        method: 'PUT',
        body: {
          id: userInput.id,
          name: userInput.name,
          description: userInput.description,
          group_id: userInput.group_id,
        }
      }),
      invalidatesTags: ['projects'],
    }),
    deleteUserProjectAsync: builder.mutation({
      query: ({ user_id, project_id }) => ({
        url: `/user/project/${user_id}`,
        method: 'DELETE',
        body: {
          id: project_id
        },
      }),
      invalidatesTags: ['projects'],
    }),
  }),
});

export const {
  useCreateProjectAsyncMutation,
  useGetAllProjectsByUserIdAsyncQuery,
  useGetSingleProjectByUserIdAsyncMutation,
  useGetUserProjectRelatedGroupsAsyncMutation,
  useUpdateProjectAsyncMutation,
  useDeleteUserProjectAsyncMutation,
} = projectApi;
