/* eslint-disable indent */
/* eslint-disable import/prefer-default-export */
import { apiSlice } from "../../api/api";
const userId = sessionStorage.getItem('id');

export const groupApi = apiSlice.injectEndpoints({
  reducerPath: 'groupApi',
  endpoints: (builder) => ({
    createNewGroupAsync: builder.mutation({
      query: (groupData) => ({
        url: `/group/${userId}`,
        method: 'POST',
        body: {
          name: groupData.name,
          description: groupData.description,
          tags: groupData.tags
        },
      }),
      invalidatesTags: ['groups'],
    }),
    getAllGroupAsync: builder.query({
      query: () => '/group/all',
      providesTags: ['groups'],
    }),
    getSingleGroupByUserIdAsync: builder.mutation({
      query: (groupId) => ({
        url: `/group/single/${userId}`,
        method: 'POST',
        body: {
          id: groupId.id
        },
      }),
      providesTags: ['singleGroup'],
      transformResponse: res => res.response,
    }),
    getAllGroupsByUserIdAsync: builder.query({
      query: (userId) => `/group/${userId}`,
      transformResponse: res => res.response,
      providesTags: ['groups'],
    }),
    updateGroupAsync: builder.mutation({
      query: (groupData) => ({
        url: `/group/${userId}`,
        method: 'PUT',
        body: {
          id: groupData.id,
          name: groupData.name,
          description: groupData.description,
          tags: groupData.tags
        },
      }),
      invalidatesTags: ['groups'],
    }),
    deleteGroupAsync: builder.mutation({
      query: ({groupId}) => ({
        url: '/group',
        method: 'DELETE',
        body: {
          id: groupId,
        },
      }),
      invalidatesTags: ['groups'],
    }),
  }),
});

export const {
  useCreateNewGroupAsyncMutation,
  useGetAllGroupAsyncQuery,
  useGetSingleGroupByUserIdAsyncMutation,
  useGetAllGroupsByUserIdAsyncQuery,
  useUpdateGroupAsyncMutation,
  useDeleteGroupAsyncMutation,
} = groupApi;


