/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */

import React from "react";
import SidenavListItem from "./sidenav-list-item";
import SIDE_NAVIGATION_LINKS from "./utils";

const SidenavList = () => {
  const [activeIndex, setActiveIndex] = React.useState(-1);

  const handleActiveChange = (newIndex) => {
    if (newIndex === -1) {
      setActiveIndex(-1);
    } else {
      setActiveIndex(newIndex);
    }
  };

  return (
    <ul className="md:min-h-screen w-full side-nav-list">
      {SIDE_NAVIGATION_LINKS.map((linkDetails, index) => (
        <li key={linkDetails.label} className="text-black my-0 py-0">
          <SidenavListItem
            index={index}
            expanded={index === activeIndex}
            details={linkDetails}
            handleActiveChange={handleActiveChange}
          />
        </li>
      ))}
    </ul>
  );
};

export default SidenavList;
