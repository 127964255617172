/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React from "react";
import { Formik, Form } from "formik";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { ClipLoader } from "react-spinners";
import { AppToast, Button, TextInput } from "../ui";
import {
  useCreateLanguageAsyncMutation,
  useGetSingleLanguageAsyncQuery,
  useUpdateLanguageAsyncMutation,
} from "./language-slice";

const AddLanguageForm = () => {
  const { id: languageId } = useParams();
  const userId = "";

  const [
    createLanguageAsync,
    {
      isLoading,
      isCreateLanguageError,
      createLanguageError,
      isCreateLanguageSuccess,
    },
  ] = useCreateLanguageAsyncMutation();

  const {
    data: singleLanguage,
    isSuccess: isSingleLanguageFetchSuccess,
    isError: isSingleLanguageFetchError,
    error: singleLanguageFetchError,
  } = useGetSingleLanguageAsyncQuery(userId, languageId);

  const [
    updateLanguage,
    {
      isLoading: isUpdateLanguageLoading,
      isSuccess: isUpdateLanguageSuccess,
      isError: isUpdateLanguageError,
      error: updateLanguageError,
    },
  ] = useUpdateLanguageAsyncMutation();

  const initialValues = {
    languageName: "",
  };

  const validationSchema = Yup.object({
    languageName: Yup.string().required("Language name cannot be blank"),
  });

  const handleSubmit = (values, { resetForm }) => {
    createLanguageAsync({
      title: values.languageName,
    });
    resetForm();
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={(values, helpers) => handleSubmit(values, helpers)}
      >
        <Form className="space-y-4">
          <div>
            <TextInput
              label="Language Name"
              id="languageName"
              name="languageName"
              placeholder="Enter name of language"
            />
          </div>

          <div className="md:flex md:justify-end">
            <Button
              type="submit"
              label="Submit"
              disabled={isLoading}
              otherStyles="bg-accent-gradient text-light-900"
            >
              {isLoading && <ClipLoader color="#fff" size={20} />}
              Create Language
            </Button>
          </div>
        </Form>
      </Formik>
      <AppToast
        isSuccess={isCreateLanguageSuccess || isUpdateLanguageSuccess}
        successMessage={
          (isCreateLanguageSuccess && "Language added successfully") ||
          (isUpdateLanguageSuccess && "Language updated successfully")
        }
        isError={isCreateLanguageError || isUpdateLanguageError}
        errorMessage={
          createLanguageError?.data?.error || updateLanguageError?.data?.error
        }
      />
    </React.Fragment>
  );
};

export default AddLanguageForm;
