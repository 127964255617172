import InstantMessageForm from './instant-message-form';
import InstantVoiceForm from './instant-voice-form';
import CreateNewMessageForm from './create-new-message-form';
import TabularDisplayOfMessageTemps from './tabular-list-message-temps';
import SingleMessageTemplate from './single-message-template';
import {
  useCreateNewInstantMessageAsyncMutation,
  useCreateNewInstantVoiceMessageAsyncMutation
} from '../messaging-slice';

export {
  InstantMessageForm,
  InstantVoiceForm,
  CreateNewMessageForm,
  TabularDisplayOfMessageTemps,
  SingleMessageTemplate,
  useCreateNewInstantMessageAsyncMutation,
  useCreateNewInstantVoiceMessageAsyncMutation,
};
