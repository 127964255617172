/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';
import { CustomLink } from '../ui';

const SubscriberBio = ({ subscriberDetails }) => {
  const {
    full_name: fullname,
    phone_number: phoneNumber,
    age,
    gender,
    comment,
    region,
    district,
  } = subscriberDetails;

  return (
    <>
      <section className="space-y-2 md:grid md:grid-cols-3 md:gap-x-3">
        <ul className="bg-blue-200 p-2 rounded-lg space-y-2 md:col-span-1">
          <li className="md:grid md:grid-cols-5 md:gap-x-4">
            <span className="block md:inline-block col-span-2">Fullname: </span>
            <span className="inline-block text-left">{fullname}</span>
          </li>
          <li className="md:grid md:grid-cols-5 md:gap-x-4">
            <span className="block md:inline-block col-span-2">Phone Number: </span>
            <span className="inline-block text-left">{phoneNumber}</span>
          </li>
          <li className="md:grid md:grid-cols-5 md:gap-x-4">
            <span className="block md:inline-block col-span-2">Age: </span>
            <span className="inline-block text-left">{age}</span>
          </li>
          <li className="md:grid md:grid-cols-5 md:gap-x-4">
            <span className="block md:inline-block col-span-2">Gender: </span>
            <span className="inline-block text-left">{gender}</span>
          </li>
          <li className="md:grid md:grid-cols-5 md:gap-x-4">
            <span className="block md:inline-block col-span-2">Region: </span>
            <span className="inline-block text-left">{region}</span>
          </li>
          <li className="md:grid md:grid-cols-5 md:gap-x-4">
            <span className="block md:inline-block col-span-2">District: </span>
            <span className="inline-block text-left">{district}</span>
          </li>
        </ul>

        <ul className="bg-blue-200 p-2 rounded-lg md:col-span-2">
          <li className="grid grid-cols-3">
            <div className="col-span-3">
              <span className="">
                Comments:
              </span>
              <p>
                {comment || 'Comments not available'}
              </p>
            </div>
          </li>
        </ul>
      </section>
      <div className="mt-16 flex space-x-4">
        <CustomLink label="Back to list" reference="/subscribers" />
        <CustomLink label="Edit subscriber" reference="/edit-subscriber-details" />
      </div>
    </>
  );
};

SubscriberBio.propTypes = {
  subscriberDetails: PropTypes.shape({}).isRequired,
};

export default SubscriberBio;
