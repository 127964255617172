/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */

import React from "react";
import PropTypes from "prop-types";
import SidenavList from "./sidenav-list";

const Sidenav = ({ customStyles }) => {
  return (
    <aside
      className={`fixed bg-light-900 border-r shadow-sm top-0 pt-[6em] left-0 ${customStyles}`}
    >
      <nav className="h-full px-4 overflow-y-auto">
        <SidenavList />
      </nav>
    </aside>
  );
};

Sidenav.propTypes = {
  customStyles: PropTypes.string.isRequired,
};

export default Sidenav;
