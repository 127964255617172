import LoginPage from './login';
import SignupPage from './signup';
import PhoneNumberVerificationPage from './phone-number-verification';
import PhoneNumberUpdatePage from './update-phone-number';
import ForgotPasswordPage from './forgot-password';
import ResetPasswordPage from './reset-password';
import ChangePasswordPage from './change-password';
import ForgotPasswordOTPVerificationPage from './forget-password-otp-verification';
import Dashboard from './dashboard';
import ConfigureOutboundCampaign from './configure-outbound-campaign';
import CreditBilling from './credit-billing';
import ExportSubscribers from './export-subscribers';
import GenerateReport from './generate-report';
import Groups from './groups';
import ImportSubscribers from './import-subscribers';
import Languages from './languages';
import MessageTemplates from './message-templates';
import CampaignLogs from './campaign-logs';
import Profile from './profile';
import Projects from './projects';
import Senders from './senders';
import Subscribers from './subscribers';
import InstantMessage from './instant-message';
import AddNewSubscriber from './add-subscriber';
import AddNewGroup from './add-group';
import AddNewProject from './add-project';
import AddNewSenderId from './add-new-sender-id';
import AddNewLanguage from './add-new-langugae';
import AddNewMessage from './new-messages';
import EditProfile from './edit-profile';
import UpdatePassword from './update-password';
import EditSubscriber from './edit-subscriber-bio';
import ViewGroupSubscribers from './view-group-subscribers';
import EditGroup from './edit-group';
// import ViewProjectDetails from './view-single-project';
import EditProject from './edit-project-details';
import ViewMessageTemplateDetails from './view-message-template-details';
import Statistics from './statistics';
import InstantVoice from './instant-voice';
import DeliveryReport from './delivery-report';

export {
  LoginPage,
  SignupPage,
  PhoneNumberVerificationPage,
  PhoneNumberUpdatePage,
  ForgotPasswordPage,
  ResetPasswordPage,
  ForgotPasswordOTPVerificationPage,
  ChangePasswordPage,
  Dashboard,
  ConfigureOutboundCampaign,
  CreditBilling,
  ExportSubscribers,
  GenerateReport,
  Groups,
  ImportSubscribers,
  Languages,
  MessageTemplates,
  CampaignLogs,
  Profile,
  Projects,
  Senders,
  Subscribers,
  InstantMessage,
  InstantVoice,
  AddNewSubscriber,
  AddNewGroup,
  AddNewProject,
  AddNewSenderId,
  AddNewLanguage,
  AddNewMessage,
  EditProfile,
  UpdatePassword,
  EditSubscriber,
  ViewGroupSubscribers,
  EditGroup,
  // ViewProjectDetails,
  EditProject,
  ViewMessageTemplateDetails,
  Statistics,
  DeliveryReport,
};
