import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { ErrorDisplay } from "../ui";
import { useGetAllGroupsByUserIdAsyncQuery } from "./group-api-slice";
import GroupListUI from "./group-list-ui";

const GroupList = () => {
  const userId = sessionStorage.getItem("id");
  const {
    data,
    isSuccess,
    isError,
    isLoading,
  } = useGetAllGroupsByUserIdAsyncQuery(userId);

  const [groups, setGroups] = useState([]);

  useEffect(() => {
    if (isSuccess && data) {
      setGroups(data);
    }
  }, [isSuccess, data]);

  return (
    <section>
      <div className="my-4">
        {isLoading ? <ClipLoader color="#94a3b8" /> : null}
      </div>
      {isSuccess ? <GroupListUI groups={groups} /> : null}
      {isError ? <ErrorDisplay /> : null}
    </section>
  );
};

export default GroupList;
