import React from "react";
import { FaEdit, FaEye, FaTrash } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const ActionButtons = ({ row, handleDelete }) => {
  return (
    <div className="flex gap-x-4">
      <button
        type="button"
        onClick={() => handleDelete(row.original.id)}
        className=" hover:cursor-pointer text-red-400 hover:text"
      >
        <FaTrash />
      </button>
      <NavLink
        to={`/content-library/message-templates/${row.original.id}?title=${row.original.title}`}
        className="text-emerald-400 hover:cursor-pointer hover:underline hover:decoration-2"
      >
        <FaEye />
      </NavLink>
      <NavLink
        href="#"
        className="text-color-primary hover:cursor-pointer hover:underline hover:decoration-2"
      >
        <FaEdit />
      </NavLink>
    </div>
  );
};

export default ActionButtons;
