import { configureStore } from '@reduxjs/toolkit';
import { authApi } from '../features/auth/api/auth-api-slice';
import { groupApi } from '../features/groups/group-api-slice';
import { languageApi } from '../features/languages/language-slice';
import { subscriptionApi } from '../features/subscriptions/subscription-slice';
import { projectApi } from '../features/projects/project-slice';
import { messageApi } from '../features/messaging/messaging-slice';
import { senderApi } from '../features/senders/sender-slice';
import { campaignApi } from '../features/campaigns';

import authReducer from '../features/auth/api/auth-slice';

// eslint-disable-next-line import/prefer-default-export
export const rtkStore = configureStore({
  reducer: {
    auth: authReducer,
    [authApi.reducerPath]: authApi.reducer,
    [groupApi.reducerPath]: groupApi.reducer,
    [languageApi.reducerPath]: languageApi.reducer,
    [subscriptionApi.reducerPath]: subscriptionApi.reducer,
    [projectApi.reducerPath]: projectApi.reducer,
    [messageApi.reducerPath]: messageApi.reducer,
    [senderApi.reducerPath]: senderApi.reducer,
    [campaignApi.reducerPath]: campaignApi.reducer,


  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(authApi.middleware),
});


