import React from "react";
import { Formik, Form } from "formik";
import { ClipLoader } from "react-spinners";
import { Button, TextInput } from "../../ui";

const ResetPasswordFormUI = ({
  initialValues,
  handleSubmit,
  validationSchema,
  isLoading,
  loading,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      <Form className="space-y-4">
        <div>
          <TextInput
            type="password"
            label="New Password"
            name="password"
            id="password"
          />
        </div>
        <div>
          <TextInput
            type="password"
            label="Confirm Password"
            name="confirmPassword"
            id="confirmPassword"
          />
        </div>

        <div className="mt-10">
          <Button
            type="submit"
            label="Save Changes"
            otherStyles="bg-accent-gradient text-light-900"
            disabled={loading}
          >
            {isLoading && <ClipLoader color="#fff" size={20} />}
            Save Changes
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

export default ResetPasswordFormUI;
