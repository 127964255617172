/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
/* eslint-disable react/function-component-definition */
import React from "react";
import PropTypes from "prop-types";

const Button = (
  { children, type, otherStyles, handleBtnClick, disabled },
  props
) => {
  const handleClick = () => {
    handleBtnClick();
  };

  return (
    <button
      type={type}
      className={` 
      ${disabled && "bg-slate-500"} 
        hover:cursor-pointer
        p-2 md:px-8 flex justify-center items-center gap-2 shadow-sm text-[14px] font-medium rounded-md transition-all duration-300
        ${otherStyles}
        `}
      onClick={handleClick}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};

Button.defaultProps = {
  type: "button",
  children: "Button",
  otherStyles: "",
  disabled: false,
  handleBtnClick: () => {},
};

Button.propTypes = {
  type: PropTypes.string,
  children: PropTypes.node,
  otherStyles: PropTypes.string,
  handleBtnClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Button;
