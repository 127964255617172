/* eslint-disable no-bitwise */
/* eslint-disable no-plusplus */
// import * as XLSX from 'xlsx-populate';
import * as XLSX from 'xlsx';

const convertJsonToExcel = (jsonData) => {
  // Prepare data in the required format for Excel
  const excelData = jsonData.map((item) => ({
    'Project Name': item.ProjectName,
    MessageTitle: item.MessageTitle,
    Recipients: item.Recipients.join(', '),
    'Recipient Counts': item.Recipients.length,
    VoiceMessageCount: item.VoiceMessageCount,
    SmsMessageCount: item.SmsMessageCount,
  }));

  // Create a new workbook and worksheet
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.json_to_sheet(excelData);

  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  const excelFile = XLSX.write(workbook, { type: 'binary', bookType: 'xlsx' });

  const fileName = 'data.xlsx';
  const buffer = new ArrayBuffer(excelFile.length);
  const view = new Uint8Array(buffer);

  for (let i = 0; i < excelFile.length; i++) {
    view[i] = excelFile.charCodeAt(i) & 0xff;
  }
  const blob = new Blob([buffer], { type: 'application/octet-stream' });

  // Create a download link and trigger the download
  const downloadLink = document.createElement('a');
  downloadLink.href = URL.createObjectURL(blob);
  downloadLink.download = fileName;
  downloadLink.click();
};



const exportJsonToExcel = (data) => {
  const worksheet = XLSX.utils.json_to_sheet(data);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
  const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const fileName = 'data.xlsx';

  if (typeof window !== 'undefined' && window.navigator.msSaveOrOpenBlob) {
    // For IE browser
    window.navigator.msSaveOrOpenBlob(blob, fileName);
  } else {
    // For modern browsers
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    URL.revokeObjectURL(url);
  }
};

export { exportJsonToExcel };


export default convertJsonToExcel;

