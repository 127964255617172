/* eslint-disable react/function-component-definition */

import React, { useEffect } from "react";

import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useResetPasswordMutation } from "../api/auth-api-slice";
import ResetPasswordFormUI from "./reset-password-form-ui";

const ResetPasswordForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading, pwdResetSucccessful } = useSelector((state) => state.auth);

  const [resetPassword, { isLoading }] = useResetPasswordMutation();

  useEffect(() => {
    if (pwdResetSucccessful === true) {
      navigate("/login", { replace: true });
    }
  }, [navigate, pwdResetSucccessful]);

  const initialValues = {
    password: "",
    confirmPassword: "",
  };

  const handleSubmit = (values) => {
    dispatch(resetPassword({ password: values.password }));
  };

  const validationSchema = Yup.object({
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .label("Confirm Password")
      .required()
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });

  return (
    <ResetPasswordFormUI
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      validationSchema={validationSchema}
      isLoading={isLoading}
      loading={loading}
    />
  );
};

export default ResetPasswordForm;
