/* eslint-disable import/no-cycle */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from "react";
import { CustomTable, EmptyResource, ErrorDisplay } from "../ui";
import { useGetAllUserSubscribersAsyncQuery } from "../subscriptions";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";

const GroupsTable = () => {
  const userId = sessionStorage.getItem("id");
  const { id: groupId } = useParams();
  const [groupSubs, setGroupSubs] = useState([]);

  const {
    isLoading,
    isSuccess: getAllUserSubscribersSuccess,
    isError,
    data,
  } = useGetAllUserSubscribersAsyncQuery(userId);

  useEffect(() => {
    if (getAllUserSubscribersSuccess && data) {
      setGroupSubs(data);
    }
  }, [data, getAllUserSubscribersSuccess]);

  const filteredGroupSubscribers = groupSubs?.filter(
    (sub) => sub.group_id === groupId
  );

  const transformedGroupSubscribers =
    filteredGroupSubscribers?.map((subscriber, index) => ({
      serialNumber: index + 1,
      uniqueID: subscriber.id,
      fullname: subscriber.full_name,
      phone_number: subscriber.phone_number,
      language: subscriber.language,
    })) || [];

  const columns = [
    {
      Header: "S/N",
      accessor: "serialNumber",
    },
    {
      Header: "Full Name",
      accessor: "fullname",
    },
    {
      Header: "Phone No.",
      accessor: "phone_number",
    },
    {
      Header: "Language",
      accessor: "language",
    },
  ];

  return (
    <section>
      <div className="my-4">{isLoading && <ClipLoader color="#94a3b8" />}</div>
      {getAllUserSubscribersSuccess ? (
        filteredGroupSubscribers.length > 0 ? (
          <CustomTable columns={columns} data={transformedGroupSubscribers} />
        ) : (
          <EmptyResource message="There are no subscribers in this group." />
        )
      ) : null}

      {isError && <ErrorDisplay />}
    </section>
  );
};

export default GroupsTable;
