import React from "react";
import PropTypes from "prop-types";

const FootNote = ({ children }) => {
  return <p className="text-slate-500 text-[13px]">{children}</p>;
};

FootNote.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FootNote;
