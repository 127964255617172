/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { Card, CustomTable, EmptyResource, ErrorDisplay } from "../ui";
import { useGetAllSendersByUserIdAsyncQuery } from "./sender-slice";
import DeleteModal from "./delete-modal";
import ActionButtons from "./action-buttons";
import { transformTableData } from "./utils";
import SenderIdDetailsModal from "./sender-id-details-modal";

const TabularDisplayOfSenders = () => {
  const userId = sessionStorage.getItem('id');
  const [senderIds, setSenderIds] = useState([]);
  const [senderIdDetails, setSenderDetails] = useState({});
  const deleteRef = React.useRef();
  const senderDetailsRef = React.useRef();

  const {
    data,
    isLoading,
    isSuccess,
    isError,
  } = useGetAllSendersByUserIdAsyncQuery(userId);

  const handleDelete = (id) => {
    if (typeof window !== "undefined") {
      sessionStorage.setItem("senderId", id);
    }
    deleteRef.current.showModal();
  };

  const handleShowSenderModal = (id) => {
    const senders = senderIds.filter((item) => item.id === id);
    setSenderDetails(senders[0]);
    senderDetailsRef.current.showModal();
    document.body.style.overflow = "hidden";
  };

  useEffect(() => {
    if (isSuccess && data) {
      setSenderIds(data);
    }
  }, [data, isSuccess]);

  const transformedData = transformTableData(senderIds);

  const SENDER_TABLE_COLUMNS = [
    {
      Header: "#",
      accessor: "serial",
    },
    {
      Header: "Sender ID",
      accessor: "title",
    },
    {
      Header: "Date Created",
      accessor: "date_created",
    },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <ActionButtons
          row={row}
          handleDelete={handleDelete}
          handleShowSenderModal={handleShowSenderModal}
        />
      ),
    },
  ];

  return (
    <section>
      <div className="my-4">{isLoading && <ClipLoader color="#94a3b8" />}</div>

      {isSuccess && (
        <Card>
          <CustomTable data={transformedData} columns={SENDER_TABLE_COLUMNS} />

          {senderIds.length === 0 && (
            <EmptyResource message="You do not have any sender ids." />
          )}
        </Card>
      )}

      <DeleteModal modalRef={deleteRef} />
      <SenderIdDetailsModal
        modalRef={senderDetailsRef}
        senderIdDetails={senderIdDetails}
      />

      {isError && <ErrorDisplay />}
    </section>
  );
};

export default TabularDisplayOfSenders;
