/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */
import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chart.js/auto';
import PropTypes from 'prop-types';

const BarChart = ({ chartHeading, subData, titleText }) => {
  return (
    <div className="">
      <h2 className="text-center">{chartHeading}</h2>
      <Bar
        data={subData}
        options={{
          plugins: {
            title: {
              display: true,
              text: `${titleText}`,
            },
          },
        }}
      />
    </div>
  );
};

BarChart.propTypes = {
  subData: PropTypes.shape({}).isRequired,
  titleText: PropTypes.string.isRequired,
  chartHeading: PropTypes.string.isRequired,
};

export default BarChart;
