// Utility function to convert base64 to blob
// This function accepts the base64 data and the blob type as arguments

const base64ToBlob = (base64Data, blobType) => {
  // Decode the base64 encoded data
  const binaryAudio = window.atob(base64Data);

  // Convert the binary data to ArrayBuffer
  const buffer = new ArrayBuffer(binaryAudio.length);
  const view = new Uint8Array(buffer);
  for (let i = 0; i < binaryAudio.length; i++) {
    view[i] = binaryAudio.charCodeAt(i);
  }

  // Create a Blob from the ArrayBuffer
  const blob = new window.Blob([buffer], { type: blobType });

  // Create a URL object from the Blob
  const url = window.URL.createObjectURL(blob);
  return url;
}

export default base64ToBlob;
