/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/function-component-definition */
import React from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import FormErrorMessage from "./form-error-message";

const SelectField = ({ label, ...props }) => {
  const [field, meta] = useField(props.name);
  return (
    <div className="w-full">
      <div>
        <label
          htmlFor={props.id || props.name}
          className="text-dark-500 text-sm font-semibold"
        >
          {label}
        </label>
        <select
          className="border-2 text-[15px] rounded-md text-slate-800 font-normal p-2 w-full focus:outline-color-primary"
          {...field}
          {...props}
        />
      </div>
      {meta.touched && meta.error ? (
        <FormErrorMessage>{meta.error}</FormErrorMessage>
      ) : null}
    </div>
  );
};

SelectField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

export default SelectField;
