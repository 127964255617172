/* eslint-disable indent */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BarChart } from '../ui';
import { useGetUserCampaignStatAsyncQuery } from './campaign-api-slice';

const CampaignBarChart = () => {
  const dispatch = useDispatch();
  const [chartData, setChartData] = useState([]);
  const userId = sessionStorage.getItem("id");

  const { data, isSuccess, isLoading, isError, error
  } = useGetUserCampaignStatAsyncQuery(userId);

  useEffect(() => {
    if (isSuccess && data) {
      setChartData(data);
    }

  }, [data, isSuccess]);

  const smsCampaigns = chartData.map((smsItem) => (smsItem.IsSms));
  const voiceCampaigns = chartData.map((voiceItem) => (voiceItem.IsVoice));

  const smsObj = {
    label: 'SMS',
    count: smsCampaigns.length,
  };
  const voiceObj = {
    label: 'Voice',
    count: voiceCampaigns.length,
  };

  const barChartData = [smsObj, voiceObj];

  const chartConfig = {
    labels: barChartData.map((labelItem) => labelItem.label),
    datasets: [{
      label: 'No. of msgs',
      data: barChartData.map((dataItem) => dataItem.count),
      backgroundColor: ['#8FCDFF', '#337AFF '],
      borderColor: '#F3F8FC',
      borderWidth: 2,
    }],
  };

  return (
    <>
      {error && (
        <div className="flex items-center justify-center mt-4">
          <p className="text-red-600 text-lg py-2 px-4 rounded-lg">
            <span className="text-red-600">{error?.data?.error}</span>
          </p>
        </div>
      )}
      <p>.</p>
      {
        isLoading ? <p>Fetching...</p> : (
          <div>
            <BarChart
              subData={chartConfig}
              chartHeading="Message Channels"
              titleText="Total no. of messages grouped by channels"
            />
          </div>
        )
      }
    </>

  );
};

export default CampaignBarChart;
