/* eslint-disable react/button-has-type */
/* eslint-disable react/function-component-definition */
import React from 'react';
import PropTypes from 'prop-types';
import { BsPip } from 'react-icons/bs';
import { ModalWrapper, Button, Input } from '../ui';

const TopupAccount = ({ modalRef }) => {
  const handleTopup = (e) => {
    e.preventDefault();
  };

  const handleDialogClose = () => {
    modalRef.current.close();
    document.body.style.overflow = 'unset';
  };

  const handleChange = () => {
  };

  return (
    <ModalWrapper modalRef={modalRef}>
      <section className="space-y-4">
        <div className="dialog-header">
          <h4 className="text-2xl text-slate-600 font-bold">Payment</h4>
          <p className="text-slate-400">Payment is powered by Paystack</p>
        </div>
        <div className="md:grid md:grid-cols-3">
          <div className="text-slate-400 md:col-span-2">
            <h4 className="text-lg text-slate-600 font-semibold">Billing Address</h4>
            <p>Fullname</p>
            <p>exampleemail@example.com</p>
            <p>0540000010</p>
          </div>
          <span className="hidden md:block">
            <BsPip className="text-color-primary text-8xl" />
          </span>
        </div>
        <form
          className="space-y-4"
          onSubmit={handleTopup}
        >
          <Input
            type="text"
            name="topup-amount"
            identity="topup-amount"
            label="Amount"
            placeholder="Enter prefered topup amount. Eg. 200"
            handleInputChange={handleChange}
          />
          <div className="dialog-footer space-y-4 md:w-[60%] md:flex md:items-baseline md:space-x-2 md:justify-center">
            <Button
              type="submit"
              label="Topup"
            >
              Topup
            </Button>
            <Button
              type="button"
              label="Cancel"
              otherStyles="bg-red-400 hover:bg-red-400"
              handleBtnClick={handleDialogClose}
            >
              Cancel
            </Button>
          </div>
        </form>
      </section>
    </ModalWrapper>
  );
};

TopupAccount.propTypes = {
  modalRef: PropTypes.instanceOf(Object).isRequired,
};

export default TopupAccount;
