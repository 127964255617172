import React from "react";
import { NavLink } from "react-router-dom";
import SubLinkItem from "./sub-link-item";

const LinkMenu = ({ title, links, expanded: isExpanded }) => {
  const [sublinksOpened, setSublinksOpened] = React.useState(false);

  const handleSublinkClick = () => {
    setSublinksOpened((prevState) => !prevState);
  };

  return (
    <div
      className={`bg-light-800 text-slate-950
      ${isExpanded && "py-5 side-nav-active"} 
      ${!isExpanded && "invisible h-0"} px-2 rounded`}
    >
      <span className="block px-2 mb-4 ml-4 text-slate-500 font-bold uppercase lg:text-[10.2px] 2xl:text-[11px]">
        {title}
      </span>

      <ul className="text-[12.5px] font-normal">
        {links.map((link) => {
          if (link.label.toLowerCase() === "instant") {
            return (
              <li
                key={link.label}
                className="rounded px-2 py-[0.4em] hover:bg-slate-300 hover:cursor-pointer"
              >
                <SubLinkItem
                  link={link}
                  sublinksOpened={sublinksOpened}
                  isExpanded={isExpanded}
                  handleSublinkClick={handleSublinkClick}
                />
              </li>
            );
          } else {
            return (
              <li
                key={link.label}
                className="rounded px-2 py-[0.4em] hover:bg-slate-300 hover:cursor-pointer"
              >
                <NavLink
                  to={link.ref}
                  className={({ isActive }) =>
                    `ml-4 ${isActive && "text-light-500"}`
                  }
                >
                  {link.label}
                </NavLink>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
};

export default LinkMenu;
