import React from "react";
import MessageTag from "./message-tag";
import { FaEnvelope } from "react-icons/fa";
import { BsSoundwave } from "react-icons/bs";
import moment from "moment";

const MessageTemplateItem = ({
  index,
  createdAt,
  is_sms: isSMS,
  is_voice: isVoice,
  language,
  content: message,
}) => {
  return (
    <div key={index} className="my-8">
      <div className="p-2 rounded-lg">
        <div className="my-4">
          <h1 className="text-sm text-slate-600">Created Date</h1>
          <p className="text-color-primary text-xs">
            {moment(createdAt).format("MMMM Do YYYY, h:mm")}
          </p>
        </div>

        {isSMS || isVoice ? (
          <MessageTag
            icon={isSMS ? <FaEnvelope /> : <BsSoundwave />}
            label={isSMS ? "SMS" : "Voice"}
            content={
              isSMS ? (
                <p className="text-sm">{message}</p>
              ) : (
                <audio src={message} controls />
              )
            }
          />
        ) : (
          <></>
        )}
        <p className="text-color-primary text-xs my-2">{language}</p>
      </div>
    </div>
  );
};

export default MessageTemplateItem;
