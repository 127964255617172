import React from "react";
import { LoginForm, AuthHeader } from "../features/auth/components";
import { Link } from "react-router-dom";
import { AppToastContainer, Card } from "../features/ui";

function LoginPage() {
  return (
    <section className="flex flex-col justify-center items-center min-h-screen">
      <Card>
        <AuthHeader title="Welcome Back!" />
        <LoginForm />
        <p className="text-center text-slate-600 text-sm my-4">
          Don&apos;t have an account yet?
          <Link to="/signup" className="inline-block text-color-primary ml-2">
            Register for an account.
          </Link>
        </p>
      </Card>
      <AppToastContainer />
    </section>
  );
}

export default LoginPage;
