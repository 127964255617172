/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { AppToast } from "../ui";
import { useGetAllGroupsByUserIdAsyncQuery } from "../groups/group-api-slice";
import { useGetAllLanguagesByUserIdAsyncQuery } from "../languages";
import {
  useAddNewSubscriberAsyncMutation,
  useGetSingleUserSubscriberAsyncMutation,
  useUpdateSubscriberDataAsycMutation,
} from "./subscription-slice";
import SubscriberFormUI from "./subscriber-form-ui";
import { useParams } from "react-router-dom";
import { ClipLoader } from "react-spinners";

const AddSubscribersForm = () => {
  const params = useParams();
  const subscriberId = params.id;
  const userId = sessionStorage.getItem("id");

  const [subscriberBio, setSubscriberBio] = React.useState({});

  const {
    data: getAllLanguagesByUserIdData,
    isSuccess: getAllLanguagesByUserIdSuccess,
  } = useGetAllLanguagesByUserIdAsyncQuery(userId);

  const {
    isSuccess: groupSuccess,
    data: getAllGroupByUserIdData,
  } = useGetAllGroupsByUserIdAsyncQuery(userId);

  const [
    getSingleSubscriber,
    {
      isLoading: getSubscriberLoading,
      // isError: isGetSubscriberError,
      // error: getSubscriberError,
      isSuccess: isGetSubscriberSuccess,
      data: subscriberData,
    },
  ] = useGetSingleUserSubscriberAsyncMutation();

  const [
    updateSubscriberDataAsyc,
    {
      isLoading: updateSubscriberLoading,
      isError: isUpdateSubscriberError,
      error: updateSubscriberError,
      isSuccess: isUpdateSubscriberSuccess,
    },
  ] = useUpdateSubscriberDataAsycMutation();

  const [
    addNewSubscriberAsync,
    {
      isLoading: addNewSubscriberLoading,
      isError: isAddNewSubscriberError,
      error: addNewSubscriberError,
      isSuccess: addNewSubscriberSuccess,
    },
  ] = useAddNewSubscriberAsyncMutation();

  const [language, setLanguage] = useState([]);
  const [group, setGroup] = useState([]);

  React.useEffect(() => {
    getSingleSubscriber({ subscriberId });
  }, [subscriberId, useGetSingleUserSubscriberAsyncMutation]);

  React.useEffect(() => {
    if (isGetSubscriberSuccess && subscriberData) {
      setSubscriberBio(subscriberData);
    }
  }, [isGetSubscriberSuccess, subscriberData]);

  useEffect(() => {
    if (groupSuccess && getAllGroupByUserIdData) {
      setGroup(getAllGroupByUserIdData);
    }
  }, [getAllGroupByUserIdData, groupSuccess]);

  useEffect(() => {
    if (getAllLanguagesByUserIdSuccess && getAllLanguagesByUserIdData) {
      setLanguage(getAllLanguagesByUserIdData);
    }
  }, [getAllLanguagesByUserIdData, getAllLanguagesByUserIdSuccess]);

  const {
    age,
    comment,
    full_name,
    gender,
    group_id,
    language: subscriberLanguage,
    phone_number,
    region,
    district,
  } = subscriberBio;
  const initialValues = {
    fullName: full_name || "",
    phoneNumber: phone_number || "",
    preferedLanguage: subscriberLanguage || "",
    region: region || "",
    district: district || "",
    gender: gender || "",
    age: age || "",
    comments: comment || "",
    group: group_id || "",
  };

  const phoneRegExp = /^(023|024|025|053|054|055|059|027|057|026|056|028|020|050)\d{7}$/;
  const validationSchema = Yup.object({
    fullName: Yup.string().required("Subscriber name cannot be blank"),
    phoneNumber: Yup.string()
      .required("Phone number cannot be blank")
      .matches(phoneRegExp, "Phone number should be valid"),
    preferedLanguage: Yup.string().required("Please choose a language"),
    region: Yup.string().required("Subscriber region cannot be blank"),
    district: Yup.string().required("Subscriber district cannot be blank"),
    gender: Yup.string().required("Please choose a gender"),
    age: Yup.number()
      .typeError("Please provide a valid age")
      .required("Please provide subscriber age"),
    comments: Yup.string(),
  });

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (subscriberId === null) {
        const response = await addNewSubscriberAsync({
          full_name: values.fullName,
          phone_number: values.phoneNumber,
          language: values.preferedLanguage,
          gender: values.gender,
          region: values.region,
          district: values.district,
          comment: values.comments,
          grp_id: values.group,
          age: values.age,
        });
        if (!response.error) {
          resetForm();
        }
      } else {
        const response = await updateSubscriberDataAsyc({
          full_name: values.fullName,
          phone_number: values.phoneNumber,
          language: values.preferedLanguage,
          gender: values.gender,
          region: values.region,
          district: values.district,
          comment: values.comments,
          grp_id: values.group,
          age: values.age,
        });
        if (!response.error) {
          resetForm();
        }
      }
    } catch (error) {}
  };

  return (
    <React.Fragment>
      {subscriberId && getSubscriberLoading ? (
        <ClipLoader className="#94a3b8" />
      ) : isGetSubscriberSuccess ? (
        <SubscriberFormUI
          initialValues={initialValues}
          validationSchema={validationSchema}
          handleSubmit={handleSubmit}
          language={language}
          group={group}
          isLoading={addNewSubscriberLoading || updateSubscriberLoading}
        />
      ) : (
        <SubscriberFormUI
          initialValues={initialValues}
          validationSchema={validationSchema}
          handleSubmit={handleSubmit}
          language={language}
          group={group}
          isLoading={addNewSubscriberLoading || updateSubscriberLoading}
        />
      )}

      <AppToast
        isSuccess={addNewSubscriberSuccess || isUpdateSubscriberSuccess}
        successMessage={
          (addNewSubscriberSuccess && "Subscriber added successfulluy") ||
          (isUpdateSubscriberSuccess && "Subscriber updated successfulluy")
        }
        isError={isAddNewSubscriberError || isUpdateSubscriberError}
        errorMessage={
          addNewSubscriberError?.data?.error ||
          updateSubscriberError?.data?.error
        }
      />
    </React.Fragment>
  );
};

export default AddSubscribersForm;
