import React from "react";
import { FaChevronRight } from "react-icons/fa";
import { Card, CustomLink, InnerPageHeader } from "../features/ui";
import { TabularDisplayOfLanguages } from "../features/languages";

const Languages = () => {
  const pageConfig = {
    title: "Manage Languages",
    subTitle: "Sender IDs are custom branding titles for your SMS camapigns.",
  };

  return (
    <React.Fragment>
      <Card>
        <section>
          <div className="md:flex md:justify-between md:items-start">
            <div className="md:basis-[60%]">
              <InnerPageHeader
                pageTitle={pageConfig.title}
                pageSubTitle={pageConfig.subTitle}
              />
            </div>
            <CustomLink
              label="New Language"
              reference="/system-setup/add-language"
              icon={<FaChevronRight />}
            />
          </div>
          <TabularDisplayOfLanguages />
        </section>
      </Card>
    </React.Fragment>
  );
};

export default Languages;
