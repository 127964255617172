import React from "react";
import { Form, Formik, Field } from "formik";
import { ClipLoader } from "react-spinners";
import { Button, FootNote, TextArea, TextInput } from "../../ui";
import AudioPlayer from "./audio-upload-player";

const InsantVoiceFormUI = ({
  initialValues,
  validationSchema,
  handleSubmit,
  isCreating,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, helpers) => handleSubmit(values, helpers)}
    >
      {({ setFieldValue }) => (
        <Form className="space-y-9">
          <div className="space-y-6 md:grid md:grid-cols-2 md:items-baseline md:gap-4">
            <div>
              <TextInput
                label="Campaign Name"
                name="campaignName"
                id="campaignName"
                type="text"
                placeholder="Please provide your campaign name."
              />
              <FootNote>
                <span className="font-bold mr-2">Note:</span>
                Provide a simple name for your campaign.
              </FootNote>
            </div>
            <div>
              <TextInput
                label="Campaign Language"
                name="campaignLanguage"
                id="campaignLanguage"
                type="text"
                placeholder="Please provide your campaign language eg. English, Fante."
              />
            </div>
          </div>
          <div>
            <div className="px-2 py-4">
              <Field name="audioFile" value={undefined}>
                {({ field, form }) => (
                  <AudioPlayer
                    onAudioUpload={(file) => {
                      form.setFieldValue("audioFile", file);
                    }}
                  />
                )}
              </Field>
            </div>
            <FootNote>
              <span className="font-bold mr-2">Note:</span>
              The audio file size should be at most 5MB.
            </FootNote>
          </div>
          <div>
            <TextArea
              label="Type/Paste Campaign Recipients."
              name="campaignRecipients"
              id="campaignRecipients"
              placeholder="Enter campaign recipients here..."
            />
            <FootNote>
              <span className="font-bold mr-2">Note:</span>
              Please ensure your numbers are seperated with commas. eg.
              0541234567,0551234567,0201234567.
            </FootNote>
          </div>
          <div className="flex justify-end">
            <Button
              type="submit"
              label="Send Campaign"
              otherStyles="bg-accent-gradient text-light-900"
              disabled={isCreating}
            >
              {isCreating && <ClipLoader color="#fff" size={20} />}
              Send Campaign
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default InsantVoiceFormUI;
