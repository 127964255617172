import React from "react";
import { Link } from "react-router-dom";
import { Form, Formik } from "formik";
import { Button, Checkbox, TextInput } from "../../ui";
import { ClipLoader } from "react-spinners";

const LoginFormUI = ({
  initialValues,
  validationSchema,
  handleSubmit,
  isLoading,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      <Form className="space-y-6">
        <div>
          <TextInput
            type="email"
            label="Email"
            name="userEmail"
            id="userEmail"
            placeholder="eg. example@gmail.com"
          />
        </div>
        <div>
          <TextInput
            type="password"
            label="Password"
            name="userPassword"
            id="userPassword"
            placeholder="please enter your password"
          />
        </div>
        <div className="flex justify-between">
          <div className="space-x-2 text-sm">
            <Checkbox
              label="Remember Me"
              type="checkbox"
              name="rememberMe"
              id="rememberMe"
            >
              Remember Me
            </Checkbox>
          </div>
          <Link to="/forgot-password" className="text-color-primary text-sm">
            Forgot your password?
          </Link>
        </div>
        <Button
          type="submit"
          disabled={isLoading}
          otherStyles="bg-accent-gradient text-light-900"
        >
          {isLoading && <ClipLoader color="#fff" size={20} />}
          Login
        </Button>
      </Form>
    </Formik>
  );
};

export default LoginFormUI;
