/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable semi */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable arrow-body-style */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { useChangePasswordMutation } from "../api/auth-api-slice";
import ChangePasswordFormUI from "./change-password-form-ui";

function ChangePasswordForm() {
  const navigate = useNavigate();
  const [
    changePassword,
    { data, isLoading, isSuccess, isError, error },
  ] = useChangePasswordMutation();
  const phoneNumber = sessionStorage.getItem("phone");

  const initialValues = {
    password: "",
  };
  const validationSchema = Yup.object({
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .label("Confirm Password")
      .required()
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  });
  useEffect(() => {
    if (isSuccess) {
      navigate("/login", { replace: true });
    }
  }, [navigate, isSuccess]);

  // Toasts
  useEffect(() => {
    if (isSuccess) {
      toast.success(data?.response, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, [isSuccess, data?.response]);

  useEffect(() => {
    if (isError) {
      toast.error(error?.data?.error, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, [isError, error?.data?.error]);

  const handleSubmit = (values) => {
    changePassword({
      password: values.password,
      phone_number: phoneNumber,
    });
  };
  return (
    <ChangePasswordFormUI
      initialValues={initialValues}
      validationSchema={validationSchema}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
    />
  );
}

export default ChangePasswordForm;
