import React from "react";
import { FaEdit, FaEye, FaTrash } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const ActionButtons = ({ row, handleDelete, handleShowProjectModal }) => {
  return (
    <div className="flex gap-x-4">
      <button
        type="button"
        onClick={() => handleDelete(row.original.id)}
        className=" hover:cursor-pointer text-red-400 hover:text"
      >
        <FaTrash />
      </button>
      <button
        className="text-emerald-400 hover:cursor-pointer hover:underline hover:decoration-2"
        onClick={() => handleShowProjectModal(row.original.id)}
      >
        <FaEye />
      </button>
      <NavLink
        to={`/content-library/projects/edit/${row.original.id}`}
        className="text-color-primary hover:cursor-pointer hover:underline hover:decoration-2"
      >
        <FaEdit />
      </NavLink>
    </div>
  );
};

export default ActionButtons;
