import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import OTPForm from "./otp-form";
import { useVerifyOTPMutation } from "../api/auth-api-slice";
import { AppToast } from "../../ui";

const OTPVerification = ({ phoneNumber }) => {
  const navigate = useNavigate();

  const [
    verifyOTP,
    { isSuccess, isLoading, isError, error },
  ] = useVerifyOTPMutation();

  useEffect(() => {
    if (isSuccess) {
      navigate("/login");
    }
  }, [navigate, isSuccess]);

  const handleSubmit = (values) => {
    verifyOTP({ secret: values.secret, phoneNumber: phoneNumber });
  };

  return (
    <React.Fragment>
      <OTPForm
        onSubmit={handleSubmit}
        initialValues={{ secret: "" }}
        loading={isLoading}
      />
      <AppToast isError={isError} errorMessage={error?.data?.error} />
    </React.Fragment>
  );
};

export default OTPVerification;
