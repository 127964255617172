/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React from "react";
import { Card, InnerPageHeader } from "../features/ui";
import { AddSenderIdForm } from "../features/senders";

const AddNewSenderId = () => {
  const pageConfig = {
    title: "SMS Sender IDs",
    subTitle: "Sender IDs are custom branding titles for your SMS camapigns.",
  };

  return (
    <React.Fragment>
      <Card>
        <section>
          <InnerPageHeader
            pageTitle={pageConfig.title}
            pageSubTitle={pageConfig.subTitle}
          />
          <AddSenderIdForm />
        </section>
      </Card>
    </React.Fragment>
  );
};

export default AddNewSenderId;
