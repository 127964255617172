import jsPDF from 'jspdf';

const generatePDFFromData = (jsonData) => {
    const pdfData = jsonData.map((item) => ({
        'Project Name': item.ProjectName,
        'Project Description': item.ProjectDescription,
        MessageTitle: item.MessageTitle,
        Recipients: item.Recipients.join(', '), // Joining recipients into a comma-separated string
        VoiceMessageCounts: item.VoiceMessageCount,
        SmsMessageCounts: item.SmsMessageCount,
    }));

    // Create a new PDF document
    const pdf = new jsPDF();

    let y = 20;
    const lineHeight = 10;
    const margin = 10;
    const pageWidth = pdf.internal.pageSize.getWidth();

    pdf.setFontSize(14);
    pdf.text('Data Report', pageWidth / 2, y, { align: 'center' });
    y += lineHeight * 2;

    pdf.setFontSize(12);
    pdf.text('Summary:', margin, y);
    y += lineHeight;

    pdf.setFontSize(10);
    pdf.setTextColor(100);
    pdf.text('Project Name: ' + pdfData[0]['Project Name'], margin, y);
    y += lineHeight;

    pdf.setTextColor(100);
    pdf.text('Project Description: ' + pdfData[0]['Project Description'], margin, y);
    y += lineHeight * 2;

    pdf.setFontSize(12);
    pdf.setTextColor(0);
    pdf.text('Details:', margin, y);
    y += lineHeight;

    pdf.setFontSize(10);
    pdf.setTextColor(100);

    pdfData.forEach((item) => {
        pdf.text(`Message Title: ${item['MessageTitle']}`, margin, y);
        y += lineHeight;

        pdf.text(`Recipients: ${item['Recipients']}`, margin, y);
        y += lineHeight;

        pdf.text(`Voice Message Counts: ${item['VoiceMessageCounts']}`, margin, y);
        y += lineHeight;

        pdf.text(`SMS Message Counts: ${item['SmsMessageCounts']}`, margin, y);
        y += lineHeight * 2;
    });

    // Save the PDF with the filename "data.pdf"
    pdf.save('report.pdf');
};

export default generatePDFFromData;
