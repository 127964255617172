const INITIAL_VALUES = {
  campaignName: "",
  smsSenderId: "",
  message: "",
  campaignRecipients: "",
};

const VOICE_CAMPAIGN_INITIAL_VALUES = {
  campaignName: "",
  campaignLanguage: "",
  audioFile: "",
  campaignRecipients: "",
};

export { INITIAL_VALUES, VOICE_CAMPAIGN_INITIAL_VALUES };
