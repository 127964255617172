import React, { useEffect, useState } from "react";
import { Card, InnerPageHeader } from "../features/ui";
import { ProfileInfo } from "../features/auth/components";
import { useGetUserBioAsyncQuery } from "../features/auth/api/auth-api-slice";

const Profile = () => {
  const userId = sessionStorage.getItem("id");
  const [userDetails, setUserDetails] = useState([]);

  const {
    data,
    isSuccess,
    // error,
    // isError
  } = useGetUserBioAsyncQuery(userId);
  useEffect(() => {
    if (isSuccess && data) {
      setUserDetails(data);
    }
  }, [isSuccess, data]);

  const pageConfig = {
    title: "Account Information",
    subTitle: "Review and update account details here.",
  };

  return (
    <React.Fragment>
      <Card>
        <section>
          <InnerPageHeader
            pageTitle={pageConfig.title}
            pageSubTitle={pageConfig.subTitle}
          />
          <div className="md:grid md:grid-cols-3">
            <div className="rounded-lg p-4 ">
              <div className="md:flex md:items-center">
                <span className="md:flex-none inline-block text-slate-500 text-base lg:text-xl mt-2 font-bold">
                  <h4>{userDetails.full_name}</h4>
                  <h4>{userDetails.email}</h4>
                </span>
              </div>
            </div>
          </div>
          <ProfileInfo />
        </section>
      </Card>
    </React.Fragment>
  );
};

export default Profile;
