import React from "react";
import { useCreateNewInstantMessageAsyncMutation } from "../messaging-slice";
import InstantSMSFormUI from "./instant-sms-form-ui";
import { INSTANT_SMS_VALIDATION_SCHEMA } from "../utils";
import { useFetchSenderIds } from "../hooks";
import { AppToast } from "../../ui";
import { INITIAL_VALUES } from "../constants";
import { CreateSenderIdModal } from "../../senders";

const InstantMessageForm = () => {
  const { senderIds } = useFetchSenderIds();
  const userId = sessionStorage.getItem("id");

  const modalRef = React.useRef();

  const [
    createNewInstantMessageAsync,
    { isLoading, isSuccess, isError, error },
  ] = useCreateNewInstantMessageAsyncMutation();

  const handleShowModal = () => {
    modalRef.current.showModal();
    document.body.style.overflow = "hidden";
  };

  const handleSubmit = async (values, { resetForm }) => {
    const campaignRecipientsList = values.campaignRecipients.split(",");

    const campaignRecipients = campaignRecipientsList.map(
      (recipientPhoneNumber) => recipientPhoneNumber.trim()
    );

    try {
      const response = await createNewInstantMessageAsync({
        id: userId,
        message_title: values.campaignName,
        sender: values.smsSenderId,
        content: values.message,
        recipients: campaignRecipients,
      });

      if (!response.error) {
        resetForm();
      }
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <InstantSMSFormUI
        initialValues={INITIAL_VALUES}
        validationSchema={INSTANT_SMS_VALIDATION_SCHEMA}
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        senderIds={senderIds}
        handleShowModal={handleShowModal}
      />
      <AppToast
        isSuccess={isSuccess}
        successMessage={"Instant message created successfully"}
        isError={isError}
        errorMessage={error?.data?.error}
      />
      <CreateSenderIdModal modalRef={modalRef} />
    </React.Fragment>
  );
};

export default InstantMessageForm;
