/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable import/named */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable arrow-body-style */
import React, { useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import { ToastContainer } from 'react-toastify';
import { Button, TextInput } from '../../ui';
import AuthHeader from './auth-header';
import { useVerifyOTPMutation, useGenerateOTPMutation } from '../api/auth-api-slice';

function OTP() {
  const [verifyOTP, { isSuccess: verifySuccess, isLoading: verifyLoading, isError, error }] = useVerifyOTPMutation();
  const [generateOTP, { data: generateOTPMutation, isSuccess: generateSuccess, isLoading: generateLoading, isError: generateError, error: generateErrorDetails }] = useGenerateOTPMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isPasswordReset = sessionStorage.getItem('pwdrst');

  const initialValues = {
    secret: '',
  };

  const accountDetails = useSelector((state) => state?.auth?.createNewAccountMutation);

  const validationSchema = Yup.object({
    secret: Yup.string().required('Please enter the code sent you.'),
  });

  useEffect(() => {
    if (verifySuccess) {
      navigate('/login');
    }
  }, [navigate, verifySuccess]);

  const handleSubmit = (values) => {
    const phoneNumber = sessionStorage.getItem('phone');
    verifyOTP({ secret: values.secret, data: phoneNumber });
  };
  const userId = sessionStorage.getItem('id');

  const handleOtpResend = () => {
    (generateOTP(userId));
  };

  return (
    <section className="bg-white rounded drop-shadow-lg p-8">
      {error && (
        <div className="flex items-center justify-center mt-4">
          <p className="bg-red-200 text-sm py-2 px-4 w-full my-2 rounded-lg">
            <span className="text-red-500">{error?.data?.error}</span>
          </p>
        </div>
      )}
      {generateSuccess && (
        <div className="flex items-center justify-center mt-4">
          <p className="bg-green-100 text-sm py-2 px-4  w-full my-2 rounded-lg">
            <span className="text-green-500">OTP Verification Sent Successfully</span>
          </p>
        </div>
      )}
      <AuthHeader title="Phone number verification" otherStyles="mb-4" />
      <span className="inline-block text-sm text-slate-400">
        {`
        An OTP has been sent to ${sessionStorage.getItem('phone')}`}
      </span>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        <Form className="space-y-6">
          <div>
            <TextInput
              type="text"
              label="Enter code here"
              name="secret"
              id="secret"
              placeholder="xxxxxx"
            />
          </div>
          <div className="space-y-1">
            <div className="text-sm text-slate-600 flex">
              <p className="mr-2">
                Didn&apos;t receive code?
              </p>
              <button
                type="button"
                className="text-color-primary"
                onClick={handleOtpResend}
                disabled={verifyLoading}
              >
                {generateLoading && <ClipLoader color="#808080" size={15} />}
                Resend
              </button>
            </div>
            {
              !isPasswordReset && (
                <div className="text-sm text-slate-500">
                  <NavLink to="/update-phone-number" className="mr-1 text-color-primary"> Click here</NavLink>
                  to udpate phone number
                </div>
              )
            }
          </div>
          <Button
            type="submit"
            disabled={verifyLoading}
          >
            {verifyLoading && <ClipLoader color="#fff" size={20} />}
            Verify
          </Button>
          <ToastContainer
            position="top-center"
            autoClose={false}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            size={10}
          />
        </Form>
      </Formik>
    </section>
  );
}

export default OTP;
