/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    token: null,
    userid: null,
  },
  reducers: {
    authenticate: (state, action) => {
      const { user, token, userid } = action.payload;
      state.user = user;
      state.token = token;
      state.userid = userid;
      sessionStorage.setItem("user", JSON.stringify(user));
      sessionStorage.setItem("token", JSON.stringify(token));
      sessionStorage.setItem("id", JSON.stringify(userid));
      Cookies.set("user", user, { expires: 7, httpOnly: true, secure: true });
      Cookies.set("token", token, {
        expires: 7,
        httpOnly: true,
        secure: true,
      });
      Cookies.set("id", userid, { expires: 7, httpOnly: true, secure: true });
    },
    logout: (state) => {
      state.user = null;
      state.token = null;
      sessionStorage.removeItem("user");
      sessionStorage.removeItem("token");
      Cookies.remove("user");
      Cookies.remove("token");
    },
  },
});

export const { authenticate, logout } = authSlice.actions;
export default authSlice.reducer;

export const selectCurrentUser = (state) => state.auth.user;
export const selectCurrentToken = (state) => state.auth.token;
