import React from "react";
import { DashboardLayout, Card } from "../features/ui";
import { AnalyticsCards } from "../features/dashboard";
import { CampaignsLineChart } from "../features/campaigns";
import moment from "moment";

const Dashboard = () => {
  const analyticsDateRange = moment().format("MMM YYYY");

  return (
    <React.Fragment>
      <AnalyticsCards />
      <Card>
        <div className="flex items-center">
          <h4 className="text-dark-200 text-[18px] font-bold">
            14 day campaign summary
            <span className="inline-block text-5xl text-light-400">.</span>
            <span className="inline-block text-light-400">
              {analyticsDateRange}
            </span>
          </h4>
        </div>
        <div>
          <CampaignsLineChart />
        </div>
      </Card>
    </React.Fragment>
  );
};

export default Dashboard;
