import React from "react";
import { Card, InnerPageHeader } from "../features/ui";
import { InstantMessageForm } from "../features/messaging/components";

const InstantMessage = () => {
  const pageConfig = {
    title: "Instant SMS campaign",
    subTitle: `Campaigns represent the Messages that are
               currently being sent to beneficiaries.`,
  };

  return (
    <React.Fragment>
      <Card>
        <section>
          <InnerPageHeader
            pageTitle={pageConfig.title}
            pageSubTitle={pageConfig.subTitle}
          />
          <InstantMessageForm />
        </section>
      </Card>
    </React.Fragment>
  );
};

export default InstantMessage;
