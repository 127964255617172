/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/button-has-type */
/* eslint-disable react/function-component-definition */
import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { useField } from "formik";
import FormErrorMessage from "./form-error-message";

const TextInput = forwardRef((props, ref) => {
  const { label, otherstyles } = props;
  const [field, meta] = useField(props.name);

  return (
    <div className="w-full">
      <div>
        <label
          htmlFor={props.id || props.name}
          className=" text-dark-500 text-sm font-semibold"
        >
          {label}
        </label>
        <input
          className={`border-2 rounded-md text-[15px] text-slate-800
            font-normal p-2 w-full focus:outline-color-primary
             file:bg-orange-200 file:hover:text-color-primary file:font-medium
            file:hover:bg-slate-50 file:hover:cursor-pointer
             file:border-0 file:p-2
            ${otherstyles}
            `}
          ref={ref}
          {...field}
          {...props}
        />
      </div>
      {meta.touched && meta.error ? (
        <FormErrorMessage>{meta.error}</FormErrorMessage>
      ) : null}
    </div>
  );
});

TextInput.propTypes = {
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default TextInput;
