/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */

import React from "react";
import PropTypes from "prop-types";
import SidenavList from "./sidenav-list";
// import Button from "../../button";

const MobileSlider = ({ customStyles }) => {
  return (
    <aside
      className={`bg-light-900 lg:hidden  flex flex-col border-r shadow-sm px-4 pt-6 w-[70%] overflow-y-scroll mobile-side-nav
      ${customStyles}`}
    >
      <SidenavList />

      {/* <Button
        otherStyles="bg-accent-gradient text-light-900"
        handleBtnClick={handleShowPurchaseTokensModal}
      >
        Purchase Tokens
      </Button> */}
    </aside>
  );
};

MobileSlider.propTypes = {
  customStyles: PropTypes.string.isRequired,
};

export default MobileSlider;
