/* eslint-disable react/function-component-definition */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TextInput } from '../../ui';

const AudioPlayer = ({ onAudioUpload }) => {
  const [audioFile, setAudioFile] = useState(null);

  const handleAudioChange = (event) => {
    const file = event.target.files[0];
    // Pass the audio file up to the CreateNewMessageForm component
    // to be used by formik
    onAudioUpload(file);
    // Get audio file url path
    const reader = new FileReader();
    reader.onload = () => {
      setAudioFile(reader.result);
    };
    reader.readAsDataURL(file);
  };

  return (
    <div className="space-y-4">
      <TextInput
        type="file"
        label="Choose an audio file to upload"
        accept="audio/*"
        name="audioFile"
        id="audioFile"
        value={undefined}
        onChange={handleAudioChange}
      />
      <audio src={audioFile} controls />
    </div>
  );
};

AudioPlayer.propTypes = {
  onAudioUpload: PropTypes.func.isRequired,
};

export default AudioPlayer;
