import React from "react";
import { AuthHeader, ChangePasswordForm } from "../features/auth/components";
import { AppToastContainer, Card } from "../features/ui";

function ChangePasswordPage() {
  return (
    <section className="flex flex-col justify-center items-center min-h-screen">
      <Card>
        <AuthHeader title="Welcome Back!" />
        <ChangePasswordForm />
      </Card>
      <AppToastContainer />
    </section>
  );
}

export default ChangePasswordPage;
