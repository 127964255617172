import React from "react";
import {
  ConditionalPhoneNumberUpdate,
  OTPResend,
  OTPVerification,
} from "../features/auth/components";
import AuthHeader from "../features/auth/components/auth-header";
import { AppToastContainer, Card } from "../features/ui";
import { useResendOTP } from "../features/auth/hooks";

function PhoneNumberVerificationPage() {
  const isPasswordReset = sessionStorage.getItem("pwdrst");
  const phoneNumber = sessionStorage.getItem("phone");

  const { handleResend } = useResendOTP();

  return (
    <section className="flex justify-center items-center min-h-screen">
      <Card className="bg-white rounded drop-shadow-lg p-8">
        <AuthHeader title="Phone number verification" otherStyles="mb-4" />

        <span className="inline-block text-sm text-slate-400">
          {`An OTP has been sent to ${phoneNumber || "xxxxxxxxxx"}`}
        </span>

        <div className="space-y-2 text-sm">
          <OTPVerification phoneNumber={phoneNumber} />
          <OTPResend
            userId={sessionStorage.getItem("id")}
            onResend={handleResend}
          />
        </div>

        <ConditionalPhoneNumberUpdate isPasswordReset={isPasswordReset} />
      </Card>
      <AppToastContainer />
    </section>
  );
}

export default PhoneNumberVerificationPage;
