/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React from "react";
import { Card, InnerPageHeader } from "../features/ui";
import { AddSubscribersForm } from "../features/subscriptions";

const EditSubscriber = () => {
  const pageConfig = {
    title: "Update Subscriber Details",
    subTitle:
      "Subscribers are who we want to engage with (send SMS & Voice to) on the platform.",
  };

  return (
    <React.Fragment>
      <Card>
        <InnerPageHeader
          pageTitle={pageConfig.title}
          pageSubTitle={pageConfig.subTitle}
        />
        <AddSubscribersForm />
      </Card>
    </React.Fragment>
  );
};

export default EditSubscriber;
