import React from "react";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const SubLinkItem = ({
  link,
  sublinksOpened,
  isExpanded,
  handleSublinkClick,
}) => {
  return (
    <React.Fragment>
      <div className="ml-4 my-[0.2em]">
        <button
          className="flex justify-between items-center"
          onClick={handleSublinkClick}
        >
          {link.label}
          <span className="ml-4">
            {sublinksOpened ? <FaChevronDown /> : <FaChevronRight />}
          </span>
        </button>
        {sublinksOpened && (
          <ul
            className={`${
              isExpanded
                ? "mt-2 bg-slate-50 bg-opacity-50 px-2 py-2 rounded-lg"
                : "bg-inherit"
            }`}
          >
            {link.links.map((item, index) => (
              <li
                key={index}
                className="hover:bg-slate-300 px-2 py-1 rounded-md"
              >
                <NavLink to={item.ref}>{item.label}</NavLink>
              </li>
            ))}
          </ul>
        )}
      </div>
    </React.Fragment>
  );
};

export default SubLinkItem;
