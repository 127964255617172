/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React from "react";
import PropTypes from "prop-types";

const InnerPageHeader = ({ pageTitle, pageSubTitle }) => {
  return (
    <div className="space-y-4 md:flex md:justify-between md:items-center">
      <div className="py-2 md:w-[50%]">
        <h1 className="text-xl text-slate-700 font-bold md:text-4xl md:font-extrabold">
          {pageTitle}
        </h1>
        <p className="py-2 text-sm text-slate-500">{pageSubTitle}</p>
      </div>
    </div>
  );
};

InnerPageHeader.propTypes = {
  pageTitle: PropTypes.string.isRequired,
  pageSubTitle: PropTypes.string.isRequired,
};

export default InnerPageHeader;
