/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React, { useState } from 'react';
import {
  Card,
  InnerPageHeader,
} from '../features/ui';
import {
  InstantOutboundCampaignLog,
  RoutineOutboundCampaignLog,
  FixedOutboundCampaignLog,
  // DeliveryReport,
  // TabularDisplayOfCampaignLogs,
} from '../features/campaigns';
import TabButton from '../features/campaigns/tab-button';

const CampaignLogs = () => {
  const pageConfig = {
    title: 'Outbound Campaigns',
    subTitle: 'Campaigns represent the Messages that are currently being sent to beneficiaries.',
  };

  const [tab, setTab] = useState('instant');
  const handleTabChange = (tabName) => {
    setTab(tabName);
  };

  return (
    <React.Fragment>
      <InnerPageHeader
        pageTitle={pageConfig.title}
        pageSubTitle={pageConfig.subTitle}
      />
      <Card>
        <div className="tabs w-full border-b-2 my-4">
          <TabButton
            isActive={tab === 'instant'}
            onTabChange={() => handleTabChange('instant')}
          >
            Instant
          </TabButton>
          <TabButton
            isActive={tab === 'fixed'}
            onTabChange={() => handleTabChange('fixed')}
          >
            Fixed Scheduled
          </TabButton>
          <TabButton
            isActive={tab === 'routine'}
            onTabChange={() => handleTabChange('routine')}
          >
            Routine Scheduled
          </TabButton>
        </div>
        {
          tab === 'instant'
          && (<InstantOutboundCampaignLog />)
        }
        {
          tab === 'fixed'
          && (<FixedOutboundCampaignLog />)
        }
        {
          tab === 'routine'
          && (<RoutineOutboundCampaignLog />)
        }
      </Card>
    </React.Fragment>
  );
};

export default CampaignLogs;
