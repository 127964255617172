import { Form, Formik } from "formik";
import React from "react";
import { Button, Checkbox, FootNote, SelectField, TextInput } from "../../ui";
import { ClipLoader } from "react-spinners";
import ResourceSelectField from "./resource-select-field";
import { Link } from "react-router-dom";

const NewOutboundCampaignFormUI = ({
  initialValues,
  validationSchema,
  handleSubmit,
  ...otherProps
}) => {
  const {
    campaignProjects,
    campaignSender,
    campaignMessage,
    campaignGroups,
    campaignSubscribers,
    createNewScheduledMessageLoading,
    handleScheduleTypeChange,
    scheduleType,
    handleShowModal,
  } = otherProps;
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, helpers) => handleSubmit(values, helpers)}
      enableReinitialize
    >
      {({ values, handleChange }) => (
        <Form className="mt-4 space-y-6">
          <div className="space-y-4 md:grid md:grid-cols-3 md:items-baseline md:gap-x-4">
            <div>
              <TextInput
                label="Campaign Name"
                name="campaignName"
                id="campaignName"
                type="text"
                placeholder="Enter campaign name"
              />
              <FootNote>
                <span className="font-bold mr-2">Note:</span>
                Provide a simple name for your campaign.
              </FootNote>
            </div>
            <div>
              <ResourceSelectField
                label="Choose Project"
                name="projectName"
                id="projectName"
                resource={campaignProjects}
              />
              <FootNote>
                <span className="font-bold mr-2">Note:</span>
                You can create a new project{" "}
                <Link
                  to="/content-library/add-project"
                  className="text-color-primary"
                >
                  here
                </Link>
              </FootNote>
            </div>
            <div>
              <ResourceSelectField
                label="Choose SMS sender ID"
                name="smsSenderId"
                id="smsSenderId"
                resource={campaignSender}
              />
              <FootNote>
                <span className="font-bold mr-2">Note:</span>
                You can create a new SenderID{" "}
                <button
                  className="text-color-primary"
                  onClick={handleShowModal}
                >
                  here
                </button>
              </FootNote>
            </div>
          </div>
          <div>
            <SelectField
              label="Choose a Message"
              name="campaignMessage"
              id="campaignMessage"
            >
              <option>Select Message</option>
              {campaignMessage &&
                campaignMessage.map((message, index) => (
                  <option
                    key={index}
                    value={message.id}
                    data-sms={message.is_sms}
                    data-voice={message.is_voice}
                  >
                    {message.title}
                  </option>
                ))}
            </SelectField>
            <FootNote>
              <span className="font-bold mr-2">Note:</span>
              Select a message to send.
            </FootNote>
          </div>

          <div className="md:grid md:grid-cols-3">
            <Checkbox
              type="radio"
              label=""
              name="recipientType"
              id="allSubscribers"
              value="All Subscribers"
              onChange={handleChange}
              checked={values.recipientType === "All Subscribers"}
            >
              All Subscribers
            </Checkbox>
            <Checkbox
              type="radio"
              label=""
              name="recipientType"
              id="selectGroups"
              value="Select Group(s)"
              onChange={handleChange}
              checked={values.recipientType === "Select Group(s)"}
            >
              Select Group(s)
            </Checkbox>
            <Checkbox
              type="radio"
              label=""
              name="recipientType"
              id="selectSubscribers"
              value="Select Subscribers"
              onChange={handleChange}
              checked={values.recipientType === "Select Subscribers"}
            >
              Select Subscribers
            </Checkbox>
          </div>

          <div className="md:grid md:grid-cols-1">
            {values.recipientType.toLocaleLowerCase() === "select group(s)" && (
              <div className="border-2 p-2 rounded-lg">
                <p className="text-slate-500">
                  Choose Groups you wish to send this Campaign.
                </p>
                <div className="my-4 flex flex-wrap">
                  {campaignGroups &&
                    campaignGroups.map((groupItem, index) => (
                      <div className="mr-2">
                        <Checkbox
                          key={index}
                          type="checkbox"
                          label=""
                          name="subscribersByGroup"
                          id={groupItem.CreatedAt}
                          checked={values.subscribersByGroup.includes(
                            groupItem.ID
                          )}
                          onChange={handleChange}
                          value={groupItem.ID}
                        >
                          {groupItem.Name}
                        </Checkbox>
                      </div>
                    ))}
                </div>
              </div>
            )}
            {values.recipientType.toLocaleLowerCase() ===
              "select subscribers" && (
              <div className="border-2 p-2 rounded-lg">
                <p className="text-slate-500">
                  Choose subscribers you wish to send this campaign to.
                </p>
                <div className="my-4 flex flex-wrap">
                  {campaignSubscribers &&
                    campaignSubscribers.map((subscriberItem, index) => (
                      <div className="mr-2">
                        <Checkbox
                          key={index}
                          type="checkbox"
                          label=""
                          name="subscribersBySub"
                          id={subscriberItem.created_at}
                          value={subscriberItem.id}
                          checked={values.subscribersBySub.includes(
                            subscriberItem.id
                          )}
                          onChange={handleChange}
                        >
                          {subscriberItem.full_name}
                        </Checkbox>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>

          <div className="mb-4">
            <div>
              <SelectField
                label="Schedule Type"
                name="scheduleType"
                id="scheduleType"
                value={scheduleType}
                onChange={handleScheduleTypeChange}
              >
                <option>Choose prefered schedule</option>
                <option value="Now">Now</option>
                <option value="Fixed">Fixed</option>
                <option value="Routine">Routine</option>
              </SelectField>
            </div>
          </div>
          <div className="mb-4">
            {scheduleType !== "Now" && (
              <div>
                <TextInput
                  label="Pick a Date & Time"
                  name="scheduleDate"
                  id="scheduleDate"
                  type="datetime-local"
                />
              </div>
            )}
          </div>
          <div>
            {scheduleType === "Routine" && (
              <div className="mb-4">
                <div>
                  <TextInput
                    label="Pick an end date"
                    name="endDate"
                    id="endDate"
                    type="datetime-local"
                  />
                </div>
              </div>
            )}
          </div>

          <div className="flex justify-end">
            <Button
              type="submit"
              label="Submit Campaign"
              otherStyles="bg-accent-gradient text-light-900"
              disabled={createNewScheduledMessageLoading}
            >
              {createNewScheduledMessageLoading && (
                <ClipLoader color="#fff" size={20} />
              )}
              Submit Campaign
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default NewOutboundCampaignFormUI;
