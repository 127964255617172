import React from "react";
import { FaEdit, FaEye, FaTrash } from "react-icons/fa";
import { NavLink } from "react-router-dom";

const ActionButtons = ({ row, handleDelete }) => {
  return (
    <div className="flex gap-x-4">
      <button
        type="button"
        onClick={() => handleDelete(row.original.id)}
        className={"hover:cursor-pointer text-red-400 hover:text disabled:text-light-400"}
        disabled={row.original.title.toLowerCase() === "ungrouped"}
      >
        <FaTrash />
      </button>
      <NavLink
        to={`/subscribers/view-group-subscribers/${row.original.id}?title=${row.original.title}&sub_count=${row.original.subscriber_count}&desc=${row.original.description}`}
        className="text-emerald-400 hover:cursor-pointer hover:underline hover:decoration-2"
      >
        <FaEye />
      </NavLink>
      <NavLink
        to={`/subscribers/edit-group-details/${row.original.id}`}
        className="text-color-primary hover:cursor-pointer hover:underline hover:decoration-2"
      >
        <FaEdit />
      </NavLink>
    </div>
  );
};

export default ActionButtons;
