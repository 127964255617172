import React from "react";
import { useGetAllSendersByUserIdAsyncQuery } from "../../senders";

const useFetchSenderIds = () => {
  const userId = sessionStorage.getItem('id');
  const [senderIds, setSenderIds] = React.useState([]);

  const {
    data,
    isSuccess,
    // error,
    // isError,
    isLoading,
  } = useGetAllSendersByUserIdAsyncQuery(userId);

  React.useEffect(() => {
    if (isSuccess && data) {
        setSenderIds(data);
    }
  }, [isSuccess, data]);

  return {
    senderIds,
    isLoading
  };
};

export default useFetchSenderIds;
