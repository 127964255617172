/* eslint-disable import/named */
import React from "react";
import {
  LoginPage,
  SignupPage,
  ForgotPasswordPage,
  ResetPasswordPage,
  Dashboard,
  Subscribers,
  Groups,
  ImportSubscribers,
  ExportSubscribers,
  MessageTemplates,
  Projects,
  Senders,
  Languages,
  ConfigureOutboundCampaign,
  CampaignLogs,
  GenerateReport,
  Profile,
  CreditBilling,
  InstantMessage,
  AddNewSubscriber,
  AddNewGroup,
  AddNewProject,
  AddNewSenderId,
  AddNewLanguage,
  AddNewMessage,
  EditProfile,
  ChangePasswordPage,
  EditSubscriber,
  ViewGroupSubscribers,
  EditGroup,
  // ViewProjectDetails,
  EditProject,
  ViewMessageTemplateDetails,
  Statistics,
  InstantVoice,
  ForgotPasswordOTPVerificationPage,
  PhoneNumberVerificationPage,
  PhoneNumberUpdatePage,
  UpdatePassword,
  DeliveryReport,
} from "../pages";

const APP_ROUTES = [
  {
    path: "/",
    element: <LoginPage />,
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/signup",
    element: <SignupPage />,
  },
  {
    path: "/phone-number-verification",
    element: <PhoneNumberVerificationPage />,
  },
  {
    path: "/update-phone-number",
    element: <PhoneNumberUpdatePage />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPasswordPage />,
  },
  {
    path: "/reset-password",
    element: <ResetPasswordPage />,
  },
  {
    path: "/forget-password-otp-verification",
    element: <ForgotPasswordOTPVerificationPage />,
  },
  {
    path: "/change-password",
    element: <ChangePasswordPage />,
  },
];

const PROTECTED_ROUTES = [
  {
    path: "/dashboard",
    element: <Dashboard />,
  },
  {
    path: "/subscribers/all-subscribers",
    element: <Subscribers />,
  },
  {
    path: "/subscribers/groups",
    element: <Groups />,
  },
  {
    path: "/subscribers/import",
    element: <ImportSubscribers />,
  },
  {
    path: "/reports/export-subscribers",
    element: <ExportSubscribers />,
  },
  {
    path: "/content-library/message-templates",
    element: <MessageTemplates />,
  },
  {
    path: "/content-library/message-templates/:templateId",
    element: <ViewMessageTemplateDetails />,
  },
  {
    path: "/content-library/projects",
    element: <Projects />,
  },
  {
    path: "/messaging/scheduled",
    element: <ConfigureOutboundCampaign />,
  },
  {
    path: "/outbound-campaigns",
    element: <CampaignLogs />,
  },
  {
    path: "/generate-report",
    element: <GenerateReport />,
  },
  {
    path: "/statistics",
    element: <Statistics />,
  },
  {
    path: "/account/view-profile",
    element: <Profile />,
  },
  {
    path: "/credit",
    element: <CreditBilling />,
  },
  {
    path: "/messaging/instant-sms",
    element: <InstantMessage />,
  },
  {
    path: "/messaging/instant-voice",
    element: <InstantVoice />,
  },
  {
    path: "/subscribers/new-subscriber",
    element: <AddNewSubscriber />,
  },
  {
    path: "/subscribers/add-group",
    element: <AddNewGroup />,
  },
  {
    path: "/content-library/add-project",
    element: <AddNewProject />,
  },
  {
    path: "/content-library/projects/edit/:id",
    element: <EditProject />,
  },
  {
    path: "/content-library/new-message-template",
    element: <AddNewMessage />,
  },
  {
    path: "/account/edit-profile",
    element: <EditProfile />,
  },
  {
    path: "/account/update-password",
    element: <UpdatePassword />,
  },
  {
    path: "/subscribers/edit/:id",
    element: <EditSubscriber />,
  },
  {
    path: "/subscribers/view-group-subscribers/:id",
    element: <ViewGroupSubscribers />,
  },
  {
    path: "/subscribers/edit-group-details/:id",
    element: <EditGroup />,
  },
  {
    path: "/delivery-reports",
    element: <DeliveryReport />,
  },
  {
    path: "/system-setup/languages",
    element: <Languages />,
  },
  {
    path: "/system-setup/languages/edit/:id",
    element: <AddNewLanguage />,
  },
  {
    path: "/system-setup/add-language",
    element: <AddNewLanguage />,
  },
  {
    path: "/system-setup/new-sender-id",
    element: <AddNewSenderId />,
  },
  {
    path: "/system-setup/senders",
    element: <Senders />,
  },
];

export { PROTECTED_ROUTES };
export default APP_ROUTES;
