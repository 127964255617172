import React from "react";
import { CustomTable } from "../ui";
import ActionButtons from "./action-buttons";
import { transformTableData } from "./utils";
import DeleteModal from "./delete-modal";

const GroupListUI = ({ groups }) => {
  const deleteModalRef = React.useRef();

  const handleDelete = (id) => {
    if (typeof window !== "undefined") {
      sessionStorage.setItem("groupId", id);
    }
    deleteModalRef.current.showModal();
    document.body.style.overflow = "hidden";
  };

  const TABLE_COLUMNS = [
    {
      Header: "S/N",
      accessor: "serial",
    },
    {
      Header: "Group Name",
      accessor: "title",
    },
    {
      Header: "No. of Subscribers",
      accessor: "subscriber_count",
    },
    {
      Header: "Date Created",
      accessor: "date_created",
    },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <ActionButtons row={row} handleDelete={handleDelete} />
      ),
    },
  ];

  const transformedData = transformTableData(groups);

  return (
    <React.Fragment>
      <CustomTable data={transformedData} columns={TABLE_COLUMNS} />
      <DeleteModal modalRef={deleteModalRef} />
    </React.Fragment>
  );
};

export default GroupListUI;
