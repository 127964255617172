import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { ClipLoader } from "react-spinners";
import { Card, CustomTable, EmptyResource, ErrorDisplay } from "../ui";
import { useGetAllLanguagesByUserIdAsyncQuery } from "./language-slice";
import ActionButtons from "./action-buttons";
import { transformTableData } from "./utils";
import DeleteModal from "./delete-modal";

const TabularDisplayOfLanguages = () => {
  const userId = sessionStorage.getItem("id");
  const [languages, setLanguages] = useState([]);
  const deleteLanguageRef = React.useRef();

  const {
    isLoading,
    isSuccess,
    isError,
    data,
  } = useGetAllLanguagesByUserIdAsyncQuery(userId);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isSuccess && data) {
      setLanguages(data);
    }
  }, [dispatch, isSuccess, data]);

  const handleDelete = (id) => {
    if (typeof window !== "undefined") {
      sessionStorage.setItem("langId", id);
      deleteLanguageRef.current.showModal();
    }
  };

  const LANGUAGE_TABLE_COLUMNS = [
    {
      Header: "#",
      accessor: "serial",
    },
    {
      Header: "Language",
      accessor: "title",
    },
    {
      Header: "Date Created",
      accessor: "date_created",
    },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <ActionButtons row={row} handleDelete={handleDelete} />
      ),
    },
  ];

  const transformedData = transformTableData(languages);

  return (
    <section>
      <div className="my-4">{isLoading && <ClipLoader color="#94a3b8" />}</div>

      {isSuccess && (
        <Card>
          <CustomTable
            data={transformedData}
            columns={LANGUAGE_TABLE_COLUMNS}
          />

          {languages.length === 0 && (
            <EmptyResource message="You do not have any projects." />
          )}
        </Card>
      )}

      <DeleteModal modalRef={deleteLanguageRef} />
      {isError && <ErrorDisplay />}
    </section>
  );
};

export default TabularDisplayOfLanguages;
