import React from "react";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

const AppToast = ({ isSuccess, successMessage, isError, errorMessage }) => {
  React.useEffect(() => {
    if (isSuccess) {
      toast.success(successMessage, {
        position: toast.POSITION.TOP_CENTER,
        toastId: `${uuidv4()}`,
      });
    }
  }, [isSuccess, successMessage]);

  React.useEffect(() => {
    if (isError) {
      toast.error(errorMessage, {
        position: toast.POSITION.TOP_CENTER,
        toastId: `${uuidv4()}`,
      });
    }
  }, [isError, errorMessage]);

  return null;
};

export default AppToast;
