import React from "react";
import { Form, Formik } from "formik";
import { Button, TextInput } from "../../ui";
import { ClipLoader } from "react-spinners";

const ForgotPasswordFormUI = ({
  initialValues,
  validationSchema,
  handleSubmit,
  isLoading,
}) => {
  return (
    <Formik
      onSubmit={(values) => handleSubmit(values)}
      initialValues={initialValues}
      validationSchema={validationSchema}
    >
      <Form className="space-y-6">
        <TextInput
          type="text"
          label="Registered Phone Number"
          name="phoneNumber"
          id="phoneNumber"
          placeholder="eg. 0551234567"
        />
        <Button
          type="submit"
          label="Send SMS"
          disabled={isLoading}
          otherStyles="bg-accent-gradient text-light-900"
        >
          {isLoading && <ClipLoader color="#fff" size={20} />}
          Submit
        </Button>
      </Form>
    </Formik>
  );
};

export default ForgotPasswordFormUI;
