const MessageTag = ({ icon, label, content }) => {
  return (
    <div>
      <h1 className="font-medium text-slate-600 flex items-center my-4">
        <span className="mr-2">{label}</span>
        {icon}
      </h1>
      {content}
    </div>
  );
};

export default MessageTag;
