import React from 'react';
import { ToastContainer } from 'react-toastify';

function AppToastContainer() {
  return (
    <ToastContainer
      position="top-center"
      autoClose={8000}
      hideProgressBar={false}
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      size={10}
    />
  );
}

export default AppToastContainer;
