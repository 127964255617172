import moment from "moment";

const transformTableData = (data) => {
  if (!Array.isArray(data) || data.length === 0) {
    return [];
  }

  return data.map((group, index) => ({
    serial: index + 1,
    id: group.ID,
    title: group.Name,
    subscriber_count: group.SubscriberCount,
    description: group.Description,
    date_created: moment(group.created_at).format("DD-MM-YYYY"),
  }));
};

export default transformTableData;
