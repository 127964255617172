/* eslint-disable react/button-has-type */
/* eslint-disable react/function-component-definition */
import React from "react";
import PropTypes from "prop-types";

const Card = ({ children, otherStyles }) => (
  <div
    className={`bg-light-900 p-4 my-2 shadow-sm rounded-sm md:py-8 ${otherStyles}`}
  >
    {children}
  </div>
);

Card.defaultProps = {
  otherStyles: "",
};

Card.propTypes = {
  children: PropTypes.node.isRequired,
  otherStyles: PropTypes.string,
};

export default Card;
