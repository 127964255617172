/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React from 'react';
import CampaignReportGenerationForm from './campaign-report-generation-form';
import { Card } from '../ui';

const DisplayGeneratedCampaignReport = () => {
  return (
    <>
      <CampaignReportGenerationForm />
      <Card>
        <p className="text-center text-slate-500">Nothing to display here</p>
      </Card>
    </>
  );
};

export default DisplayGeneratedCampaignReport;
