/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React from "react";
import { Card, InnerPageHeader } from "../features/ui";
import { CampaignBarChart, CampaignPieChart } from "../features/campaigns";

const Statistics = () => {
  const pageConfig = {
    title: "Statistics",
    subTitle: "",
  };

  return (
    <React.Fragment>
      <InnerPageHeader
        pageTitle={pageConfig.title}
        pageSubTitle={pageConfig.subTitle}
      />

      <Card>
        <div className="md:grid md:grid-cols-3">
          <div>
            <CampaignPieChart />
          </div>
          <div className="md:col-span-2">
            <CampaignBarChart />
          </div>
        </div>
      </Card>
    </React.Fragment>
  );
};

export default Statistics;
