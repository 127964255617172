/* eslint-disable react/button-has-type */
/* eslint-disable react/function-component-definition */
import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

const ModalWrapper = ({ children, modalRef }) => (
  createPortal(
    <dialog
      ref={modalRef}
      id="appDialog"
      className="w-full my-0 mx-auto mt-16 p-4 bg-white backdrop:bg-slate-500 backdrop:opacity-90 rounded-lg md:max-w-[500px]"
    >
      {children}
    </dialog>,
    document.body,
  )

);

ModalWrapper.propTypes = {
  children: PropTypes.node.isRequired,
  modalRef: PropTypes.instanceOf(Object).isRequired,
};

export default ModalWrapper;
