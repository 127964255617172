/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/function-component-definition */
import React from 'react';
import PropTypes from 'prop-types';

const FormErrorMessage = ({ children }) => (
  <p className="text-rose-400 text-sm p-2">{children}</p>
);

FormErrorMessage.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FormErrorMessage;
