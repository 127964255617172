/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable max-len */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React from "react";
import { Formik, Form } from "formik";
import PropTypes from "prop-types";
import * as Yup from "yup";
import { ClipLoader } from "react-spinners";
import { AppToast, Button, TextArea, TextInput } from "../ui";
import { useCreateNewGroupAsyncMutation } from "./group-api-slice";

const AddGroupForm = ({ hideControls }) => {
  const [
    createNewGroupAsync,
    { isSuccess, isError, error, isLoading },
  ] = useCreateNewGroupAsyncMutation();

  const initialValues = {
    groupName: "",
    groupDescription: "",
    groupTags: "",
  };

  const validationSchema = Yup.object({
    groupName: Yup.string().required("Group name cannot be blank"),
    groupDescription: Yup.string().required("Description cannot be blank"),
    groupTags: Yup.string(),
  });

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const result = await createNewGroupAsync({
        name: values.groupName,
        description: values.groupDescription,
        tags: values.groupTags,
      });

      if (!result.error) {
        resetForm();
      }
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, helpers) => handleSubmit(values, helpers)}
      >
        <Form className="space-y-4">
          <div className="space-y-4">
            <div>
              <TextInput
                label="Group Name"
                id="groupName"
                name="groupName"
                placeholder="Enter name of group"
              />
            </div>
            <div>
              <TextArea
                label="Description"
                name="groupDescription"
                id="groupDescription"
                placeholder="Enter description of group here"
              />
            </div>
            <div>
              <TextInput
                label="Group Tags"
                id="groupTags"
                name="groupTags"
                placeholder="Enter tags for this group. Eg. Tech, Finance etc."
              />
            </div>
          </div>
          {!hideControls && (
            <div className="md:gap-2 md:flex md:justify-end md:items-center">
              <Button
                type="submit"
                label="Add Group"
                disabled={isLoading}
                otherStyles="bg-accent-gradient text-light-900 hover:bg-green-600"
              >
                {isLoading && <ClipLoader color="#fff" size={20} />}
                Create
              </Button>
            </div>
          )}
        </Form>
      </Formik>
      <AppToast
        isSuccess={isSuccess}
        successMessage="Group created successfully"
        isError={isError}
        errorMessage={error?.data?.message}
      />
    </React.Fragment>
  );
};

AddGroupForm.defaultProps = {
  hideControls: false,
  scale: true,
};

AddGroupForm.propTypes = {
  hideControls: PropTypes.bool,
  scale: PropTypes.bool,
};

export default AddGroupForm;
