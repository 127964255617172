/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React from "react";
import { Card, InnerPageHeader } from "../features/ui";
import { EditGroupForm } from "../features/groups";

const EditGroup = () => {
  const pageConfig = {
    title: "Update Group Details",
  };

  return (
    <React.Fragment>
      <Card>
        <InnerPageHeader
          pageTitle={pageConfig.title}
          pageSubTitle={pageConfig.subTitle}
        />
        <EditGroupForm />
      </Card>
    </React.Fragment>
  );
};

export default EditGroup;
