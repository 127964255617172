/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { ClipLoader } from "react-spinners";
import { Button, TextInput } from "../../ui";
import { useChangePasswordMutation } from "../api/auth-api-slice";

const UpdatePasswordForm = () => {
  const [
    changePassword,
    { isLoading, isSuccess, isError, error },
  ] = useChangePasswordMutation();

  const initialValues = {
    newPassword: "",
    confirmPassword: "",
  };

  const validationSchema = Yup.object({
    newPassword: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .label("Confirm Password")
      .required()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match"),
  });

  useEffect(() => {
    if (isSuccess) {
      toast.success("Password updated successfully", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      toast.error(error?.data?.error, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, [isError, error?.data?.error]);

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const response = await changePassword({
        password: values.newPassword,
        phone_number: JSON.parse(sessionStorage.getItem("accountPhone")),
      });
      if (!response.error) resetForm();
    } catch (error) {}
  };

  return (
    <section>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, helpers) => handleSubmit(values, helpers)}
        validationSchema={validationSchema}
      >
        <Form className="space-y-4">
          <TextInput
            type="password"
            label="New Password"
            name="newPassword"
            id="newPassword"
          />
          <TextInput
            type="password"
            label="Confirm Password"
            name="confirmPassword"
            id="confirmPassword"
          />
          <div className="mt-10 flex justify-end">
            <Button
              type="submit"
              label="Save Changes"
              otherStyles="bg-accent-gradient text-light-900"
              disabled={isLoading}
            >
              {isLoading && <ClipLoader color="#fff" size={20} />}
              Save Changes
            </Button>
          </div>
        </Form>
      </Formik>
    </section>
  );
};

export default UpdatePasswordForm;
