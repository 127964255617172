import React from 'react';
import { Field } from 'formik';
import { TextArea } from '../../ui';

const SmsTempUploadTable = ({ data }) => {
    return (
        <div className="overflow-x-auto">
            <table className="min-w-full border-collapse">
                <thead>
                    <tr>
                        <th className="border border-gray-400 px-4 py-2">Language</th>
                        <th className="border border-gray-400 px-4 py-2">Message</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index} className={index % 2 === 0 ? 'bg-gray-100' : ''}>
                            <td className="border border-gray-400 px-4 py-2">{item.language}</td>
                            <td className="border border-gray-400 px-4 py-2">
                                <Field name={`smsMessages.${item.id}`}>
                                    {({ field, form }) => (
                                        <TextArea
                                            label="SMS Message"
                                            name={field.name}
                                            id={field.name}
                                            placeholder="Type your message here"
                                            value={field.value}
                                            onChange={field.onChange}
                                            onBlur={field.onBlur}
                                        />
                                    )}
                                </Field>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default SmsTempUploadTable;
