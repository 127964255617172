/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable max-len */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React from "react";
import * as Yup from "yup";
import { ClipLoader } from "react-spinners";
import { ErrorDisplay } from "../ui";
import {
  useGetSingleGroupByUserIdAsyncMutation,
  useUpdateGroupAsyncMutation,
} from "./group-api-slice";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import EditGroupFormUI from "./edit-group-form-ui";

const EditGroupForm = () => {
  const navigate = useNavigate();
  const { id: groupId } = useParams();

  const [groupData, setGroupData] = React.useState({});

  const [
    getSingleGroupByUserIdAsync,
    { isLoading, isSuccess, isError },
  ] = useGetSingleGroupByUserIdAsyncMutation();

  const [
    updateGroupAsyc,
    { isLoading: updateLoading, isSuccess: updateSuccess, error },
  ] = useUpdateGroupAsyncMutation();

  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getSingleGroupByUserIdAsync({ id: groupId });
        setGroupData(response?.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [getSingleGroupByUserIdAsync, groupId]);

  const { id, name, description, tags } = groupData || {};
  let initialValues = {
    groupId: id,
    groupName: name || "",
    groupDescription: description || "",
    groupTags: tags || "",
  };

  if (typeof groupData === "object" && groupData !== null) {
    const hasID = Object.prototype.hasOwnProperty.call(groupData, "ID");

    if (hasID) {
      const { id, name, description, tags } = groupData;
      initialValues = {
        groupId: id,
        groupName: name || "",
        groupDescription: description || "",
        groupTags: tags || "",
      };
    }
  }

  React.useEffect(() => {
    if (updateSuccess) {
      toast.success("Group updated successfully", {
        position: toast.POSITION.TOP_CENTER,
      });
      navigate("/subscribers/groups");
    }
  }, [updateSuccess, navigate]);

  React.useEffect(() => {
    if (error) {
      toast.error(error?.data?.error, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, [error]);

  const validationSchema = Yup.object({
    groupName: Yup.string().required("Group name cannot be blank"),
    groupDescription: Yup.string().required("Description cannot be blank"),
    groupTags: Yup.string(),
  });

  const handleSubmit = async (values, { resetForm }) => {
    resetForm();
    updateGroupAsyc({
      id: groupId,
      name: values.groupName,
      description: values.groupDescription,
      tags: values.groupTags,
    });
  };

  return (
    <section>
      {isLoading ? <ClipLoader color="#808080" /> : null}

      {isSuccess ? (
        <EditGroupFormUI
          initialValues={initialValues}
          validationSchema={validationSchema}
          handleSubmit={handleSubmit}
          updateLoading={updateLoading}
        />
      ) : null}

      {isError ? <ErrorDisplay /> : null}
    </section>
  );
};

export default EditGroupForm;
