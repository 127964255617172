import { Form, Formik } from "formik";
import React from "react";
import { Button, TextArea, TextInput } from "../ui";
import { ClipLoader } from "react-spinners";

const EditGroupFormUI = ({
  initialValues,
  validationSchema,
  handleSubmit,
  updateLoading,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      enableReinitialize
      onSubmit={(values, helpers) => handleSubmit(values, helpers)}
    >
      <Form className="space-y-4">
        <div className="space-y-4">
          <div>
            <TextInput
              label="Group Name"
              id="groupName"
              name="groupName"
              placeholder="Enter name of group"
            />
          </div>
          <div>
            <TextArea
              label="Description"
              name="groupDescription"
              id="groupDescription"
              placeholder="Enter description of group here"
            />
          </div>
          <div>
            <TextInput
              label="Group Tags"
              id="groupTags"
              name="groupTags"
              placeholder="Enter tags for this group. Eg. Tech, Finance etc."
            />
          </div>
        </div>
        <div className="md:flex md:justify-end">
          <Button
            type="submit"
            label="Update Group"
            otherStyles="bg-accent-gradient text-light-900"
            loading={updateLoading}
          >
            {updateLoading && <ClipLoader color="#fff" size={20} />}
            Save Changes
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

export default EditGroupFormUI;
