import React from "react";
import { Line } from "react-chartjs-2";
// import { faker } from "@faker-js/faker";

import moment from "moment";

const CampaignsLineChart = () => {
  function getCurrentWeekDates(includeToday = true) {
    const today = new Date();
    let dates = [];

    for (let i = 0; i < (includeToday ? 14 : 13); i++) {
      const pastDay = new Date(today.getTime() - i * 24 * 60 * 60 * 1000);
      dates.push(moment(pastDay).format("Do MMM"));
    }
    return dates.reverse();
  }

  const labels = getCurrentWeekDates();
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "Chart.js Line Chart",
      },
    },
    maintainAspectRatio: false,
  };

  const data = {
    labels,
    datasets: [
      {
        label: "",
        data: labels.map(() => Math.floor(Math.random() * (50 - 10 + 1)) + 10),
      },
    ],
  };

  return <Line options={options} data={data} height={400} />;
};

export default CampaignsLineChart;
