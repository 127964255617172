/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */
import React from "react";
import { AuthHeader, SignupForm } from "../features/auth/components";
import { Link } from "react-router-dom";
import { AppToastContainer, Card } from "../features/ui";

const SignupPage = () => {
  return (
    <section className="flex flex-col justify-center items-center min-h-screen">
      <Card>
        <AuthHeader title="Create an Account" />

        <SignupForm />

        <div className=" my-4">
          <Link to="/login" className="text-color-primary text-sm text-center">
            Already have an account? Login.
          </Link>
        </div>
      </Card>
      <AppToastContainer />
    </section>
  );
};

export default SignupPage;
