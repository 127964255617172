/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ClipLoader } from "react-spinners";
import { Button, TextInput } from "../../ui";
import { useUpdateUserBioAsyncMutation } from "../api/auth-api-slice";
import { useGetUserBioAsyncQuery } from "../api/auth-api-slice";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const EditProfileForm = () => {
  const userId = sessionStorage.getItem("id");
  const [userInfo, setUserInfo] = useState({});
  const navigate = useNavigate();
  const [
    updateBioAsyncMutations,
    {
      data: updateBioAsync,
      isSuccess: updateSuccess,
      isLoading: updateLoading,
    },
  ] = useUpdateUserBioAsyncMutation();

  useEffect(() => {
    if (updateSuccess && updateBioAsync) {
      sessionStorage.setItem("profile_image", updateBioAsync.profile_image);
      toast.success("Profile updated successfully", {
        position: toast.POSITION.TOP_CENTER,
      });
      navigate("/account/view-profile");
    }
  }, [updateSuccess, updateBioAsync, navigate]);

  const {
    refetch,
    data: getUserBio,
    isSuccess,
    isError,
    error,
    isLoading,
  } = useGetUserBioAsyncQuery(userId);

  useEffect(() => {
    if (isSuccess && getUserBio) {
      setUserInfo(getUserBio);
    }
  }, [getUserBio, isSuccess]);

  useEffect(() => {
    if (isError) {
      toast.error(error?.data?.error, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, [isError, error?.data?.error]);

  const initialValues = {
    orgName: userInfo.organization || "",
    fullName: userInfo.full_name || "",
    email: userInfo.email || "",
    mobileNumber: userInfo.phone_number || "",
  };

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (values.file) {
        const formData = new FormData();
        formData.append("full_name", values.fullName);
        formData.append("organization", values.orgName);
        formData.append("email", values.email);
        formData.append("phone_number", values.mobileNumber);
        formData.append("picture", values.file);

        const response = await updateBioAsyncMutations({ data: formData });
        if (!response.error) resetForm();
        refetch();
      }

      if (!values.file) {
        const formData = {
          organization: values.orgName,
          full_name: values.fullName,
          email: values.email,
          phone_number: values.mobileNumber,
          has_profile: false,
        };
        const response = updateBioAsyncMutations({ data: formData });
        if (!response.error) resetForm();
      }
    } catch (error) {}
  };

  const validationSchema = Yup.object({
    orgName: Yup.string().required("Organization name cannot be blank"),
    fullName: Yup.string().required("Full name cannot be blank"),
    email: Yup.string()
      .email()
      .required("Email cannot be blank"),
    mobileNumber: Yup.string().required("Mobile number cannot be blank"),
  });

  return (
    <section>
      <Formik
        initialValues={initialValues}
        onSubmit={(values, helpers) => handleSubmit(values, helpers)}
        validationSchema={validationSchema}
        enableReinitialize
      >
        {({ setFieldValue }) => (
          <Form className="space-y-4">
            <div>
              <TextInput
                label="Organization Name"
                name="orgName"
                id="orgName"
              />
            </div>
            <div>
              <TextInput label="Full Name" name="fullName" id="fullName" />
            </div>
            <div>
              <TextInput label="Official Email" name="email" id="email" />
            </div>
            <div>
              <TextInput
                label="Mobile Number"
                name="mobileNumber"
                id="mobileNumber"
              />
            </div>
            {/* <div>
              <div className="md:flex md:justify-between md:items-center">
                <div>
                  <ImageUpload
                    onImageUpload={(file) => setFieldValue("file", file)}
                  />
                </div>
              </div>
            </div> */}
            <div className="flex justify-end">
              <Button
                type="submit"
                label="Save Changes"
                otherStyles="bg-accent-gradient text-light-900"
                disabled={updateLoading}
              >
                {updateLoading && <ClipLoader color="#fff" size={20} />}
                Save Changes
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </section>
  );
};

export default EditProfileForm;
