/* eslint-disable max-len */
/* eslint-disable object-curly-newline */
/* eslint-disable operator-linebreak */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable arrow-body-style */
/* eslint-disable react/function-component-definition */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { ToastContainer, toast } from "react-toastify";

import SignupFormUI from "./signup-form-ui";
import {
  useCreateNewAccountMutation,
  useGenerateOTPMutation,
} from "../api/auth-api-slice";

const SignupForm = () => {
  const [
    createNewAccount,
    { data: createNewAccountMutation, isSuccess, isLoading, error, isError },
  ] = useCreateNewAccountMutation();
  const [generateOTPMutation] = useGenerateOTPMutation();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const initialValues = {
    organizationName: "",
    username: "",
    organizationEmail: "",
    phoneNumber: "",
    password: "",
    confirmPassword: "",
    acceptTerms: "",
  };
  const validationSchema = Yup.object({
    organizationName: Yup.string().required(
      "Organization name cannot be blank"
    ),
    username: Yup.string().required("Username cannot be empty"),
    organizationEmail: Yup.string()
      .email()
      .required("Email is required"),
    phoneNumber: Yup.string().required("Phone number is requried"),
    password: Yup.string().required("Password is required"),
    confirmPassword: Yup.string()
      .label("Confirm Password")
      .required()
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
    acceptTerms: Yup.boolean().required("Please accept our terms & conditions"),
  });

  useEffect(() => {
    if (
      isSuccess &&
      createNewAccountMutation &&
      createNewAccountMutation.response
    ) {
      generateOTPMutation({ userId: createNewAccountMutation.response.id });
      sessionStorage.setItem(
        "phone",
        createNewAccountMutation.response.phone_number
      );
      sessionStorage.setItem("id", createNewAccountMutation.response.id);
      navigate("/phone-number-verification");
    }
  }, [isSuccess, generateOTPMutation, navigate, createNewAccountMutation]);

  // Toasts

  useEffect(() => {
    if (isError) {
      toast.error(error?.data?.error, {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, [isError, error?.data?.error]);

  const handleSubmit = (values) => {
    values.organizationEmail = values.organizationEmail.toLowerCase();
    dispatch(
      createNewAccount({
        full_name: values.username,
        password: values.password,
        email: values.organizationEmail,
        organization: values.organizationName,
        phone_number: values.phoneNumber,
      })
    );
  };

  return (
    <SignupFormUI
      initialValues={initialValues}
      validationSchema={validationSchema}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
    />
  );
};

export default SignupForm;
