/* eslint-disable react/button-has-type */
/* eslint-disable react/function-component-definition */
import React from 'react';
import PropTypes from 'prop-types';

const CardWithHeader = ({ children, title, otherStyles }) => (
  <div className={`bg-white my-2 shadow-md rounded-lg ${otherStyles}`}>
    <div className="px-2 bg-slate-100 py-2 border-b-2">
      <h5 className="text-color-primary font-medium">{title}</h5>
    </div>
    {children}
  </div>
);

CardWithHeader.defaultProps = {
  otherStyles: '',
};

CardWithHeader.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  otherStyles: PropTypes.string,
};

export default CardWithHeader;
