/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable react/function-component-definition */
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { useGetSingleMessageTemplateContentAsyncMutation } from "../messaging-slice";
import SingleMessageTemplateUI from "./single-message-template-ui";
import { AppToast } from "../../ui";

const SingleMessageTemplate = () => {
  const userId = sessionStorage.getItem("id");
  const { templateId } = useParams();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const title = queryParams.get("title");

  const [templateData, setTemplateData] = React.useState([]);

  const [
    getSingleTemplateContent,
    {
      isLoading: contentLoading,
      isSuccess: contentSuccess,
      isError: isContentError,
      error: contentError,
      data: contentData,
    },
  ] = useGetSingleMessageTemplateContentAsyncMutation();

  React.useEffect(() => {
    getSingleTemplateContent({
      user_id: userId,
      message_template_id: templateId,
    });
  }, [getSingleTemplateContent, templateId, userId]);

  React.useEffect(() => {
    if (contentSuccess && contentData) {
      setTemplateData(contentData);
    }
  }, [contentData, contentSuccess]);

  return (
    <React.Fragment>
      <SingleMessageTemplateUI
        templateData={templateData}
        title={title}
        contentLoading={contentLoading}
      />
      <AppToast
        isError={isContentError}
        errorMessage={contentError?.data?.error}
      />
    </React.Fragment>
  );
};

export default SingleMessageTemplate;
