/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React, { useEffect, useRef, useState } from "react";
import { ClipLoader } from "react-spinners";
import { Card, CustomTable, EmptyResource, ErrorDisplay } from "../ui";
import { useGetAllProjectsByUserIdAsyncQuery } from "./project-slice";
import ActionButtons from "./action-buttons";
import { transformTableData } from "./utils";
import DeleteModal from "./delete-modal";
import ProjectDetailsModal from "./project-details-modal";

const TabularDisplayOfProjects = () => {
  const ref = useRef();
  const projectDetailsRef = useRef();
  const [projectDetails, setProjectDetails] = React.useState({});

  const userId = sessionStorage.getItem('id');
  const [projects, setProjects] = useState([]);

  const {
    data,
    isLoading,
    isError,
    error,
    isSuccess,
  } = useGetAllProjectsByUserIdAsyncQuery(userId);

  useEffect(() => {
    if (isSuccess && data) {
      setProjects(data);
    }
  }, [data, isSuccess]);

  const handleDelete = (id) => {
    if (typeof window !== "undefined") {
      sessionStorage.setItem("prjId", id);
    }
    ref.current.showModal();
    document.body.style.overflow = "hidden";
  };

  const handleShowProjectModal = (id) => {
    const projectItems = projects.filter((item) => item.id === id);
    setProjectDetails(projectItems[0]);
    projectDetailsRef.current.showModal();
    document.body.style.overflow = "hidden";
  };

  const transformedData = transformTableData(projects);

  const PROJECT_TABLE_COLUMNS = [
    {
      Header: "#",
      accessor: "serial",
    },
    {
      Header: "Project Title",
      accessor: "title",
    },
    {
      Header: "Date Created",
      accessor: "date_created",
    },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <ActionButtons
          row={row}
          handleDelete={handleDelete}
          handleShowProjectModal={handleShowProjectModal}
        />
      ),
    },
  ];

  return (
    <section>
      <div className="my-4">{isLoading && <ClipLoader color="#94a3b8" />}</div>

      {isSuccess && (
        <Card>
          <CustomTable data={transformedData} columns={PROJECT_TABLE_COLUMNS} />

          {projects.length === 0 && (
            <EmptyResource message="You do not have any projects." />
          )}
        </Card>
      )}

      <DeleteModal modalRef={ref} />
      <ProjectDetailsModal
        modalRef={projectDetailsRef}
        projectDetails={projectDetails}
      />

      {isError && <ErrorDisplay />}
    </section>
  );
};

export default TabularDisplayOfProjects;
