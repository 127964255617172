import moment from "moment";
import { BsSoundwave } from "react-icons/bs";
import { FaEnvelope } from "react-icons/fa";

const transformTableData = (data) => {
  if (!Array.isArray(data) || data.length === 0) {
    return [];
  }

  return data.map((templatesItem, index) => ({
    serial: index + 1,
    id: templatesItem.id,
    title: templatesItem.title,
    content_type: templatesItem.is_sms ? (
      <FaEnvelope className="text-xl text-slate-500" />
    ) : (
      <BsSoundwave className="text-xl text-slate-500" />
    ),
    date_created: moment(templatesItem.created_at).format("DD-MM-YYYY"),
  }));
};

export default transformTableData;
