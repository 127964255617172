import React from "react";
import { FaChevronRight } from "react-icons/fa";
import { CustomLink, InnerPageHeader, Card } from "../features/ui";
import { TabularDisplayOfProjects } from "../features/projects";

const Projects = () => {
  const pageConfig = {
    title: "Projects",
    subTitle:
      "Projects categorize campaigns into different project-based engagements.",
  };
  return (
    <React.Fragment>
      <Card>
        <section>
          <div className="md:flex md:justify-between md:items-start">
            <div className="md:basis-[60%]">
              <InnerPageHeader
                pageTitle={pageConfig.title}
                pageSubTitle={pageConfig.subTitle}
              />
            </div>
            <CustomLink
              label="New Project"
              reference="/content-library/add-project"
              icon={<FaChevronRight />}
            />
          </div>
          <TabularDisplayOfProjects />
        </section>
      </Card>
    </React.Fragment>
  );
};

export default Projects;
