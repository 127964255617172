/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React from "react";
import { InnerPageHeader } from "../features/ui";
import { DisplayGeneratedDeliveryReport } from "../features/campaigns";

const DeliveryReport = () => {
  const pageConfig = {
    title: "Generate Delivery Reports",
    subTitle: "Generate delivery reports from Campaigns sent to beneficiaries.",
  };

  return (
    <React.Fragment>
      <InnerPageHeader
        pageTitle={pageConfig.title}
        pageSubTitle={pageConfig.subTitle}
      />
      <DisplayGeneratedDeliveryReport />
    </React.Fragment>
  );
};

export default DeliveryReport;
