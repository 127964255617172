/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React from "react";
import { Card, InnerPageHeader } from "../features/ui";
import { AddProjectForm } from "../features/projects";

const AddNewProject = () => {
  const pageConfig = {
    title: "Create New Project",
    subTitle: "",
  };

  return (
    <React.Fragment>
      <Card>
        <section className="">
          <InnerPageHeader
            pageTitle={pageConfig.title}
            pageSubTitle={pageConfig.subTitle}
          />
          <AddProjectForm />
        </section>
      </Card>
    </React.Fragment>
  );
};

export default AddNewProject;
