import React from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import { useTable, usePagination, useGlobalFilter } from "react-table";

const CustomTable = ({ data, columns }) => {
  const {
    getTableProps,
    getTableBodyProps,
    prepareRow,
    setPageSize,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    state,
    setGlobalFilter,
    gotoPage,
    // pageCount,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useGlobalFilter,
    usePagination
  );

  const { pageIndex, globalFilter } = state;

  return (
    <div className="">
      <div className="flex justify-between items-center">
        <select
          className="border text-[15px] rounded-md text-slate-800 font-normal p-2 focus:outline-light-700"
          value={state.pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[10, 25, 50, 100].map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </select>
        <div>
          <input
            className="border border-light-700 rounded-md text-[15px] text-slate-800
            font-normal p-2 focus:border-light-900 focus:outline-light-700"
            type="search"
            value={globalFilter || ""}
            onChange={(e) => setGlobalFilter(e.target.value)}
            placeholder="Search here..."
          />
        </div>
      </div>

      <table
        className="table-auto border-separate border-spacing-0 border w-full rounded-md my-4"
        {...getTableProps()}
      >
        <thead className=" bg-light-900 text-sm text-light-400">
          {headerGroups.map((headerGroup) => (
            <tr className="text-left" {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th className="p-2" {...column.getHeaderProps()}>
                  {column.render("Header")}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="text-sm font-normal" {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr
                className="text-slate-500 odd:bg-slate-100"
                {...row.getRowProps()}
                key={row.id}
              >
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} className="p-2">
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="text-light-400 flex justify-end mt-8">
        <span>
          Page{" "}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Go to page:{" "}
          <input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const pageNumber = e.target.value
                ? Number(e.target.value) - 1
                : 0;
              gotoPage(pageNumber);
            }}
            style={{ width: "50px" }}
          />
        </span>
        <button
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
          className="hover:cursor-pointer mr-4"
        >
          <FaChevronLeft />
        </button>
        <button
          onClick={() => nextPage()}
          disabled={!canNextPage}
          className="hover:cursor-pointer"
        >
          <FaChevronRight />
        </button>
      </div>
    </div>
  );
};

export default CustomTable;
