/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { FaBars, FaChevronDown, FaTimes } from "react-icons/fa";
import Cookies from "js-cookie";
import logo from "../../../assets/images/kpododo-logo.png";
import AccountSignout from "./account-signout";
import { useGetUserBioAsyncQuery } from "../../auth/api/auth-api-slice";
import Button from "../button";

const Topnav = ({ otherStyles, handleSliderToggle }) => {
  const [drawerNavOpened, setDrawerNavOpened] = useState(false);
  const [showAccountSignout, setShowAccountSignout] = useState(false);

  const { isSuccess, data: userBio } = useGetUserBioAsyncQuery(
    Cookies.get("id")
  );
  useEffect(() => {
    // Store username and profile picture (if profile picture exists) in sessionStorage
    if (isSuccess && userBio) {
      sessionStorage.setItem("username", JSON.stringify(userBio.full_name));
      sessionStorage.setItem("orgname", JSON.stringify(userBio.organization));
      sessionStorage.setItem(
        "accountPhone",
        JSON.stringify(userBio.phone_number)
      );
    }
  }, [isSuccess, userBio]);

  const handleClick = () => {
    handleSliderToggle();
    setDrawerNavOpened((prevState) => !prevState);
  };

  const handleShowAccount = () => {
    setShowAccountSignout((prevState) => !prevState);
  };

  const handleShowPurchaseTokensModal = () => {};

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest(".button-wrappers")) {
        setShowAccountSignout(false);
      }
    };
    document.body.addEventListener("click", handleClickOutside);
    return () => document.body.removeEventListener("click", handleClickOutside);
  }, []);

  return (
    <nav className="w-full px-[16px]  bg-light-900 py-2 shadow-md fixed top-0 left-0 z-10">
      <div className="flex flex-1 items-center justify-between">
        <div className="lg:ml-[45px] flex items-center justify-center">
          <img src={logo} alt="Kpododo company logo" className="h-14" />
        </div>

        <div className="">
          <div className="flex items-center">
            <div className="hidden md:flex">
              <Button
                otherStyles="bg-accent-gradient text-light-900"
                handleBtnClick={handleShowPurchaseTokensModal}
              >
                Purchase Tokens
              </Button>
            </div>
            <div className="ml-4 button-wrappers">
              <Button
                otherStyles={`text-light-500 bg-light-700 hover:bg-light-800 ${
                  showAccountSignout ? "bg-light-800" : ""
                }`}
                handleBtnClick={handleShowAccount}
              >
                {sessionStorage.getItem("organization_name")} <FaChevronDown />
              </Button>
              <AccountSignout
                otherStyles={`${showAccountSignout ? "scale-full" : "scale-0"}`}
              />
            </div>
            <div className="lg:hidden ml-4">
              <button type="button" onClick={handleClick}>
                {!drawerNavOpened ? (
                  <FaBars className="lg:hidden text-color-primary text-4xl" />
                ) : (
                  <FaTimes className="lg:hidden text-color-primary text-4xl" />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

Topnav.propTypes = {
  otherStyles: PropTypes.string.isRequired,
  handleSliderToggle: PropTypes.func.isRequired,
};

export default Topnav;
