/* eslint-disable react/button-has-type */
/* eslint-disable react/function-component-definition */
import React from "react";
import PropTypes from "prop-types";
import { Button, ModalWrapper } from "../ui";
import { useDeleteSenderByUserIdAsyncMutation } from "./sender-slice";
import { ClipLoader } from "react-spinners";

const DeleteModal = ({ modalRef }) => {
  // const userId = sessionStorage.getItem('id');

  const [
    // deleteSenderAsync,
    { isLoading: isDeleting, isSuccess },
  ] = useDeleteSenderByUserIdAsyncMutation();

  React.useEffect(() => {
    if (isSuccess) {
      if (typeof window !== "undefined") {
        sessionStorage.removeItem("senderId");
      }
      modalRef.current.close();
    }
  }, [isSuccess, modalRef]);

  const handleDelete = (e) => {
    // let senderId = null;
    if (typeof window !== "undefined") {
      // senderId = sessionStorage.getItem("senderId");
    }
    // deleteSenderAsync({});
  };

  const handleDialogClose = () => {
    if (typeof window !== "undefined") {
      sessionStorage.removeItem("senderId");
    }
    modalRef.current.close();
    document.body.style.overflow = "unset";
  };

  return (
    <ModalWrapper modalRef={modalRef}>
      <section className="space-y-4 flex flex-col justify-center items-center">
        <div className="dialog-header text-center">
          <h4 className="tex-md text-slate-600 font-bold">Delete Sender ID</h4>
          <p className="text-slate-400 text-sm">
            You are about to delete a Sender ID. This means you would not be
            able to send campaigns using
          </p>
          <p className="text-slate-400 my-2 text-sm">
            Are you sure of this action?
          </p>
        </div>
        <div className="dialog-footer flex items-start space-x-4">
          <Button
            type="button"
            otherStyles="bg-slate-200 text-zinc-800 hover:bg-slate-300"
            handleBtnClick={handleDialogClose}
          >
            No, Keep it
          </Button>
          <Button
            type="button"
            otherStyles="bg-red-400 hover:bg-red-600"
            handleBtnClick={handleDelete}
          >
            Yes, Delete it!
            {isDeleting && <ClipLoader color="#fff" size={20} />}
          </Button>
        </div>
      </section>
    </ModalWrapper>
  );
};

DeleteModal.propTypes = {
  modalRef: PropTypes.instanceOf(Object).isRequired,
};

export default DeleteModal;
