import React from "react";
import { SelectField } from "../../ui";

const ResourceSelectField = ({ label, name, id, resource }) => {
  return (
    <SelectField label={label} name={name} id={id}>
      <option value="">Choose a project</option>
      {resource &&
        resource.map((project, index) => (
          <option key={index} value={project.id}>
            {project.name}
          </option>
        ))}
    </SelectField>
  );
};

export default ResourceSelectField;
