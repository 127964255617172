import React, { useEffect, useState } from "react";
import { ClipLoader } from "react-spinners";
import { CustomTable, EmptyResource, ErrorDisplay } from "../ui";
import ActionButtons from "./action-buttons";
import { useGetAllUserSubscribersAsyncQuery } from "./subscription-slice";
import DeleteModal from "./delete-modal";
import { transformTableData } from "./utils";
import SubscriberDetailsModal from "./subscriber-details-modal";

const SubscribersTable = () => {
  const userId = sessionStorage.getItem("id");

  const deleteModalRef = React.useRef();
  const subscriberDetailsModalRef = React.useRef();

  const [subs, setSubs] = useState([]);
  const [subscriberDetails, setSubscriberDetails] = React.useState({});

  const {
    data: getAllUserSubscribersAsync,
    isLoading: getAllUserSubscribersLoading,
    isError: isGetAllUserSubscribersError,
    isSuccess,
  } = useGetAllUserSubscribersAsyncQuery(userId);

  useEffect(() => {
    if (isSuccess && getAllUserSubscribersAsync) {
      setSubs(getAllUserSubscribersAsync);
    }
  }, [getAllUserSubscribersAsync, isSuccess]);

  const transformedSubscribersData = transformTableData(subs);

  const handleDeleteSubscriber = (id) => {
    if (typeof window !== "undefined") {
      sessionStorage.setItem("subId", id);
    }
    deleteModalRef.current.showModal();
    document.body.style.overflow = "hidden";
  };

  const handleShowSubscriberModal = (id) => {
    const subscribers = subs.filter((sub) => sub.id === id);
    setSubscriberDetails(subscribers[0]);
    subscriberDetailsModalRef.current.showModal();
    document.body.style.overflow = "hidden";
  };

  const columns = [
    {
      Header: "S/N",
      accessor: "serial",
    },
    {
      Header: "Fullname",
      accessor: "fullname",
    },
    {
      Header: "Phone No.",
      accessor: "phoneNumber",
    },
    {
      Header: "Gender",
      accessor: "gender",
    },
    {
      Header: "District",
      accessor: "district",
    },
    {
      Header: "Region",
      accessor: "region",
    },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <ActionButtons
          row={row}
          handleDelete={handleDeleteSubscriber}
          handleShowSubscriberModal={handleShowSubscriberModal}
        />
      ),
    },
  ];

  return (
    <React.Fragment>
      <section>
        <div className="my-4">
          {getAllUserSubscribersLoading && <ClipLoader color="#94a3b8" />}
        </div>

        {isSuccess ? (
          subs.length > 0 ? (
            <CustomTable data={transformedSubscribersData} columns={columns} />
          ) : (
            <EmptyResource message="You do not have any subscribers." />
          )
        ) : null}

        {isGetAllUserSubscribersError && <ErrorDisplay />}
      </section>
      <DeleteModal modalRef={deleteModalRef} />
      <SubscriberDetailsModal
        modalRef={subscriberDetailsModalRef}
        subscriberDetails={subscriberDetails}
      />
    </React.Fragment>
  );
};

export default SubscribersTable;
