/* eslint-disable react/button-has-type */
/* eslint-disable react/function-component-definition */
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { FcCustomerSupport, FcStart, FcViewDetails } from 'react-icons/fc';
import { IconContext } from 'react-icons';
import { CardWithHeader } from '../ui';

const TRAINING_LINKS = [
  {
    label: 'Training for customers',
    ref: '/dashboard',
    icon: <FcCustomerSupport />,
  },
  {
    label: 'Documentation',
    ref: '/dashboard',
    icon: <FcViewDetails />,
  },
  {
    label: 'Video Guides',
    ref: '/dashboard',
    icon: <FcStart />,
  },
];

const TrainingAndSupport = () => {
  const iconConfig = useMemo(() => ({ size: '28px', className: 'mr-2' }), []);
  return (
    <CardWithHeader title="Training & Support" otherStyles="h-full">
      <div className="p-2 md:py-8">
        <ul className="space-y-8">
          {
        TRAINING_LINKS.map((link) => (
          <li
            key={link.label}
            className="flex items-center text-color-primary hover:underline hover:decoration-2"
          >
            <IconContext.Provider
              value={iconConfig}
            >
              {link.icon}
            </IconContext.Provider>
            <Link href="/dashboard" className="text-sm">{link.label}</Link>
          </li>
        ))
      }
        </ul>
      </div>
    </CardWithHeader>
  );
};

export default TrainingAndSupport;
