const transformTableData = (data) => {
  if (!Array.isArray(data) || data.length === 0) {
    return [];
  }

  return data.map((subscriber, index) => ({
    serial: index + 1,
    id: subscriber.id,
    fullname: subscriber.full_name,
    phoneNumber: subscriber.phone_number,
    gender: subscriber.gender,
    region: subscriber.region,
    district: subscriber.district,
  }));
};

export default transformTableData;
