/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React, { useRef } from 'react';
import { BsPip } from 'react-icons/bs';
import { Button } from '../ui';
import TopupAccount from './topup-account-modal';
import TabularDisplayOfCredits from './tabular-display-of-credits';

const CreditBillingInfo = () => {
  const ref = useRef();

  const handleTopupClick = () => {
    ref.current.showModal();
    document.body.style.overflow = 'hidden';
  };

  return (
    <section>
      <div className="mb-8">
        <span className="flex items-center my-2">
          <BsPip className="mr-2 text-3xl text-color-primary" />
          <p className="text-gray-500">Available Credit: GHC 2.00</p>
        </span>
        <div className="my-4 md:w-full lg:w-[45%] xl:w-[25%]">
          <Button
            type="button"
            label="Topup Account"
            handleBtnClick={handleTopupClick}
          />
          <TopupAccount
            modalRef={ref}
          />
        </div>
        <p>
          Or reach us via:
          <span className="text-color-primary font-medium hover:underline hover:decoration-2">
            <a href="mailto://info@kpododo.com" className="ml-2">info@kpododo.com</a>
          </span>
        </p>
      </div>
      <TabularDisplayOfCredits />
    </section>
  );
};

export default CreditBillingInfo;
