/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { useUpdatePhoneNumberMutation } from "../api/auth-api-slice";
import UpdatePhoneNumberFormUI from "./update-phone-number-form-ui";
import { toast } from "react-toastify";

const UpdatePhoneNumber = () => {
  const navigate = useNavigate();

  const [
    updatePhone,
    { isLoading, isSuccess, isError, error },
  ] = useUpdatePhoneNumberMutation();

  const initialValues = {
    userPhone: "",
  };

  const validationSchema = Yup.object({
    userPhone: Yup.string().required("Phone number cannot be empty."),
  });

  // Redirect user to OTP screen
  useEffect(() => {
    if (isSuccess) {
      navigate("/phone-number-verification", { replace: true });
    }
  }, [navigate, isSuccess]);

  const handleSubmit = (values) => {
    const { userPhone } = values;
    let userId = null;
    if (typeof window !== "undefined") {
      userId = sessionStorage.getItem("id");
    }
    updatePhone({ userPhone, userId });
  };

  // Toasts
  useEffect(() => {
    if (isSuccess) {
      toast.success("OTP verification code sent", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      toast.error(
        error?.data?.error || "Sorry, an error occured. Please try again",
        {
          position: toast.POSITION.TOP_CENTER,
        }
      );
    }
  }, [isError, error?.data?.error]);

  return (
    <UpdatePhoneNumberFormUI
      initialValues={initialValues}
      validationSchema={validationSchema}
      handleSubmit={handleSubmit}
      isLoading={isLoading}
    />
  );
};

export default UpdatePhoneNumber;
