import React from 'react';
import PropTypes from 'prop-types';
import logo from '../../../assets';

function AuthHeader({ title, otherStyles }) {
  return (
    <div className={`text-center flex flex-col items-center mb-10 space-y-5 ${otherStyles}`}>
      <img
        src={logo}
        alt="Logo of the Kpododo company"
        className="h-16"
      />
      <h1 className="text-center text-2xl font-bold text-slate-700">{title}</h1>
    </div>
  );
}

AuthHeader.defaultProps = {
  otherStyles: '',
};

AuthHeader.propTypes = {
  title: PropTypes.string.isRequired,
  otherStyles: PropTypes.string,
};

export default AuthHeader;
