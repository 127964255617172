import React from "react";
import { AuthHeader, ForgotPasswordForm } from "../features/auth/components";
import { Link } from "react-router-dom";
import { AppToastContainer, Card } from "../features/ui";

function ForgotPasswordPage() {
  return (
    <section className="flex flex-col justify-center items-center min-h-screen">
      <Card>
        <AuthHeader title="Reset Your Password" />

        <ForgotPasswordForm />

        <p className="text-center text-slate-600 font-medium mt-4 text-sm">
          Don&apos;t have an account yet?
          <Link to="/signup" className="inline-block text-color-primary ml-2">
            Register for an account.
          </Link>
        </p>
      </Card>
      <AppToastContainer />
    </section>
  );
}

export default ForgotPasswordPage;
