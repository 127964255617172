/* eslint-disable max-len */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { ClipLoader } from "react-spinners";
import { AppToast, Button, TextArea, TextInput } from "../ui";
import { useCreateSenderAsyncMutation } from "./sender-slice";

const AddSenderIdForm = () => {
  const userId = sessionStorage.getItem("id");

  const [
    createSenderAsync,
    { isLoading, isSuccess, isError, error },
  ] = useCreateSenderAsyncMutation();

  const initialValues = {
    senderId: "",
    descriptionOfId: "",
  };

  const validationSchema = Yup.object({
    senderId: Yup.string().required("Sender ID cannot be blank"),
    descriptionOfId: Yup.string().required("Description cannot be blank"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    try {
      const response = await createSenderAsync({
        userId,
        name: values.senderId,
        description: values.descriptionOfId,
      });

      if (!response.error) resetForm();
    } catch (error) {}
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, helpers) => handleSubmit(values, helpers)}
      >
        <Form className="space-y-4">
          <div className="space-y-4">
            <div>
              <TextInput
                label="Sender ID"
                id="senderId"
                name="senderId"
                placeholder="Enter name your sender ID. Eg. Kpododo Consult"
              />
              <p className="text-slate-500">
                <span className="font-bold mr-2">Note:</span>
                Your sender ID must be 11 chars or less
              </p>
            </div>
            <div>
              <TextArea
                label="Description"
                name="descriptionOfId"
                id="descriptionOfId"
                placeholder="Enter a description. Eg. For messaging pregnant mothers about ANC"
              />
            </div>
          </div>
          <div className="md:flex md:justify-end md:items-center">
            <Button
              type="submit"
              label="Submit ID Request"
              disabled={isLoading}
              otherStyles="bg-accent-gradient text-light-900"
            >
              {isLoading && <ClipLoader color="#fff" size={20} />}
              Create Sender
            </Button>
          </div>
        </Form>
      </Formik>
      <AppToast
        isSuccess={isSuccess}
        successMessage={"Message sender created successfully"}
        isError={isError}
        errorMessage={error?.error.data}
      />
    </React.Fragment>
  );
};

export default AddSenderIdForm;
