import React from "react";
import { FaChevronRight } from "react-icons/fa";
import { CustomLink, InnerPageHeader, Card } from "../features/ui";
import { TabularDisplayOfSenders } from "../features/senders";

const Senders = () => {
  const pageConfig = {
    title: "Manage Sender IDs",
    subTitle: "Sender IDs are custom branding titles for your SMS camapigns.",
  };

  return (
    <React.Fragment>
      <Card>
        <section>
          <div className="md:flex md:justify-between md:items-start">
            <div className="md:basis-[60%]">
              <InnerPageHeader
                pageTitle={pageConfig.title}
                pageSubTitle={pageConfig.subTitle}
              />
            </div>

            <CustomLink
              label="New Sender ID"
              reference="/system-setup/new-sender-id"
              icon={<FaChevronRight />}
            />
          </div>
          <TabularDisplayOfSenders />
        </section>
      </Card>
    </React.Fragment>
  );
};

export default Senders;
