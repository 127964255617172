import React from "react";
import { Formik, Form } from "formik";
import { ClipLoader } from "react-spinners";
import * as Yup from "yup";
import { Button, TextInput } from "../../ui";

const OTPForm = ({ onSubmit, initialValues, loading }) => {
  const validationSchema = Yup.object({
    secret: Yup.string().required("Please enter the code sent you."),
  });

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      <Form className="space-y-6">
        <div>
          <TextInput
            type="text"
            label="Enter code here"
            name="secret"
            id="secret"
            placeholder="xxxxxx"
          />
        </div>
        <Button
          type="submit"
          disabled={loading}
          otherStyles="bg-accent-gradient text-light-900"
        >
          {loading && <ClipLoader color="#fff" size={20} />}
          Verify
        </Button>
      </Form>
    </Formik>
  );
};

export default OTPForm;
