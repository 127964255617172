import React from "react";
import { AuthHeader, ResetPasswordForm } from "../features/auth/components";
import { AppToastContainer, Card } from "../features/ui";

function ResetPasswordPage() {
  return (
    <section className="flex justify-center items-center min-h-screen">
      <Card className="bg-white rounded drop-shadow-lg p-8">
        <AuthHeader title="Reset your password" />

        <ResetPasswordForm />
      </Card>
      <AppToastContainer />
    </section>
  );
}

export default ResetPasswordPage;
