/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React from "react";
import { Card, InnerPageHeader } from "../features/ui";
import { UpdatePasswordForm } from "../features/auth/components";

const UpdatePassword = () => {
  const pageConfig = {
    title: "Update Password",
    subTitle: "Update account password here.",
  };

  return (
    <React.Fragment>
      <Card>
        <InnerPageHeader
          pageTitle={pageConfig.title}
          pageSubTitle={pageConfig.subTitle}
        />
        <UpdatePasswordForm />
      </Card>
    </React.Fragment>
  );
};

export default UpdatePassword;
