import moment from "moment";
import React from "react";

const Footer = () => {
  return (
    <footer className=" bg-light-900 shadow-sm font-light text-light-400 text-[13px] text-center py-5 w-full fixed bottom-0">
      © {moment().year()} kpododo Messaging
    </footer>
  );
};

export default Footer;
