/* eslint-disable react/button-has-type */
/* eslint-disable react/function-component-definition */
import React from "react";
import PropTypes from "prop-types";
import { ModalWrapper } from "../ui";
import AddSenderIdForm from "./create-new-sender-id-form";
import { IoCloseCircleSharp } from "react-icons/io5";

const CreateSenderIdModal = ({ modalRef }) => {
  const handleDialogClose = () => {
    modalRef.current.close();
    document.body.style.overflow = "unset";
  };

  return (
    <ModalWrapper modalRef={modalRef}>
      <div className="flex justify-end">
        <button type="button" onClick={handleDialogClose}>
          <IoCloseCircleSharp className="text-4xl text-rose-400" />
        </button>
      </div>
      <AddSenderIdForm />
    </ModalWrapper>
  );
};

CreateSenderIdModal.propTypes = {
  modalRef: PropTypes.instanceOf(Object).isRequired,
};

export default CreateSenderIdModal;
