/* eslint-disable react/button-has-type */
/* eslint-disable react/function-component-definition */
import React from "react";
import PropTypes from "prop-types";
import { Button, ModalWrapper } from "../ui";
import { FaCalendar } from "react-icons/fa";
import moment from "moment";

const SenderIdDetailsModal = ({ modalRef, senderIdDetails }) => {
  const handleDialogClose = () => {
    modalRef.current.close();
    document.body.style.overflow = "unset";
  };

  return (
    <ModalWrapper modalRef={modalRef}>
      <section className="">
        <div className="mb-4">
          <h1 className="text-xl font-semibold mb-2 text-slate-500">
            {senderIdDetails?.name}
          </h1>

          <p className="text-slate-500 font-light text-[14px]">
            {senderIdDetails?.description}
          </p>
        </div>

        <div className="flex items-center text-slate-500 font-light text-[14px]">
          <span className="inline-block">
            <FaCalendar className="text-state-400 mr-2" />
          </span>
          <span className="inline-block">
            {moment(senderIdDetails?.created_at).format("DD-MM-YYYY")}
          </span>
        </div>

        <div className="dialog-footer flex justify-end items-start space-x-4">
          <Button
            type="button"
            otherStyles="bg-slate-200 text-zinc-800 hover:bg-slate-300"
            handleBtnClick={handleDialogClose}
          >
            Ok, Close
          </Button>
        </div>
      </section>
    </ModalWrapper>
  );
};

SenderIdDetailsModal.propTypes = {
  modalRef: PropTypes.instanceOf(Object).isRequired,
};

export default SenderIdDetailsModal;
