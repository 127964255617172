import { Form, Formik } from "formik";
import React from "react";
import { Button, Checkbox, TextInput } from "../../ui";
import AudioTempUploadTable from "./audio-template-upload-table";
import { ClipLoader } from "react-spinners";
import SmsTempUploadTable from "./sms-template-upload-table";

const CreateNewMessageUI = ({
  initialValues,
  validationSchema,
  handleSubmit,
  languageTemplate,
  messageType,
  handleMessageType,
  isLoading,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, helpers) => handleSubmit(values, helpers)}
    >
      <Form className="space-y-6 scale-95">
        <div className="space-y-6 md:grid md:grid-cols-2 md:items-baseline md:gap-4">
          <div>
            <TextInput
              label="Message Title"
              name="messageTitle"
              id="messageTitle"
              type="text"
              placeholder="Please provide your message title."
            />
          </div>
        </div>
        <div className="space-y-6 md:grid md:grid-cols-2 md:items-baseline md:gap-4"></div>
        <div className="space-y-6 md:grid md:grid-cols-1 md:items-baseline md:gap-4">
          <div className="space-y-4">
            <div>
              <div>
                <Checkbox
                  type="radio"
                  name="messageType"
                  id="voiceType"
                  value="voiceType"
                  onChange={handleMessageType}
                >
                  Voice Message
                </Checkbox>
              </div>
              {messageType === "voiceType" && (
                <div>
                  <div className="px-2 py-4 rounded-lg">
                    <AudioTempUploadTable data={languageTemplate} />
                  </div>
                </div>
              )}
            </div>
            <div>
              <Checkbox
                type="radio"
                name="messageType"
                id="smsType"
                value="smsType"
                onChange={handleMessageType}
              >
                SMS Message
              </Checkbox>
            </div>
            {messageType === "smsType" && (
              <div>
                <div className="px-2 py-4 rounded-lg">
                  <SmsTempUploadTable data={languageTemplate} />
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="md:flex md:justify-end">
          <Button
            type="submit"
            label="Save Message"
            otherStyles="bg-accent-gradient text-light-900"
          >
            {isLoading && <ClipLoader color="#fff" size={20} />}
            Save Template
          </Button>
        </div>
      </Form>
    </Formik>
  );
};

export default CreateNewMessageUI;
