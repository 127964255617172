/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React from "react";
import { FaChevronRight } from "react-icons/fa";
import { NavLink, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import Cookies from "js-cookie";

const AccountSignout = ({ otherStyles }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.clear();
    Cookies.remove("user_session");
    navigate("/login", { replace: true });
  };

  const ACCOUNT_MAP = [
    {
      label: "Account Information",
      src: "/account/view-profile",
    },
    {
      label: "Update Account Information",
      src: "/account/edit-profile",
    },
    {
      label: "Change Password",
      src: "/account/update-password",
    },
  ];

  return (
    <div
      className={`bg-white rounded-sm shadow-lg lg:w-[20%] absolute right-4 top-[75px] transition ease-in-out duration-3000 ${otherStyles}`}
    >
      <h5>{sessionStorage.getItem("orgname")}</h5>
      <ul className="text-[12px]">
        {ACCOUNT_MAP.map(({ label, src }) => (
          <li
            id={label}
            className="border-b border-light-800 p-2 text-dark-500 hover:text-light-500 hover:cursor-pointer hover:bg-light-800"
          >
            <NavLink to={src} className="flex justify-between">
              {label}
              <FaChevronRight />
            </NavLink>
          </li>
        ))}
        <li className="m-2">
          <button
            className="bg-light-400 hover:bg-light-500 text-light-900 rounded-sm text-[11px] p-2 transition-all ease-in duration-300"
            onClick={handleLogout}
          >
            Logout
          </button>
        </li>
      </ul>
    </div>
  );
};

AccountSignout.propTypes = {
  otherStyles: PropTypes.string.isRequired,
};

export default AccountSignout;
