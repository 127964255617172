import { apiSlice } from "../../api/api";
const userId = sessionStorage.getItem('id');

export const languageApi = apiSlice.injectEndpoints({
  reducerPath: "languageApi",
  endpoints: (builder) => ({
    createLanguageAsync: builder.mutation({
      query: (userInput) => {
        return {
          url: `/message-template/language/${userId}`,
          method: "POST",
          body: {
            title: userInput.title,
          },
        };
      },
      invalidatesTags: ["languages"],
    }),
    getAllLanguagesByUserIdAsync: builder.query({
      query: (userId) => `/message-template/language/${userId}`,
      providesTags: ["languages"],
      transformResponse: (res) => res.response,
    }),
    getSingleLanguageAsync: builder.query({
      query: ({userId, languageId}) => `/message-template/language/single/${userId}`,
      providesTags: ["languages"],
      transformResponse: (res) => res.response,
    }),
    updateLanguageAsync: builder.mutation({
      query: (userInput, { getState }) => {
        const updatedLanguage = {
          title: userInput.languageName,
        };
        return {
          url: `/message-template/language/${userId}`,
          method: "PUT",
          body: updatedLanguage,
        };
      },
      invalidatesTags: ["languages"],
    }),
    deleteLanguageByUserId: builder.mutation({
      query: (data, { getState }) => {
        const payload = {
          id: data.language_id,
        };
        return {
          url: `/message-template/language/${userId}`,
          method: "DELETE",
          body: payload,
        };
      },
      invalidatesTags: ["languages"],
    }),
  }),
});

export const {
  useCreateLanguageAsyncMutation,
  useGetAllLanguagesByUserIdAsyncQuery,
  useUpdateLanguageAsyncMutation,
  useDeleteLanguageByUserIdMutation,
  useGetSingleLanguageAsyncQuery,
} = languageApi;
