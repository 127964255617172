/* eslint-disable object-curly-newline */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { useGetAllLanguagesByUserIdAsyncQuery } from "../../languages";
import { useCreateNewMessageTemplateAsyncMutation } from "../messaging-slice";
import { toast } from "react-toastify";
import CreateNewMessageUI from "./create-new-message-form-ui";
import { AppToast } from "../../ui";

const CreateNewMessageForm = () => {
  const userId = sessionStorage.getItem('id');

  const [
    createNewMessageTemplateAsync,
    { isLoading, isSuccess, isError, error },
  ] = useCreateNewMessageTemplateAsyncMutation();

  const {
    data: langData,
    isSuccess: langSuccess,
  } = useGetAllLanguagesByUserIdAsyncQuery(userId);

  const [formLanguages, setFormLanguages] = useState([]);
  const [messageType, setMessageType] = useState("");

  useEffect(() => {
    if (langSuccess && langData) {
      setFormLanguages(langData);
    }
  }, [langData, langSuccess]);

  const initialValues = {
    messageTitle: "",
    smsMessages: {},
    audioFiles: {},
  };

  const handleMessageType = (event) => {
    if (event.target.value === "smsType") {
      setMessageType("smsType");
    }

    if (event.target.value === "voiceType") {
      setMessageType("voiceType");
    }
  };

  const validationSchema = Yup.object({
    messageTitle: Yup.string().required("Message title cannot be empty"),
  });

  const handleSubmit = async (values, { resetForm }) => {
    try {
      if (messageType === "voiceType") {
        const formData = new FormData();

        formData.append("title", values.messageTitle);
        Object.keys(values.audioFiles).forEach((languageId, index) => {
          formData.append(
            `content[${index}][file]`,
            values.audioFiles[languageId]
          );
          formData.append(
            `content[${index}][msg_template_lang_id]`,
            languageId
          );
        });

        const response = await createNewMessageTemplateAsync({ formData });
        if (!response.error) {
          resetForm();
        }
      }

      if (messageType === "smsType") {
        const formData = {
          title: values.messageTitle,
          content: Object.keys(values.smsMessages).map((langId) => ({
            sms_content: values.smsMessages[langId],
            msg_template_lang_id: langId,
          })),
        };

        const response = await createNewMessageTemplateAsync({ formData });
        if (!response.error) {
          resetForm();
        }
      }
    } catch (error) {}
  };

  const languageTemplate = formLanguages.map((item, index) => ({
    id: item.id,
    language: item.title,
    message: null,
  }));

  return (
    <React.Fragment>
      <CreateNewMessageUI
        initialValues={initialValues}
        validationSchema={validationSchema}
        languageTemplate={languageTemplate}
        messageType={messageType}
        isLoading={isLoading}
        handleSubmit={handleSubmit}
        handleMessageType={handleMessageType}
      />
      <AppToast
        isSuccess={isSuccess}
        successMessage="Message template created successfully"
        isError={isError}
        errorMessage={error?.data?.error}
      />
    </React.Fragment>
  );
};

export default CreateNewMessageForm;
