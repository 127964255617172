/* eslint-disable import/newline-after-import */
/* eslint-disable import/order */
/* eslint-disable indent */
/* eslint-disable camelcase */
/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
import { apiSlice } from '../../api/api';
import { toast } from 'react-toastify';
const userId = sessionStorage.getItem("id");

export const campaignApi = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        createNewScheduledMessageAsync: builder.mutation({
            query: (newScheduledMessage) => ({
                url: `/user/message/schedule/${userId}`,
                method: 'POST',
                body: newScheduledMessage.userInput,
            }),
            invalidatesTags: ['scheduledMessage'],
        }),
        getAllScheduledMessageByUserAsync: builder.query({
            query: (_, { getState }) => {
                return `/user/message/schedule/${userId}`;
            },
            providesTags: ['scheduledMessage'],
        }),
        getAllUserScheduledMessageByTypeAsync: builder.mutation({
            query: (scheduleType) => ({
                url: `/user/message/logs/${userId}`,
                method: 'POST',
                body: {
                    scheduled_type: scheduleType.scheduled_type
                }

            }),
            providesTags: ['scheduledMessage'],
            transformResponse: res => res.response,
        }),
        getSingleScheduledMessageByUserAsync: builder.query({
            query: (_, { getState }) => {
                return `/user/message/schedule/${userId}`;
            },
            providesTags: ['scheduledMessage'],
            onError: (error) => {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            },
        }),
        updateSingleScheduledMessageByUserAsync: builder.mutation({
            query: (_, { getState }) => {
                return {
                    url: `/user/message/schedule/${userId}`,
                    method: 'PUT',
                };
            },
            invalidatesTags: ['scheduledMessage'],
            onError: (error) => {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            },
        }),
        deleteSingleScheduledMessageByUserAsync: builder.mutation({
            query: (_, { getState }) => {
                return {
                    url: `/user/message/schedule/${userId}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: ['scheduledMessage'],
            onError: (error) => {
                toast.error(error.message, {
                    position: toast.POSITION.TOP_CENTER,
                });
            },
        }),
        generateUserCampaignReportAsync: builder.mutation({
            query: (userInput) => ({
                url: `/user/report/${userId}`,
                method: 'POST',
                body: {
                    is_sms: userInput.data.isSMS,
                    id: userInput.data.projectId,
                    scheduled_date: userInput.data.startDate,
                    ended_at: userInput.data.endDate,
                }
            }),
            transformResponse: res => res.response,
        }),
        getUserCampaignStatAsync: builder.query({
            query: (userId) => `/user/report/stat/${userId}`,
            providesTags: [''],
            transformResponse: res => res.response,
        }),
        getUserDeliveryReportAsync: builder.mutation({
            query: (({ user_id, start_date, end_date }) => ({
                url: `/user/report/voice/within/${user_id}`,
                method: 'POST',
                body: {
                    start_date,
                    end_date,
                }
            }))
        }),
    }),
});

export const {
    useCreateNewScheduledMessageAsyncMutation,
    useGetAllScheduledMessageByUserAsyncQuery,
    useGetAllUserScheduledMessageByTypeAsyncMutation,
    useGetSingleScheduledMessageByUserAsyncQuery,
    useUpdateSingleScheduledMessageByUserAsyncMutation,
    useDeleteSingleScheduledMessageByUserAsyncMutation,
    useGenerateUserCampaignReportAsyncMutation,
    useGetUserCampaignStatAsyncQuery,
    useGetUserDeliveryReportAsyncMutation,
} = campaignApi;
