import React, { useEffect } from "react";
import { ClipLoader } from "react-spinners";
import { useGenerateOTPMutation } from "../api/auth-api-slice";

const OTPResend = ({ userId, onResend }) => {
  const [
    generateOTP,
    { isSuccess, isLoading, isError, error },
  ] = useGenerateOTPMutation();

  useEffect(() => {
    if (isSuccess) {
      onResend({
        type: "success",
        message: "OTP Verification Sent Successfully",
      });
    }
  }, [isSuccess, onResend]);

  useEffect(() => {
    if (isError) {
      onResend({
        type: "error",
        message: error?.data?.error,
      });
    }
  }, [error?.data?.error, isError, onResend]);

  const handleResend = () => {
    generateOTP(userId);
  };

  return (
    <div className="text-sm text-slate-600 flex">
      <p className="mr-2">Didn&apos;t receive code?</p>
      <button
        type="button"
        className="text-color-primary"
        onClick={handleResend}
        disabled={isLoading}
      >
        {isLoading && <ClipLoader color="#808080" size={15} />}
        Resend
      </button>
    </div>
  );
};

export default OTPResend;
