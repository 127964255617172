import Cookies from "js-cookie";
import React from "react";

const useAuth = () => {
  const [authed, setAuthed] = React.useState(false);

  return {
    authed,
    login(user) {
      Cookies.set("user_session", user.jwt_key);
      sessionStorage.setItem("id", user.id);
      sessionStorage.setItem("organization_name", user.organization);

      setAuthed(true);
    },
    logout() {
      setAuthed(false);
    },
  };
};

export { useAuth };
