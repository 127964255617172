/* eslint-disable consistent-return */
/* eslint-disable camelcase */
/* eslint-disable import/prefer-default-export */
// import { toast } from 'react-toastify';
import { apiSlice } from "../../../api/api";

const userId = sessionStorage.getItem("id");

export const authApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createNewAccount: builder.mutation({
      query: (userInput) => ({
        url: "/user",
        method: "POST",
        body: {
          full_name: userInput.full_name,
          password: userInput.password,
          email: userInput.email,
          organization: userInput.organization,
          phone_number: userInput.phone_number,
        },
      }),
    }),
    generateOTP: builder.mutation({
      query: (userId) => ({
        url: `/user/otp/${userId}`,
        method: "GET",
      }),
    }),
    verifyOTP: builder.mutation({
      query: (data) => ({
        url: "/user/otp/verify",
        method: "POST",
        body: {
          secret: data.secret,
          phone_number: data.phoneNumber,
        },
      }),
    }),
    changePassword: builder.mutation({
      query: ({ phone_number, password }) => ({
        url: "/user/change-password",
        method: "PUT",
        body: {
          password,
          phone_number,
        },
      }),
    }),
    resetPassword: builder.mutation({
      query: ({ password, userPhone }) => ({
        url: "/user/change-password",
        method: "PUT",
        body: {
          phone_number: userPhone,
          password,
        },
      }),
    }),

    forgotPassword: builder.mutation({
      query: (userInput) => ({
        url: "/user/forget-password",
        method: "POST",
        body: {
          phone_number: userInput.phoneNumber,
        },
      }),
    }),

    UpdatePhoneNumber: builder.mutation({
      query: ({ userPhone, userId }) => ({
        url: `/user/${userId}`,
        method: "PUT",
        body: userPhone,
      }),
    }),
    updateUserBioAsync: builder.mutation({
      query: (userInput) => ({
        url: `/user/${userId}`,
        method: "PUT",
        body: userInput.data,
      }),
    }),
    login: builder.mutation({
      query: ({ userEmail, userPassword }) => ({
        url: "/auth/user/login",
        method: "POST",
        body: {
          email: userEmail,
          password: userPassword,
        },
      }),
    }),
    getUserBioAsync: builder.query({
      query: (userId) => `/user/${userId}`,
      transformResponse: (res) => res.response,
    }),
    logOutUserAsync: builder.mutation({
      query: () => ({
        url: "/auth/user/logout",
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),
  }),
});

export const {
  useCreateNewAccountMutation,
  useGenerateOTPMutation,
  useVerifyOTPMutation,
  useResetPasswordMutation,
  useForgotPasswordMutation,
  useUpdatePhoneNumberMutation,
  useUpdateUserBioAsyncMutation,
  useLoginMutation,
  useGetUserBioAsyncQuery,
  useLogOutUserAsyncMutation,
  useChangePasswordMutation,
} = authApi;
