/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React from "react";
import { FaChevronRight } from "react-icons/fa";
import {
  Card,
  CustomLink,
  InnerPageHeader,
} from "../features/ui";
import { SubscribersTable } from "../features/subscriptions";

const Subscribers = () => {
  const pageConfig = {
    title: "Subscribers",
    subTitle: `Subscribers are who we want to engage with
    (send SMS & Voice campaigns to) on the platform.`,
  };

  return (
    <React.Fragment>
      <Card>
        <div className="flex justify-between items-center">
          <div className=" basis-[60%]">
            <InnerPageHeader
              pageTitle={pageConfig.title}
              pageSubTitle={pageConfig.subTitle}
            />
          </div>

          <CustomLink
            label="New subscriber"
            reference="/subscribers/new-subscriber"
            icon={<FaChevronRight />}
          />
        </div>
        <SubscribersTable />
      </Card>
    </React.Fragment>
  );
};

export default Subscribers;
