/* eslint-disable react/button-has-type */
/* eslint-disable react/function-component-definition */
import React from 'react';
import PropTypes from 'prop-types';

const Input = ({
  placeholder,
  type,
  label,
  identity,
  handleInputChange,
}) => {
  const handleChange = () => {
    handleInputChange();
  };

  return (
    <div>
      <label
        htmlFor={identity}
        className="text-slate-600 text-lg"
      >
        {label}
      </label>
      <input
        type={type}
        name={identity}
        id={identity}
        className="border-2 text-lg text-slate-800 font-normal p-1 w-full focus:outline-color-primary"
        onChange={handleChange}
        placeholder={placeholder}
      />
    </div>
  );
};

Input.defaultProps = {
  type: 'text',
  placeholder: 'Input field',
  label: 'Label',
};

Input.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  identity: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  handleInputChange: PropTypes.func.isRequired,
};

export default Input;
