import moment from "moment";

const transformTableData = (data) => {
  if (!Array.isArray(data) || data.length === 0) {
    return [];
  }

  return data.map((project, index) => ({
    serial: index + 1,
    id: project.id,
    title: project.name,
    date_created: moment(project.created_at).format("DD-MM-YYYY"),
  }));
};

export default transformTableData;
