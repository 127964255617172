/* eslint-disable react/button-has-type */
/* eslint-disable react/function-component-definition */
import React from "react";
import PropTypes from "prop-types";
import { Button, CardError, ModalWrapper } from "../ui";
import { useDeleteGroupAsyncMutation } from "./group-api-slice";
import { ClipLoader } from "react-spinners";

const DeleteModal = ({ modalRef }) => {
  const userId = sessionStorage.getItem("id");
  const [
    deleteGroupAsync,
    { isLoading: isDeleting, isSuccess, isError, error },
  ] = useDeleteGroupAsyncMutation();

  React.useEffect(() => {
    if (isSuccess) {
      if (typeof window !== "undefined") {
        sessionStorage.removeItem("groupId");
      }
      modalRef.current.close();
    }
  }, [isSuccess, modalRef]);

  const handleDelete = (e) => {
    let groupId = null;
    if (typeof window !== "undefined") {
      groupId = sessionStorage.getItem("groupId");
    }
    deleteGroupAsync({
      groupId,
      user_id: userId,
    });
  };

  const handleDialogClose = () => {
    if (typeof window !== "undefined") {
      sessionStorage.removeItem("groupId");
    }
    modalRef.current.close();
    document.body.style.overflow = "unset";
  };

  return (
    <ModalWrapper modalRef={modalRef}>
      <section className="space-y-4 flex flex-col justify-center items-center">
        {isError && <CardError message={error?.data?.error} />}

        <div className="dialog-header text-center">
          <h4 className="tex-md text-slate-600 font-bold">Delete Group</h4>
          <p className="text-slate-400 text-sm">
            You are about to delete a group.
          </p>
          <p className="text-slate-400 my-2 text-sm">
            Are you sure of this action?
          </p>
        </div>

        <div className="dialog-footer flex items-start space-x-4">
          <Button
            type="button"
            otherStyles="bg-slate-200 text-zinc-800 hover:bg-slate-300"
            handleBtnClick={handleDialogClose}
          >
            No, Keep it
          </Button>
          <Button
            type="button"
            otherStyles="bg-red-400 hover:bg-red-600"
            handleBtnClick={handleDelete}
          >
            Yes, Delete it!
            {isDeleting && <ClipLoader color="#fff" size={20} />}
          </Button>
        </div>
      </section>
    </ModalWrapper>
  );
};

DeleteModal.propTypes = {
  modalRef: PropTypes.instanceOf(Object).isRequired,
};

export default DeleteModal;
