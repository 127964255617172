/* eslint-disable object-curly-newline */
/* eslint-disable max-len */
/* eslint-disable operator-linebreak */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { ClipLoader } from 'react-spinners';
import moment from 'moment';
import {
  Button, CardWithHeader, SelectField, TextInput,
} from '../ui';
import { useGetAllProjectsByUserIdAsyncQuery } from '../projects/project-slice';
import { useGenerateUserCampaignReportAsyncMutation } from './campaign-api-slice';
import convertJsonToExcel from './utils';
import generatePDF from './generate-pdf';

const CampaignReportGenerationForm = () => {
  const userId = sessionStorage.getItem("id");
  const [generateUserCampaignReportAsync, { data: generateUserCampaignReportAsyncMutation,
    isLoading, isSuccess, error }] = useGenerateUserCampaignReportAsyncMutation();

  const { data: getAllProjectsByUser, isLoading: pjLoading, isSuccess: projectSuccess, error: pError }
    = useGetAllProjectsByUserIdAsyncQuery(userId);

  const [reportsProject, setReportsProject] = useState([]);

  useEffect(() => {
    if (projectSuccess && getAllProjectsByUser) {
      setReportsProject(getAllProjectsByUser);
    }
  }, [getAllProjectsByUser, projectSuccess]);

  const initialValues = {
    startDate: '',
    endDate: '',
    channelName: '',
    projectName: '',
  };

  const validationSchema = Yup.object({
    startDate: Yup.string().required('Start date cannot be empty'),
    endDate: Yup.string().required('End date cannot be empty'),
    channelName: Yup.string().required('Channel name cannot be empty'),
    projectName: Yup.string(),
  });

  useEffect(() => {
    if (isSuccess && generateUserCampaignReportAsyncMutation) {
      convertJsonToExcel(generateUserCampaignReportAsyncMutation);
      generatePDF(generateUserCampaignReportAsyncMutation);
    }
  }, [generateUserCampaignReportAsyncMutation, isSuccess])

  const handleSubmit = (values) => {
    const userInput = {
      isSMS: values.channelName === 'sms',
      // isVoice: values.channelName === 'voice',
      startDate: moment(values.startDate).format('YYYY-MM-DD HH:mm'),
      endDate: moment(values.endDate).format('YYYY-MM-DD HH:mm'),
      projectId: values.projectName || null,
    };

    generateUserCampaignReportAsync({ data: userInput });

  };

  return (
    <CardWithHeader title="Configuration">
      {error && (
        <div className="flex items-center justify-center mt-4">
          <p className="text-red-600 text-lg py-2 px-4 rounded-lg">
            <span className="text-red-600">{error?.data?.error}</span>
          </p>
        </div>
      )}
      {pError && (
        <div className="flex items-center justify-center mt-4">
          <p className="text-red-600 text-lg py-2 px-4 rounded-lg">
            <span className="text-red-600">{pError?.data?.error}</span>
          </p>
        </div>
      )}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        <Form className="mt-4 space-y-6 pb-6 px-2">
          <div className="space-y-4 md:grid md:grid-cols-5 md:items-baseline md:gap-x-4">
            <div>
              <TextInput
                label="Start Date"
                name="startDate"
                id="startDate"
                type="datetime-local"
              />
            </div>
            <div>
              <TextInput
                label="End Date"
                name="endDate"
                id="endDate"
                type="datetime-local"
              />
            </div>
            <div>
              {
                pjLoading && (
                  <p>
                    Loading projects..
                    <ClipLoader size={10} color="grey" />
                  </p>
                )
              }
              <SelectField
                label="Project"
                name="projectName"
                id="projectName"
              >
                <option value="">All projects</option>
                {
                  reportsProject && (
                    reportsProject.map((projectItem, index) => (
                      <option key={index} value={projectItem.id}>{projectItem.name}</option>
                    ))
                  )
                }
              </SelectField>
            </div>
            <div>
              <SelectField
                label="Channel"
                name="channelName"
                id="channelName"
              >
                <option value="">Choose a channel</option>
                <option value="voice">Voice</option>
                <option value="sms">SMS</option>
              </SelectField>
            </div>
            <div className="md:place-self-end">
              <Button
                type="submit"
                label="Generate Report"
                disabled={isLoading}
              >
                {isLoading && <ClipLoader size={20} color="#fff" />}
                Generate Report
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </CardWithHeader>
  );
};

export default CampaignReportGenerationForm;
