import React from "react";
import AudioPlayer from "./audio-upload-player";
import { Field } from "formik";

const AudioTempUploadTable = ({ data }) => {
  return (
    <div className="overflow-x-auto">
      <table className="min-w-full border-collapse">
        <thead>
          <tr>
            <th className="border border-gray-400 px-4 py-2">Language</th>
            <th className="border border-gray-400 px-4 py-2">Audio file</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={index} className={index % 2 === 0 ? "bg-gray-100" : ""}>
              <td className="border border-gray-400 px-4 py-2">
                {item.language}
              </td>
              <td className="border border-gray-400 px-4 py-2">
                <Field name={`audioFiles.${item.id}`}>
                  {({ field, form }) => (
                    <AudioPlayer
                      onAudioUpload={(file) => {
                        form.setFieldValue(field.name, file);
                      }}
                    />
                  )}
                </Field>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default AudioTempUploadTable;
