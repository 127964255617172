/* eslint-disable react/function-component-definition */
import React from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

const Tooltip = ({ children, tooltipRef }) => (
  createPortal(
    <div
      className=""
      ref={tooltipRef}
    >
      {children }
    </div>,
    document.body,
  )
);

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Tooltip;
