/* eslint-disable no-unused-vars */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from "react";
import { FaKey, FaUser } from "react-icons/fa";
import { ClipLoader } from "react-spinners";
import { CustomLink } from "../../ui";
import { useGetUserBioAsyncQuery } from "../api/auth-api-slice";

const ProfileInfo = () => {
  const userId = sessionStorage.getItem("id");
  const { data: getUserBio, isSuccess, isLoading } = useGetUserBioAsyncQuery(
    userId
  );

  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    if (isSuccess && getUserBio) {
      setUserDetails(getUserBio);
    }
  }, [getUserBio, isSuccess]);

  const userProfileInfo = {
    Organization: userDetails.organization,
    "Full name": userDetails.full_name,
    "Official Email": userDetails.email,
    Phone: userDetails.phone_number,
  };

  return (
    <section>
      {isLoading ? (
        <div className="w-full">
          <ClipLoader />
        </div>
      ) : (
        <ul className="space-y-2 text-slate-700">
          {Object.entries(userProfileInfo).map((entry) => {
            const [key, value] = entry;
            return (
              <li
                key={key}
                className="odd:bg-slate-100 p-2 md:grid md:grid-cols-2"
              >
                <p className="font-medium">{key}</p>
                <p className="text-color-primary">{value}</p>
              </li>
            );
          })}
        </ul>
      )}
    </section>
  );
};

export default ProfileInfo;
