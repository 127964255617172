/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React from "react";
import { Card, InnerPageHeader } from "../features/ui";
import { AddProjectForm } from "../features/projects";

const EditProject = () => {
  const pageConfig = {
    title: "Update Project Details",
    subTitle: "",
  };

  return (
    <React.Fragment>
      <Card>
        <InnerPageHeader
          pageTitle={pageConfig.title}
          pageSubTitle={pageConfig.subTitle}
        />
        <AddProjectForm />
      </Card>
    </React.Fragment>
  );
};

export default EditProject;
