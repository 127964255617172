import moment from "moment";

const transformTableData = (data) => {
  if (!Array.isArray(data) || data.length === 0) {
    return [];
  }

  return data.map((language, index) => ({
    serial: index + 1,
    id: language.id,
    title: language.title,
    date_created: moment(language.created_at).format("DD-MM-YYYY"),
  }));
};

export default transformTableData;
