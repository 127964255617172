/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React from "react";
import { Card, InnerPageHeader } from "../features/ui";
import { GroupsTable } from "../features/groups";
import { useLocation } from "react-router-dom";

const ViewGroupSubscribers = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const subCount = queryParams.get("sub_count");
  const title = queryParams.get("title");
  const description = queryParams.get("desc");

  const pageConfig = {
    title: `${title}`,
    subTitle: `${description}`,
  };

  return (
    <React.Fragment>
      <Card>
        <div className="flex justify-between items-start">
          <div className=" basis-[80%]">
            <InnerPageHeader
              pageTitle={pageConfig.title}
              pageSubTitle={pageConfig.subTitle}
            />
          </div>

          <h5 className="bg-white flex flex-col justify-center items-center font-medium p-2 rounded">
            <span className="text-color-primary font-extrabold text-2xl block">
              {subCount ? subCount : 0}
            </span>
            <p className=" text-sm text-slate-500">Subscriber(s)</p>
          </h5>
        </div>
        <GroupsTable />
      </Card>
    </React.Fragment>
  );
};

export default ViewGroupSubscribers;
