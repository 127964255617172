import React from "react";
import { GiDutchBike } from "react-icons/gi";

const ErrorDisplay = () => {
  return (
    <section className="flex flex-col items-center justify-center py-8 bg-red-50 rounded-lg">
      <div>
        <GiDutchBike size={150} color="#94a3b8" />
      </div>
      <p className="text-lg font-light text-slate-500">
        Hmm... something went wrong on our end. But dont't fret, we are
        currently trying to fix the problem. <br /> In the meantime, you can
        reload the page to try again.
      </p>
    </section>
  );
};

export default ErrorDisplay;
