/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React from 'react';
import PropTypes from 'prop-types';

const Table = ({ tableConfigurationObject, children }) => {
  const { tableHeaders } = tableConfigurationObject;

  return (
    <table className="table-auto border-separate border-spacing-0 border-b-color-primary border-b-2 w-full rounded-md my-4">
      <thead className="bg-slate-700 text-slate-50">
        <tr className="text-left">
          {
            tableHeaders?.map((header) => <th key={header} className=" first:p-4">{header}</th>)
          }
        </tr>
      </thead>
      <tbody className="bg-slate-50">
        {
          children || (
            <tr className="text-slate-500">
              <td
                colSpan={`${tableHeaders.length}`}
                className="text-center py-10"
              >
                No data available in the table
              </td>
            </tr>
          )
        }
      </tbody>
    </table>
  );
};

Table.propTypes = {
  tableConfigurationObject: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.node.isRequired,
};

export default Table;
