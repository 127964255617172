import React from "react";
import { Card, InnerPageHeader } from "../features/ui";
import { AddLanguageForm } from "../features/languages";
import { useParams } from "react-router-dom";

const AddNewLanguage = () => {
  const { id: languageId } = useParams();

  const pageConfig = {
    title: languageId ? "Update Language" : "New Language",
    subTitle: "",
  };

  return (
    <React.Fragment>
      <Card>
        <section>
          <InnerPageHeader
            pageTitle={pageConfig.title}
            pageSubTitle={pageConfig.subTitle}
          />
          <AddLanguageForm />
        </section>
      </Card>
    </React.Fragment>
  );
};

export default AddNewLanguage;
