import { apiSlice } from "../../api/api";

export const senderApi = apiSlice.injectEndpoints({
  reducerPath: "senderApi",
  endpoints: (builder) => ({
    createSenderAsync: builder.mutation({
      query: (arg) => ({
        url: "/sms/sender",
        method: "POST",
        body: {
          user_id: arg.userId,
          name: arg.name,
          description: arg.description,
        },
      }),
      invalidatesTags: ["senders"],
    }),
    getAllSendersByUserIdAsync: builder.query({
      query: (userId) => `/sms/sender/user/${userId}`,
      providesTags: ["senders"],
      transformResponse: (res) => res.response,
    }),
    deleteSenderByUserIdAsync: builder.mutation({
      query: () => ({}),
    }),
  }),
});

export const {
  useCreateSenderAsyncMutation,
  useGetAllSendersByUserIdAsyncQuery,
  useDeleteSenderByUserIdAsyncMutation,
} = senderApi;
