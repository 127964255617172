/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React from "react";
import { Card, CustomLink } from "../features/ui";
import { GroupList } from "../features/groups";
import { FaChevronRight } from "react-icons/fa";

const Groups = () => {
  return (
    <React.Fragment>
      <Card>
        <div className="md:flex md:justify-end mb-8">
          <CustomLink
            label="New Group"
            reference="/subscribers/add-group"
            icon={<FaChevronRight />}
          />
        </div>
        <GroupList />
      </Card>
    </React.Fragment>
  );
};

export default Groups;
