/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import moment from "moment";
import { Button, SelectField, TextInput } from "../ui";
import { useGetAllGroupsByUserIdAsyncQuery } from "../groups";
import { ClipLoader } from "react-spinners";
import { useGetUserSubscribersToExportMutation } from "./subscription-slice";
import { exportJsonToExcel } from "../campaigns/utils";

const ExportscribersForm = () => {
  const userId = sessionStorage.getItem("id");
  const [subscribers, setSubscribers] = React.useState([]);

  const [
    getSubscribers,
    {
      data: subsData,
      isLoading: getSubsLoading,
      isSuccess: getSubsSuccess,
      // isError: isGetSubsError,
      // error: getSubsError,
    },
  ] = useGetUserSubscribersToExportMutation();

  const {
    // isLoading: groupLoading,
    isSuccess: groupSuccess,
    data: groupsData,
  } = useGetAllGroupsByUserIdAsyncQuery(userId);

  const [group, setGroup] = useState([]);
  useEffect(() => {
    if (groupSuccess && groupsData) {
      setGroup(groupsData);
    }
  }, [groupsData, groupSuccess]);

  useEffect(() => {
    if (getSubsSuccess && subsData) {
      console.log("subsData.response: ", subsData.response);
      setSubscribers(subsData.response);
    }
  }, [getSubsSuccess, subsData]);

  // React.useEffect(() => {
  //   const transformedData = Array.isArray(subscribers)
  //     ? subscribers.map((data, index) => {
  //         const {
  //           full_name,
  //           phone_number,
  //           language,
  //           age,
  //           gender,
  //           comment,
  //           region,
  //           district,
  //         } = data;

  //         return {
  //           serial: index + 1,
  //           full_name,
  //           phone_number,
  //           language,
  //           age,
  //           gender,
  //           comment,
  //           region,
  //           district,
  //         };
  //       })
  //     : [];

  //   if (getSubsSuccess && subsData) exportJsonToExcel(transformedData);
  // }, [getSubsSuccess, subsData, subscribers]);

  const validationSchema = Yup.object({
    startDate: Yup.string().required("Start date cannot be empty"),
    endDate: Yup.string().required("End date cannot be empty"),
  });

  const initialValues = {
    startDate: "",
    endDate: "",
    group: "",
  };

  const handleExport = async (values, { resetForm }) => {
    try {
      const all_sub_default = "all";
      const userInput = {
        user_id: userId,
        group_id: values.group,
        all_sub: values.group ? "" : all_sub_default,
        start_date: moment(values.startDate).format("YYYY-MM-DD HH:mm"),
        end_date: moment(values.endDate).format("YYYY-MM-DD HH:mm"),
      };
      // getSubscribersToExport(userInput).then(() => {
      //   processExport();
      // });
      const response = await getSubscribers(userInput);
      if (!response.error) resetForm();
    } catch (error) {}
  };

  return (
    <section>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={(values, helpers) => handleExport(values, helpers)}
      >
        <Form>
          <div className="space-y-4">
            <SelectField label="Export" type="file" name="group" id="group">
              <option>All subscribers</option>
              {Array.isArray(group)
                ? group.map((groupItem, index) => (
                    <option key={index} value={groupItem.ID}>
                      {groupItem.Name}
                    </option>
                  ))
                : ""}
            </SelectField>
            <TextInput
              label="From"
              name="startDate"
              id="startDate"
              type="datetime-local"
            />
            <TextInput
              label="To"
              name="endDate"
              id="endDate"
              type="datetime-local"
            />

            <div className="my-4 md:flex md:justify-end">
              <Button
                type="Submit"
                label="Export Subscribers"
                otherStyles="bg-accent-gradient text-light-900"
                disabled={getSubsLoading}
              >
                {getSubsLoading && <ClipLoader size={20} color="#fff" />}
                Export
              </Button>
            </div>
          </div>
        </Form>
      </Formik>
    </section>
  );
};

export default ExportscribersForm;
