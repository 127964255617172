/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
/* eslint-disable import/no-cycle */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import { Formik, Form } from "formik";
import React, { useEffect, useRef, useState } from "react";
// import * as Yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import {
  Button,
  Checkbox,
  SelectField,
  TextInput,
  TextArea,
  AppToast,
} from "../ui";
import { useGetAllGroupsByUserIdAsyncQuery } from "../groups/group-api-slice";
import {
  useImportUserSubscriberNewGroupsAsyncMutation,
  useImportUserSubscribersAsyncMutation,
} from "./subscription-slice";
import { RiFileExcel2Fill } from "react-icons/ri";
import { toast } from "react-toastify";

const UploadSubscribersForm = () => {
  const fileUploadRef = useRef(null);
  const userId = sessionStorage.getItem("id");
  const [selectedFileName, setSelectedFileName] = useState("");

  const {
    isLoading: getAllGroupByUserIdLoading,
    isError: getAllGroupByUserIdError,
    data: getAllGroupByUserIdData,
    isSuccess: getAllGroupByUserIdSuccess,
  } = useGetAllGroupsByUserIdAsyncQuery(userId);
  const [
    importUserSubscriberNewGroupsAsync,
    {
      isLoading: importUserSubscriberNewGroupsLoading,
      isSuccess: isImportUserSubscriberNewGroupsSuccess,
      isError: isImportUserSubscriberNewGroupsError,
      error: importUserSubscriberNewGroupsError,
    },
  ] = useImportUserSubscriberNewGroupsAsyncMutation();
  const [
    importUserSubscribersAsync,
    {
      isLoading: importUserSubscribersLoading,
      isSuccess: isImportUserSubscribersSuccess,
      isError: isImportUserSubscribersError,
      error: importUserSubscribersError,
    },
  ] = useImportUserSubscribersAsyncMutation();
  const [formGroups, setFormGroups] = useState([]);

  useEffect(() => {
    if (getAllGroupByUserIdSuccess) {
      setFormGroups(getAllGroupByUserIdData);
    }
  }, [getAllGroupByUserIdData, getAllGroupByUserIdSuccess]);

  const [showControl, setShowControl] = useState("existingGroup");
  const handleGroupTypeChange = (event) => {
    if (event.target.value === "existingGroup") {
      setShowControl("existingGroup");
    } else if (event.target.value === "newGroup") {
      setShowControl("newGroup");
    }
  };

  const handleXlsxClick = () => {
    fileUploadRef.current.click();
  };

  const handleFileChange = (event, setFieldValue) => {
    const file = event.currentTarget.files[0];
    setSelectedFileName(file?.name);

    setFieldValue("subscriberListExcelFile", file);
  };

  const initialValues = {
    subscriberListExcelFile: null,
    subscriberGroupType: "",
    selectGroup: "",
    groupName: "",
    groupDescription: "",
    groupTags: "",
  };

  const handleFileImport = async (values, { resetForm }) => {
    try {
      if (showControl === "existingGroup") {
        const formData = new FormData();

        formData.append("file_header", values.subscriberListExcelFile);
        formData.append("group_id", values.selectGroup);

        const response = await importUserSubscribersAsync({ formData });
        if (!response.error) {
          resetForm();
        }
      }

      if (showControl === "newGroup") {
        const formData = new FormData();

        formData.append("file_header", values.subscriberListExcelFile);
        formData.append("name", values.groupName);
        formData.append("description", values.groupDescription);
        formData.append("tags", values.groupTags);

        const response = await importUserSubscriberNewGroupsAsync({ formData });
        !response.error && resetForm();
      }
    } catch (error) {}
  };

  return (
    <section>
      <Formik
        initialValues={initialValues}
        // validationSchema={validationSchema}
        onSubmit={(values, helpers) => handleFileImport(values, helpers)}
      >
        {({ setFieldValue }) => (
          <Form className="space-y-4">
            <div className="md:grid md:items-start md:grid-cols-2 md:gap-x-4">
              <div className="">
                <input
                  hidden
                  label=""
                  type="file"
                  accept=".xlsx"
                  name="subscriberListExcelFile"
                  id="subscriberListExcelFile"
                  otherstyles="invisible h-0"
                  ref={fileUploadRef}
                  value={""}
                  onChange={(event) => handleFileChange(event, setFieldValue)}
                />
                <div className="">
                  <button
                    type="button"
                    className="flex items-center border rounded-md p-2 bg-light-800 hover:bg-light-700 transition-all duration-300"
                    onClick={handleXlsxClick}
                  >
                    <RiFileExcel2Fill className="text-2xl text-color-primary" />
                    <span className="ml-2 text-light-500">Select File</span>
                  </button>
                  {selectedFileName && (
                    <span className="inline-block border rounded-md p-2 my-2 text-light-400 text-sm bg-accent-800">
                      {selectedFileName}
                    </span>
                  )}
                </div>

                <div className="py-4 md:flex md:items-center">
                  <div className="mr-4">
                    <Checkbox
                      type="radio"
                      name="subscriberGroupType"
                      id="existingGroup"
                      value="existingGroup"
                      checked={
                        showControl.toLocaleLowerCase() === "existinggroup"
                      }
                      onChange={handleGroupTypeChange}
                    >
                      Add subscribers to existing group
                    </Checkbox>
                  </div>
                  <div>
                    <Checkbox
                      type="radio"
                      name="subscriberGroupType"
                      id="newGroup"
                      value="newGroup"
                      checked={showControl.toLocaleLowerCase() === "newgroup"}
                      onChange={handleGroupTypeChange}
                    >
                      Add subscribers to new group
                    </Checkbox>
                  </div>
                </div>
              </div>

              <div>
                {showControl === "existingGroup" && (
                  <div>
                    <SelectField
                      name="selectGroup"
                      id="existingGroup"
                      label="Select Group"
                    >
                      <option>Choose a group</option>
                      {formGroups?.map((groupItem, index) => (
                        <option key={index} value={groupItem.ID}>
                          {groupItem.Name}
                        </option>
                      ))}
                    </SelectField>
                  </div>
                )}

                {showControl === "newGroup" && (
                  <div>
                    <div className="space-y-4">
                      <div>
                        <TextInput
                          label="Group Name"
                          id="groupName"
                          name="groupName"
                          placeholder="Enter name of group"
                        />
                      </div>
                      <div>
                        <TextArea
                          label="Description"
                          name="groupDescription"
                          id="groupDescription"
                          placeholder="Enter description of group here"
                        />
                      </div>
                      <div>
                        <TextInput
                          label="Group Tags"
                          id="groupTags"
                          name="groupTags"
                          placeholder="Enter tags for this group. Eg. Tech, Finance etc."
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="flex justify-end">
              <Button
                type="Submit"
                label="Submit"
                otherStyles="bg-accent-gradient text-light-900"
              >
                {(importUserSubscribersLoading ||
                  importUserSubscriberNewGroupsLoading) && (
                  <ClipLoader color="#fff" size={20} />
                )}
                Import
              </Button>
            </div>
          </Form>
        )}
      </Formik>
      <AppToast
        isSuccess={
          isImportUserSubscribersSuccess ||
          isImportUserSubscriberNewGroupsSuccess
        }
        successMessage="Subscribers imported successfully"
        isError={
          isImportUserSubscriberNewGroupsError || isImportUserSubscribersError
        }
        errorMessage={
          importUserSubscribersError?.data?.error ||
          importUserSubscriberNewGroupsError?.data?.error
        }
      />
    </section>
  );
};

export default UploadSubscribersForm;
