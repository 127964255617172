/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable no-lone-blocks */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import moment from "moment";
import { useGetAllProjectsByUserIdAsyncQuery } from "../../projects/project-slice.js";
import { useCreateNewScheduledMessageAsyncMutation } from "../../campaigns/campaign-api-slice.js";
import { useGetAllGroupsByUserIdAsyncQuery } from "../../groups/group-api-slice.js";
import { useGetAllSendersByUserIdAsyncQuery } from "../../senders/sender-slice.js";
import { useGetAllUserSubscribersAsyncQuery } from "../../subscriptions/subscription-slice.js";
import { useGetAllMessageTemplatesByUserIdAsyncQuery } from "../messaging-slice.js";
import NewOutboundCampaignFormUI from "./new-outbound-campaign-form-ui.jsx";
import { CreateSenderIdModal } from "../../senders";
import { AppToast } from "../../ui/index.js";

const NewOutboundCampaignForm = () => {
  const [campaignProjects, setCampaignProjects] = useState([]);
  const [campaignSender, setCampaignSender] = useState([]);
  const [campaignMessage, setCampaignMessage] = useState([]);
  const [campaignGroups, setCampaignGroups] = useState([]);
  const [campaignSubscribers, setCampaignSubscribers] = useState([]);
  const [scheduleType, setScheduleToggle] = useState("");
  const userId = sessionStorage.getItem("id");
  const modalRef = React.useRef();

  const {
    data: getAllProjectsByUserIdData,
    isSuccess: getAllProjectsByUserIdSuccess,
  } = useGetAllProjectsByUserIdAsyncQuery(userId);

  const {
    data: allmessageTemplates,
    isSuccess: messageTemplateSuccess,
  } = useGetAllMessageTemplatesByUserIdAsyncQuery(userId);

  const {
    data: getAllSendersByUserIdData,
    isSuccess: getAllSendersByUserIdSuccess,
  } = useGetAllSendersByUserIdAsyncQuery(userId);

  const {
    data: getAllGroupByUserIdData,
    isSuccess: getAllGroupByUserIdSuccess,
  } = useGetAllGroupsByUserIdAsyncQuery(userId);

  const {
    data: getAllUserSubscribersData,
    isSuccess: getAllUserSubscribersSuccess,
  } = useGetAllUserSubscribersAsyncQuery(userId);

  const [
    createNewScheduledMessageAsync,
    {
      isLoading: createNewScheduledMessageLoading,
      isError: isCreateNewScheduledMessageError,
      error: createNewScheduledMessageError,
      isSuccess: isCreateNewScheduledMessageSuccess,
    },
  ] = useCreateNewScheduledMessageAsyncMutation();

  useEffect(() => {
    if (
      getAllProjectsByUserIdSuccess &&
      getAllSendersByUserIdSuccess &&
      messageTemplateSuccess &&
      getAllGroupByUserIdSuccess &&
      getAllUserSubscribersSuccess
    ) {
      setCampaignProjects(getAllProjectsByUserIdData);
      setCampaignGroups(getAllGroupByUserIdData);
      setCampaignSubscribers(getAllUserSubscribersData);
      setCampaignMessage(allmessageTemplates);
      setCampaignSender(getAllSendersByUserIdData);
    }
  }, [
    allmessageTemplates,
    getAllGroupByUserIdData,
    getAllGroupByUserIdSuccess,
    getAllProjectsByUserIdData,
    getAllProjectsByUserIdSuccess,
    getAllSendersByUserIdData,
    getAllSendersByUserIdSuccess,
    getAllUserSubscribersData,
    getAllUserSubscribersSuccess,
    messageTemplateSuccess,
  ]);

  const initialValues = {
    campaignName: "",
    projectName: "",
    smsSenderId: "",
    campaignMessage: "",
    recipientType: "All Subscribers",
    subscribersByGroup: [],
    subscribersBySub: [],
    confirmSchedule: false,
    scheduleDate: "",
    endDate: "",
  };

  const validationSchema = Yup.object({
    campaignName: Yup.string().required("Campaign name cannot be empty"),
    projectName: Yup.string().required("Project name cannot be empty"),
    smsSenderId: Yup.string().required("Sms sender ID cannot be empty "),
    campaignMessage: Yup.string().required("Campaign message cannot be compty"),
    // confirmSchedule: Yup.bool().required('Choose a date and schedule type'),
    // scheduleType: Yup.string().required('Choose a schedule type'),
    // scheduleDate: Yup.string().required('Schedule data cannot be blank'),
  });

  const handleShowModal = () => {
    modalRef.current.showModal();
    document.body.style.overflow = "hidden";
  };

  const handleSubmit = async (values, { resetForm }) => {
    let userInput = {
      message_title: values.campaignName,
      project_id: values.projectName,
      message_template_id: values.campaignMessage,
      sender: values.smsSenderId,
      is_scheduled: false,
      scheduled_date: moment(values.scheduleDate).format("YYYY-MM-DD HH:mm"),
      scheduled_end_date: moment(values.endDate).format("YYYY-MM-DD HH:mm"),
    };

    try {
      switch (values.recipientType.toLocaleLowerCase()) {
        case "all subscribers":
          {
            const subscriberPhoneNums = campaignSubscribers.map(
              (sub) => sub.id
            );
            userInput = {
              ...userInput,
              recipients: subscriberPhoneNums,
            };
            const response = await createNewScheduledMessageAsync({
              userInput,
            });
            if (!response.error) {
              resetForm();
            }
          }
          break;
        case "select group(s)":
          {
            userInput = {
              ...userInput,
              group_array: values.subscribersByGroup,
            };
            const response = await createNewScheduledMessageAsync({
              userInput,
            });
            if (!response.error) {
              resetForm();
            }
          }
          break;
        case "select subscribers":
          {
            const filteredSubs = campaignSubscribers.filter((sub) => {
              return values.subscribersBySub.includes(sub.id);
            });
            const subPhoneNumbers = filteredSubs.map((sub) => sub.id);
            userInput = {
              ...userInput,
              recipients: subPhoneNumbers,
            };
            const response = await createNewScheduledMessageAsync({
              userInput,
            });
            if (!response.error) {
              resetForm();
            }
          }
          break;
        default: {
          const subscriberPhoneNums = campaignSubscribers.map((sub) => sub.id);
          userInput = {
            ...userInput,
            recipients: subscriberPhoneNums,
          };
          const response = await createNewScheduledMessageAsync({ userInput });
          if (!response.error) {
            resetForm();
          }
        }
      }
    } catch (error) {}
  };

  const handleScheduleTypeChange = (event) => {
    setScheduleToggle(event.target.value);
  };

  return (
    <React.Fragment>
      <NewOutboundCampaignFormUI
        initialValues={initialValues}
        validationSchema={validationSchema}
        handleSubmit={handleSubmit}
        campaignProjects={campaignProjects}
        campaignSender={campaignSender}
        campaignMessage={campaignMessage}
        campaignGroups={campaignGroups}
        campaignSubscribers={campaignSubscribers}
        createNewScheduledMessageLoading={createNewScheduledMessageLoading}
        handleScheduleTypeChange={handleScheduleTypeChange}
        scheduleType={scheduleType}
        handleShowModal={handleShowModal}
      />
      <CreateSenderIdModal modalRef={modalRef} />
      <AppToast
        isError={isCreateNewScheduledMessageError}
        errorMessage={createNewScheduledMessageError?.error}
        isSuccess={isCreateNewScheduledMessageSuccess}
        successMessage="Campaign scheduled successfully"
      />
    </React.Fragment>
  );
};

export default NewOutboundCampaignForm;
