import React from "react";

const IconSection = ({ icon, label }) => {
  return (
    <div className="flex items-center icon-wrapper">
      <span className="block mr-4 text-[23px] text-lg opacity-80">
        {icon}
      </span>
      <span className="block text-[14px]">{label}</span>
    </div>
  );
};

export default IconSection;
