/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React from "react";
import { Card, InnerPageHeader } from "../features/ui";
import { InstantVoiceForm } from "../features/messaging/components";

const InstantVoice = () => {
  const pageConfig = {
    title: "Instant voice campaign",
    subTitle: `Campaigns represent the Messages that are
               currently being sent to beneficiaries.`,
  };

  return (
    <React.Fragment>
      <Card>
        <section>
          <InnerPageHeader
            pageTitle={pageConfig.title}
            pageSubTitle={pageConfig.subTitle}
          />
          <InstantVoiceForm />
        </section>
      </Card>
    </React.Fragment>
  );
};

export default InstantVoice;
