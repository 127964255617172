/* eslint-disable react/button-has-type */
/* eslint-disable react/function-component-definition */
import React from "react";
import PropTypes from "prop-types";
import { Button, ModalWrapper } from "../ui";
// import { FaCalendar } from "react-icons/fa";
import moment from "moment";

const SubscriberDetailsModal = ({ modalRef, subscriberDetails }) => {
  const handleDialogClose = () => {
    if (typeof window !== "undefined") {
      sessionStorage.removeItem("prjId");
    }
    modalRef.current.close();
    document.body.style.overflow = "unset";
  };

  return (
    <ModalWrapper modalRef={modalRef}>
      <section className="text-slate-500">
        <div className="mb-4">
          <h1 className="text-xl font-semibold mb-2 text-slate-500">
            {subscriberDetails?.full_name}
          </h1>
        </div>

        <div className="font-medium text-[13px]">
          <p>Phone: {subscriberDetails?.phone_number}</p>
          <p>Language: {subscriberDetails?.language}</p>
          <p>Gender: {subscriberDetails?.gender}</p>
          <p>Region: {subscriberDetails?.region}</p>
          <p>District: {subscriberDetails?.district}</p>
          <p>Group: {subscriberDetails?.group}</p>
          <p>
            Comments:{" "}
            {subscriberDetails?.comments ||
              "No comments given for this subscriber"}
          </p>
          <p>
            Date created:{" "}
            {moment(subscriberDetails?.created_at).format("DD/MM/YYYY")}
          </p>
        </div>

        <div className="dialog-footer flex justify-end items-start space-x-4">
          <Button
            type="button"
            otherStyles="bg-slate-200 text-zinc-800 hover:bg-slate-300"
            handleBtnClick={handleDialogClose}
          >
            Ok, Close
          </Button>
        </div>
      </section>
    </ModalWrapper>
  );
};

SubscriberDetailsModal.propTypes = {
  modalRef: PropTypes.instanceOf(Object).isRequired,
};

export default SubscriberDetailsModal;
