/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React from "react";
import { Card, InnerPageHeader } from "../features/ui";
import { SingleMessageTemplate } from "../features/messaging/components";

const ViewMessageTemplateDetails = () => {
  const pageConfig = {
    title: "Message Details",
    subTitle: "Messages engage with our audience by sending them information.",
  };

  return (
    <React.Fragment>
      <Card>
        <InnerPageHeader
          pageTitle={pageConfig.title}
          pageSubTitle={pageConfig.subTitle}
        />
        <SingleMessageTemplate />
      </Card>
    </React.Fragment>
  );
};

export default ViewMessageTemplateDetails;
