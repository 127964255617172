/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */

import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { FaChevronDown, FaChevronRight } from "react-icons/fa";
import LinkMenu from "./link-menu";
import IconSection from "./icon-section";

const SidenavListItem = ({ details, expanded, index, handleActiveChange }) => {
  const location = useLocation();
  const { pathname } = location;

  const { label, icon, linksDetails } = details;
  const { title, links } = linksDetails;

  const buttonPathMap = {
    "/subscribers": "subscribers",
    "/content-library": "content library",
    "/messaging": "messaging",
    "/account": "account",
    "/reports": "reports",
    "/system-setup": "system setup",
  };

  const getButtonActiveStatus = (pathname, label) => {
    for (let key in buttonPathMap) {
      if (
        pathname.startsWith(key) &&
        buttonPathMap[key].toLowerCase() === label
      ) {
        return true;
      }
    }
    return false;
  };

  const isActive = getButtonActiveStatus(pathname, label.toLowerCase());
  const setButtonActiveBgColor = () => {
    return isActive ? "bg-accent-gradient text-light-900" : "";
  };

  const handleClick = (label, index) => {
    if (label.toLowerCase === "dashboard") {
      handleActiveChange(-1);
    } else if (expanded) {
      handleActiveChange(-1);
    } else {
      handleActiveChange(index);
    }
  };

  return (
    <React.Fragment>
      {label.toLowerCase() === "dashboard" ? (
        <NavLink
          to="/dashboard"
          className={({ isActive }) =>
            `flex justify-between items-center font-semibold px-2 py-3 text-dark-200 rounded-lg ${isActive &&
              "bg-accent-gradient text-light-900"}`
          }
          onClick={handleActiveChange}
        >
          <IconSection icon={icon} label={label} />
        </NavLink>
      ) : (
        <button
          type="button"
          className={`w-full hover:cursor-pointer my-2 first:mt-0 first:mb-4 text-dark-200 px-2 py-3 rounded-lg hover:bg-accent-800 hover:transition-all duration-300 ${setButtonActiveBgColor()}`}
          onClick={() => handleClick(label, index)}
        >
          <div className="flex justify-between items-center font-semibold">
            <IconSection icon={icon} label={label} />
            {expanded ? <FaChevronDown /> : <FaChevronRight />}
          </div>
        </button>
      )}
      <LinkMenu
        title={title}
        links={links}
        expanded={expanded}
        handleSublinkClick={handleClick}
      />
    </React.Fragment>
  );
};

SidenavListItem.propTypes = {
  details: PropTypes.instanceOf(Object).isRequired,
};

export default SidenavListItem;
