import { apiSlice } from "../../api/api";

const userId = sessionStorage.getItem("id");

export const messageApi = apiSlice.injectEndpoints({
  reducerPath: "messageApi",
  endpoints: (builder) => ({
    createNewInstantMessageAsync: builder.mutation({
      query: ({ id, message_title, sender, recipients, content }) => ({
        url: `/user/message/sms/${id}`,
        method: "POST",
        body: {
          message_title: message_title,
          sender: sender,
          content: content,
          recipients: recipients,
          is_scheduled: false,
        },
      }),
      invalidatesTags: ["messageTemplates"],
    }),
    createNewInstantVoiceMessageAsync: builder.mutation({
      query: (arg) => ({
        url: `/user/message/voice/${userId}`,
        method: "POST",
        body: arg.formData,
      }),
      invalidatesTags: ["messageTemplates"],
    }),
    createNewMessageTemplateAsync: builder.mutation({
      query: (arg) => {
        const formData = arg.formData;
        if (formData.message) {
          return {
            url: `/user/message-template/${arg.userId}`,
            method: "POST",
            body: {
              title: formData.title,
              message: formData.message,
              msg_template_lang_id: formData.msg_template_lang_id,
            },
          };
        } else {
          return {
            url: `/user/message-template/${userId}`,
            method: "POST",
            body: formData,
          };
        }
      },
    }),

    getAllMessageTemplatesByUserIdAsync: builder.query({
      query: (userId) => `/user/message-template/${userId}`,
      providesTags: ["messageTemplates"],
      transformResponse: (res) => res.response,
    }),
    getSingleMessageTemplatesByUserIdAsync: builder.mutation({
      query: (arg) => ({
        url: `/user/message-template/single/${userId}`,
        method: "POST",
        body: {
          id: arg.id,
        },
      }),
      providesTags: ["messageTemplates"],
    }),
    getSingleMessageTemplateContentAsync: builder.mutation({
      query: ({ user_id, message_template_id }) => ({
        url: "/user/message-template/content",
        method: "POST",
        body: {
          user_id,
          message_template_id,
        },
      }),
      providesTags: ["messageTemplates"],
    }),
    deleteUserMessageTemplateAsync: builder.mutation({
      query: (arg) => ({
        url: `/user/message-template`,
        method: "DELETE",
        body: {
          id: arg.id,
        },
      }),
      invalidatesTags: ["messageTemplates"],
    }),
  }),
});

export const {
  useCreateNewInstantMessageAsyncMutation,
  useCreateNewInstantVoiceMessageAsyncMutation,
  useCreateNewMessageTemplateAsyncMutation,
  useGetAllMessageTemplatesByUserIdAsyncQuery,
  useGetSingleMessageTemplatesByUserIdAsyncMutation,
  useDeleteUserMessageTemplateAsyncMutation,
  useGetSingleMessageTemplateContentAsyncMutation,
} = messageApi;
