import React from "react";
import { Form, Formik } from "formik";
import { ClipLoader } from "react-spinners";
import { Button, TextInput } from "../../ui";

const UpdatePhoneNumberFormUI = ({
  initialValues,
  validationSchema,
  handleSubmit,
  isLoading,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      <Form className="space-y-6">
        <div>
          <TextInput
            type="text"
            label="Enter new number"
            name="userPhone"
            id="userPhone"
            placeholder="eg. 0241234567"
          />
        </div>
        <Button
          type="submit"
          disabled={isLoading}
          otherStyles="bg-accent-gradient text-light-900"
        >
          {isLoading && <ClipLoader color="#fff" size={20} />}
          Update
        </Button>
      </Form>
    </Formik>
  );
};

export default UpdatePhoneNumberFormUI;
