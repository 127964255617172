import React from "react";
import {
  FaUsers,
  FaTelegram,
  FaTachometerAlt,
  FaChartLine,
} from "react-icons/fa";
import { RiAccountCircleFill } from "react-icons/ri";
import { IoSettingsOutline } from "react-icons/io5";
import { MdOutlineLibraryBooks } from "react-icons/md";

const SIDE_NAVIGATION_LINKS = [
  {
    label: "Dashboard",
    icon: <FaTachometerAlt />,
    linksDetails: {
      title: "",
      links: [],
    },
  },
  {
    label: "Subscribers",
    icon: <FaUsers />,
    linksDetails: {
      title: "Subscriber management",
      links: [
        {
          label: "Groups",
          ref: "/subscribers/groups",
        },
        {
          label: "Subscribers",
          ref: "/subscribers/all-subscribers",
        },
        {
          label: "Import",
          ref: "/subscribers/import",
        },
      ],
    },
  },
  {
    label: "Content Library",
    icon: <MdOutlineLibraryBooks />,
    linksDetails: {
      title: "Management",
      links: [
        {
          label: "Message Templates",
          ref: "/content-library/message-templates",
        },
        {
          label: "Projects",
          ref: "/content-library/projects",
        },
      ],
    },
  },
  {
    label: "Messaging",
    icon: <FaTelegram />,
    linksDetails: {
      title: "Campaigns",
      links: [
        {
          label: "Instant",
          links: [
            {
              label: "SMS Campaign",
              ref: "/messaging/instant-sms",
            },
            {
              label: "Voice Campaign",
              ref: "/messaging/instant-voice",
            },
          ],
        },
        {
          label: "Scheduled",
          ref: "/messaging/scheduled",
        },
      ],
    },
  },
  {
    label: "Reports",
    icon: <FaChartLine />,
    linksDetails: {
      title: "Campaign Reports",
      links: [
        {
          label: "Export Subscribers",
          ref: "/reports/export-subscribers",
        },
        // {
        //   label: 'Campaign Logs',
        //   ref: '/outbound-campaigns',
        // },
        // {
        //   label: 'Delivery Reports',
        //   ref: '/delivery-reports',
        // },
        // {
        //   label: 'Generate Reports',
        //   ref: '/generate-report',
        // },
        // {
        //   label: 'Charts & Stats',
        //   ref: '/statistics',
        // },
      ],
    },
  },
  {
    label: "System Setup",
    icon: <IoSettingsOutline />,
    linksDetails: {
      title: "Configurations",
      links: [
        {
          label: "Sender IDs",
          ref: "/system-setup/senders",
        },
        {
          label: "Languages",
          ref: "/system-setup/languages",
        },
      ],
    },
  },

  {
    label: "Account",
    icon: <RiAccountCircleFill />,
    linksDetails: {
      title: "Account",
      links: [
        {
          label: "My Profile",
          ref: "/account/view-profile",
        },
        // {
        //   label: 'Credit Billing',
        //   ref: '/credit',
        // },
      ],
    },
  },
];

export default SIDE_NAVIGATION_LINKS;
