import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";

const useResendOTP = () => {
  const [resendState, setResendState] = useState({
    type: null,
    message: null,
  });

  React.useEffect(() => {
    if (resendState?.type === "success") {
      toast.success(resendState.message, {
        position: toast.POSITION.TOP_CENTER,
        onClose: () => setResendState(null),
        toastId: "otpResendSuccess",
      });
    } else if (resendState?.type === "error") {
      toast.error(resendState.message, {
        position: toast.POSITION.TOP_CENTER,
        onClose: () => setResendState(null),
        toastId: "otpResendError",
      });
    }
  }, [resendState]);

  const handleResend = React.useCallback(
    (config) => {
      setResendState((prevState) => ({
        ...prevState,
        type: config.type,
        message: config.message,
      }));
    },
    [setResendState]
  );

  return {
    resendState,
    handleResend,
  };
};

export default useResendOTP;
