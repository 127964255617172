import React from "react";
import { AuthHeader, UpdatePhoneNumber } from "../features/auth/components";
import { AppToastContainer, Card } from "../features/ui";

function PhoneNumberUpdatePage() {
  return (
    <section className="flex flex-col justify-center items-center min-h-screen">
      <Card className="bg-white rounded drop-shadow-lg p-8">
        <AuthHeader title="Update your phone number" otherStyles="mb-4" />
        <UpdatePhoneNumber />
      </Card>
      <AppToastContainer />
    </section>
  );
}

export default PhoneNumberUpdatePage;
