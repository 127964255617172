import React from "react";
import { Routes, Route } from "react-router-dom";
import APP_ROUTES, { PROTECTED_ROUTES } from "./utils";
import { DashboardWrapper } from "./features/ui";

function App() {
  return (
    <Routes>
      {APP_ROUTES.map((routeItem, index) => (
        <Route key={index} path={routeItem.path} element={routeItem.element} />
      ))}
      
      {PROTECTED_ROUTES.map((routeItem, index) => (
        <Route
          key={routeItem.path}
          path={routeItem.path}
          element={<DashboardWrapper>{routeItem.element}</DashboardWrapper>}
        />
      ))}
    </Routes>
  );
}

export default App;
