import React from "react";
import { Form, Formik } from "formik";
import { Button, TextInput } from "../../ui";
import { ClipLoader } from "react-spinners";

const ChangePasswordFormUI = ({
  initialValues,
  validationSchema,
  handleSubmit,
  isLoading,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      <Form className="space-y-6">
        <div>
          <TextInput
            type="password"
            label="Password"
            name="password"
            id="password"
            placeholder="Enter your password"
          />
        </div>
        <div>
          <TextInput
            type="password"
            label="Confirm Password"
            name="confirmPassword"
            id="confirmPassword"
            placeholder="Please confirm your password"
          />
        </div>
        <Button
          type="submit"
          disabled={isLoading}
          otherStyles="bg-accent-gradient text-light-900"
        >
          {isLoading && <ClipLoader color="#fff" size={20} />}
          Submit
        </Button>
      </Form>
    </Formik>
  );
};

export default ChangePasswordFormUI;
