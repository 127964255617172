import React from "react";
import PropTypes from "prop-types";
import { MobileSlider, Sidenav } from "./side-nav";
import Topnav from "./topnav";
import AppToastContainer from "../toast-container";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import { LoginPage } from "../../../pages";
import Footer from "./footer";

const DashboardWrapper = ({ children }) => {
  const [sideNavOpened, setSideNavOpened] = React.useState(false);

  const user_session = Cookies.get("user_session");
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!user_session) {
      navigate("/login", { replace: true });
    }
  }, [user_session, navigate]);

  if (!user_session) {
    return <LoginPage />;
  }

  const handleDrawerNavToggle = () => {
    setSideNavOpened((prevState) => !prevState);
  };

  if (sideNavOpened === true) {
    document.body.style.overflow = "hidden";
  } else {
    document.body.style.overflow = "unset";
  }

  return (
    <React.Fragment>
      <Topnav handleSliderToggle={handleDrawerNavToggle} />
      <section className="relative mt-[70px] h-screen">
        <Sidenav customStyles="hidden lg:col-span-2 lg:block" />
        <MobileSlider
          customStyles={`${
            sideNavOpened ? "side-nav-opened" : "side-nav-closed"
          }`}
        />
        <main className="flex-1 lg:ml-[15rem] mr-[1rem]">
          <div className="py-4">
            <AppToastContainer />
          </div>
          {children}
        </main>
        <Footer />
      </section>
    </React.Fragment>
  );
};

DashboardWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardWrapper;
