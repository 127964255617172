/* eslint-disable import/prefer-default-export */
import TabularDisplayOfLanguages from './tabular-display-of-languages';
import AddLanguageForm from './add-new-language-form';

import { useGetAllLanguagesByUserIdAsyncQuery } from './language-slice';

export {
  TabularDisplayOfLanguages,
  AddLanguageForm,
  useGetAllLanguagesByUserIdAsyncQuery,
};
