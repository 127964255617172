import React from "react";
import { MdPlaylistRemove } from "react-icons/md";

const EmptyResource = ({ message }) => {
  return (
    <section className="flex flex-col items-center justify-center py-8 bg-light-700 rounded-md">
      <div>
        <MdPlaylistRemove size={150} className="text-red-300" />
      </div>
      <p className="text-lg font-light text-slate-500">{message}</p>
    </section>
  );
};

export default EmptyResource;
