/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */

import React from "react";
import PropTypes from "prop-types";
import { Card } from "../ui/card";

const AnalyticsCardItem = ({ data }) => {
  const { label, icon, value } = data;
  return (
    <div className="flex-1 mx-2 first:mx-0 last:mx-0">
      <Card>
        <div className="flex flex-col items-center space-y-2">
          <div className=" border rounded-full border-light-400 p-3">
            {icon}
          </div>
          <div className="text-dark-300 font-black text-3xl">
            <b>{value}</b>
          </div>
          <div>
            <p className="text-light-400 text-sm font-light">{label}</p>
          </div>
        </div>
      </Card>
    </div>
  );
};

AnalyticsCardItem.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default AnalyticsCardItem;
