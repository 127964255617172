import { Formik, Form } from "formik";
import React from "react";
import { Button, TextInput } from "../../ui";
import { ClipLoader } from "react-spinners";

const ForgotPasswordOTPVerificationUI = ({
  initialValues,
  validationSchema,
  handleOtpResend,
  verifyLoading,
  resendLoading,
  handleSubmit,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      <Form className="space-y-6">
        <div>
          <TextInput
            type="text"
            label="Enter code here"
            name="secret"
            id="secret"
            placeholder="xxxxxx"
          />
        </div>
        <div className="space-y-1">
          <div className="text-sm text-slate-600 flex">
            <p className="mr-2">Didn&apos;t receive code?</p>
            <button
              type="button"
              className="text-color-primary"
              onClick={handleOtpResend}
              disabled={resendLoading}
            >
              {resendLoading && <ClipLoader color="#808080" size={15} />}
              Resend
            </button>
          </div>
        </div>
        <Button
          type="submit"
          disabled={resendLoading}
          otherStyles="bg-accent-gradient text-light-900"
        >
          {verifyLoading && <ClipLoader color="#fff" size={20} />}
          Verify
        </Button>
      </Form>
    </Formik>
  );
};

export default ForgotPasswordOTPVerificationUI;
