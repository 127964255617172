/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
import React from "react";
import { FaChevronRight } from "react-icons/fa";
import {
  Card,
  CustomLink,
  InnerPageHeader,
} from "../features/ui";
import { TabularDisplayOfMessageTemps } from "../features/messaging/components";

const MessageTemplates = () => {
  const pageConfig = {
    title: "Messages",
    subTitle: "Messages engage with our audience by sending them information.",
  };

  return (
    <React.Fragment>
      <Card>
        <section>
          <div className="flex justify-between items-start">
            <div className=" basis-[60%]">
              <InnerPageHeader
                pageTitle={pageConfig.title}
                pageSubTitle={pageConfig.subTitle}
              />
            </div>
            <CustomLink
              label="New Template"
              reference="/content-library/new-message-template"
              icon={<FaChevronRight />}
            />
          </div>
          <TabularDisplayOfMessageTemps />
        </section>
      </Card>
    </React.Fragment>
  );
};

export default MessageTemplates;
