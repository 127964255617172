/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react/function-component-definition */
/* eslint-disable arrow-body-style */
// import DeliveryReportGenerationForm from './delivery-report-generation-form';

import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { ClipLoader } from 'react-spinners';
import moment from 'moment';
import {
    Button,
    Card,
    CardWithHeader,
    CustomTable,
    TextInput,
} from '../ui';
import { useGetUserDeliveryReportAsyncMutation } from './campaign-api-slice';
import { exportJsonToExcel } from './utils';

const DisplayGeneratedDeliveryReport = () => {
    const userId = sessionStorage.getItem('id');
    const [
        getDeliveryReport,
        { data: deliveryReport,
            isLoading,
            isSuccess,
            isError,
            error }] = useGetUserDeliveryReportAsyncMutation();



    const [reports, setReports] = useState([]);
    useEffect(() => {
        if (isSuccess && deliveryReport) {
            setReports(deliveryReport.response);
        }
    }, [isSuccess, deliveryReport])

    const initialValues = {
        startDate: '',
        endDate: '',
    };

    const validationSchema = Yup.object({
        startDate: Yup.string().required('Start date cannot be empty'),
        endDate: Yup.string().required('End date cannot be empty'),
    });

    const handleSubmit = (values, { resetForm }) => {
        const userInput = {
            user_id: userId,
            start_date: moment(values.startDate).format('YYYY-MM-DD'),
            end_date: moment(values.endDate).format('YYYY-MM-DD')
        };

        getDeliveryReport(userInput);
        isSuccess && resetForm();
    };


    const columns = [
        {
            Header: '#',
            accessor: 'serial',
        },
        {
            Header: 'Recipient',
            accessor: 'recipient',
        },
        {
            Header: 'Status',
            accessor: 'status',
        },
        {
            Header: 'Answer Time',
            accessor: 'answer_time',
        },
        {
            Header: 'Hangup Time',
            accessor: 'hangup_time',
        },
        {
            Header: 'Retries',
            accessor: 'retries',
        },
        {
            Header: 'Date sent',
            accessor: 'date_sent',
        },
        {
            Header: 'Total Duration',
            accessor: 'duration',
        },
    ];

    const transformedData = Array.isArray(reports) ? reports.map((report, index) => {
        const { recipient, status, answer_time, hang_up_time, retries, date_sent, total_duration: duration } = report.report[0];
        return ({
            serial: index + 1,
            recipient,
            status,
            answer_time,
            hang_up_time,
            retries,
            date_sent,
            duration,
        });
    }) : [];

    const handleExport = () => {
        exportJsonToExcel(transformedData);
    };

    let content = <><p className="text-center text-slate-500">Nothing to display here</p></>

    if (isLoading) {
        content = (
            <>
                <div className="w-full flex justify-center items-center">
                    Please wait...
                    <ClipLoader />
                </div>
            </>)
    } else if (isError) {
        content = <p className="text-center text-red-500 bg-red-200 p-4">{error?.data?.error}</p>
    } else if (isSuccess && reports) {
        content = (
            <>
                <div className="mb-4">
                    <Button type="button" handleBtnClick={handleExport}>Export</Button>
                </div>
                <CustomTable columns={columns} data={transformedData} />
            </>
        )
    }

    return (
        <>
            <CardWithHeader title="Configuration">
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={(values, helpers) => handleSubmit(values, helpers)}
                >
                    <Form className="mt-4 space-y-6 pb-6 px-2">
                        <div className="space-y-4 md:grid md:grid-cols-5 md:items-baseline md:gap-x-4">
                            <div>
                                <TextInput
                                    label="Start Date"
                                    name="startDate"
                                    id="startDate"
                                    type="datetime-local"
                                />
                            </div>
                            <div>
                                <TextInput
                                    label="End Date"
                                    name="endDate"
                                    id="endDate"
                                    type="datetime-local"
                                />
                            </div>
                            <div className="md:place-self-end">
                                <Button
                                    type="submit"
                                    label="Generate Report"
                                    disabled={isLoading}
                                >
                                    {isLoading && <ClipLoader size={20} color="#fff" />}
                                    Generate Report
                                </Button>
                            </div>
                        </div>
                    </Form>
                </Formik>
            </CardWithHeader>
            <Card>
                {content}
            </Card>
        </>
    );
};

export default DisplayGeneratedDeliveryReport;
